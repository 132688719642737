import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle} from '@fortawesome/pro-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

class PatchFormAddButton extends React.PureComponent {

  render(){
    return (
      <div className = "row mt-2 mb-5">
        <div className = "col-auto">
          <button className={`btn btn-lg btn-dark}`} onClick ={this.addField} ><FontAwesomeIcon className="fa-1x" icon={faPlusCircle} style ={{color:"#FFFFFF"}}/> Add a field</button>
        </div>
      </div>

    )
  }

  addField = () => {
    this.props.addField(this.props.formKey);
  }

}

const mapDispatchToProps = {
  addField: (formKey) => {
    const id = uuidv4();    
    return {
      type: 'forms.merge',
      data: {
        [formKey]: {
          [id]: {
            id:  id,
            order: Date.now(),
          }
        }
      }
    };
  }
}


export default connect(null,mapDispatchToProps)(PatchFormAddButton);
