import Immutable from 'immutable';

const defaults = {
  id: null,
  type: null,
  name: null,
  address: null,
  latitude: null,
  longitude: null,
  project_id: null,
};

class Pin extends Immutable.Record(defaults){

}

export default Pin;