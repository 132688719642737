import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

import DeliveriesAnalysisActions from '../actions/deliveries_analysis_actions';
import DeliveriesActions from '../actions/deliveries_actions';
import AnalysisResults from './analysis_results';
import Loading from '../../shared/components/loading';


class DeliveriesAnalyzePanel extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loaded: false,
    }
  }

  componentDidMount(){
    DeliveriesAnalysisActions.show(this.props.delivery.id).then(() => {
      this.setState({loaded: true})
    })
  }

  render(){
    const { delivery } = this.props;
    var btn;
    var label;

    if(this.state.loaded){
      if(this.analyzeStatus() === 'analyzing'){
        label = <FontAwesomeIcon icon={faSpinner} spin className="mx-auto d-block" size="lg"/>;
      } else {
        label = (this.analyzeStatus() === 'completed' ? "Re-Analyze" : 'Analyze');
      }
      return (
        <div className="deliveries-data mb-2">
          <button className="btn btn-danger col-2 mb-4" onClick={this.analyze}>{label}</button>
          <AnalysisResults analysis={delivery.analysis} onRemove={this.onRemove}/>
        </div>

      )
    } else {
      return <Loading />
    }


  }

  analyzeStatus = () => {
    const { delivery } = this.props;
    if(!delivery.analyze_queued_at && !delivery.analyzed_at){
      return 'unanalyzed';
    } else if(!delivery.analyzed_at || (delivery.analyze_queued_at && delivery.analyze_queued_at > delivery.analyzed_at) ){
      return 'analyzing';
    } else {
      return 'completed';
    }
  }

  onRemove = (id) => {

    DeliveriesActions.destroy(this.props.delivery.id, [id])
  }

  analyze = () => {
    this.setState({loaded: false});
    DeliveriesAnalysisActions.create(this.props.delivery.id).then(() => {
      this.setState({loaded: true});
    });
  }
}

const mapStateToProps = (state,props) =>
{
  return{
    delivery: state.getIn(['deliveries',props.match.params.id])
  };
};

const mapDispatchToProps = {
  updateDelivery: (data) => ({ type: 'deliveries.merge', data })
}

export default connect(mapStateToProps,mapDispatchToProps)(DeliveriesAnalyzePanel);
