import Store from '../reducers/store';

import { baseUrl } from '../../shared/helpers/request_helpers';

export default {
  create: function(params={}){
    const attrs = Object.assign({},params,{resource: 'validator'});
    return fetch(`${baseUrl}/v2/authenticate`,{
      headers: {'Content-Type': 'application/json'},
      method: 'POST',
      body: JSON.stringify({data: { attributes: attrs, type: 'session'}}),
    }).then(response => {
      if(response.status < 400){ return response.json()} 
      else { throw response }
    }).then(json => {
      return Store.dispatch({type: 'validator.merge', data: json.data.attributes });
    });
  },
};