import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import VisitsActions from '../actions/visits_actions';

import VisitsOwedBox from './visits_owed_box';
import VisitsList from './visits_list';
import Loading from './loading';
import T from '../../shared/components/t';

class VisitsIndex extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {loaded: false};
  }

  componentDidMount(){
    VisitsActions.index({
      page: this.props.page
    }).then(() => this.setState({loaded: true}));
  }

  render(){
    let alert;
    if(!this.props.paypal_email){
      alert = (
        <div className="row mb-2">
          <div className="col">
            <Link to="/account">
              <div className="alert alert-danger no-paypal-email-alert" role="alert">
                <T k="visits.index.alerts.no_paypal" />
              </div>
            </Link>
          </div>
        </div>    
      )
    }

    let paginate;
    if(this.props.total > 9){
      paginate = (
        <ReactPaginate 
          pageCount={Math.ceil(this.props.total / 10)}
          containerClassName="pagination justify-content-center"
          breakClassName="page-item"
          breakLabel={<span className="page-link">...</span>}
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
          pageLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          activeClassName="active"
          onPageChange={this.onPageChange}
        />
      );
    }


    let content;
    if(this.state.loaded){
      content = (
        <div>
          <div className="row mb-2">
            <div className="col">
              <VisitsOwedBox visits={this.props.visits} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <VisitsList visits={this.props.visits} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              {paginate}
            </div>
          </div>
        </div>
      );
    } else {
      content = <Loading />
    }

    return (
      <div>
        {alert} 
        {content}
      </div>
    );
  }

  onPageChange = (e) => {
     VisitsActions.index({ page: (e.selected + 1) })
  }
}

const mapStateToProps = state => ({ 
  visits: state.get('visits'),
  paypal_email: state.getIn(['explorer','paypal_email']),
  page: state.getIn(['page','visits_page']),
  total: state.getIn(['page','visits_total']),
});


export default connect(mapStateToProps)(VisitsIndex);