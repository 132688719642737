import React from 'react';
import { connect } from 'react-redux';

import BotCallObservationForm from './bot_call_observation_form';

import BotCallObservationActions from '../actions/bot_call_observation_actions';

class ObservationsVerifyPanel extends React.Component{

  render(){
    const { observation } = this.props;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col">
                <h5>Schedule a bot call</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <BotCallObservationForm observation={observation} onSubmit={this.onSubmit}/>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  onSubmit = (data) => {
    return BotCallObservationActions.create(this.props.observation.id,data);
  }
}

const mapStateToProps = (state,props) => {
  const observation = state.getIn(['observations',props.match.params.id]);
  return{
    observation: observation,
  };
};

export default connect(mapStateToProps)(ObservationsVerifyPanel);
