import React from 'react';
import { connect } from 'react-redux';

import PasswordResetActions from '../actions/password_reset_actions';

import T from '../../shared/components/t';
import PasswordResetButton from './password_reset_button';

class PasswordResetsNew extends React.PureComponent {

  constructor(props){
    super(props);
    this.state = { passwordResetRequested: false, passwordResetSent: false, email: '' };
  }

  render(){
    return (
      <div className="row justify-content-center" >
        <div className="col-sm-6 col">
          <div className="card mt-sm-5">
            <div className="card-body">
              <h5 className="card-title"><T k="password_resets.new.header" /></h5>
              <form onSubmit={this.resetPassword}>
                <div className="form-group">
                  <label htmlFor="loginEmail"><T k="sessions.login.email" /></label>
                  <input className="form-control" name="password_reset[email]" type="email" onChange={this.updateEmail} value={this.state.email} id="loginEmail"/>
                </div>
                <PasswordResetButton 
                  className="col btn-primary" 
                  onClick={this.resetPassword}
                  requested={this.state.passwordResetRequested}
                  sent={this.state.passwordResetSent}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

  updateEmail = e => this.setState({email: e.target.value});

  resetPassword = e => {
    e.preventDefault();
    this.setState({passwordResetRequested: true});
    PasswordResetActions.create(this.state.email,this.props.locale).then(() => {
      this.setState({passwordResetSent: true});
    });
  }
}

const mapStateToProps = state => ({
  locale: state.getIn(['explorer','locale'])
})

export default connect(mapStateToProps)(PasswordResetsNew);
