import React from 'react';
import cn from 'classnames';

import JSONInputField from './json_input_field';

class JSONTextareaField extends JSONInputField {

  input = () => {
    const {element,type,value,disabled,property} = this.props;

    const rows = element.get('rows') || 4;

    return (
      <textarea type={type} name={property} className={cn('form-control',this.validation())} onChange={this.onChange} value={value} autoComplete="new-password" disabled={disabled} rows={rows}/>
    );
  }
}

export default JSONTextareaField;