import { authorizedFetch } from '../../shared/helpers/request_helpers';
import { generateResourceResponse } from '../../shared/helpers/action_helpers';

export const abstractAuthorizedRequest = (urlString,params,options) => {
  return authorizedFetch(urlString,params,options).then(json => {
    return {
      actions: generateResourceResponse(json),
      json
    };
  });

};
