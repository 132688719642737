import Store from '../reducers/store';

import { includedToActions } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  create: function(params={}){
    return authorizedFetch(`/v1/validators/place_validations`,null,{
      headers: {'Content-Type': 'application/json'},
      method: 'POST',
    }).then(json => {
      if(json.included){
        includedToActions(json.included).forEach(action => {
          Store.dispatch(action);
        });
      }
      Store.dispatch({type: 'page.merge', data: json.meta })
      Store.dispatch({type: 'place_validation.load', data: json.data });
    });
  },
  update: function(id,params={}){
    return authorizedFetch(`/v1/validators/place_validations/${id}`,null,{
      headers: {'Content-Type': 'application/json'},
      method: 'PUT',
      body: JSON.stringify(params)
    }).then(json => {
      return Store.dispatch({type: 'place_validation.load', data: {} });
    });
  },
  destroy: function(id){
    return authorizedFetch(`/v1/validators/place_validations/${id}`,null,{
      headers: {'Content-Type': 'application/json'},
      method: 'DELETE',
    }).then(json => {
      return Store.dispatch({type: 'place_validation.load', data: {} });
    });
  }
};