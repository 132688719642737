import Immutable from 'immutable';

const defaults = {
  id: null,
  uuid: null,
  name: null,
  notes: null,
  created_at: null,
  updated_at: null,
  relationships: Immutable.fromJS({}),
  analysis: null,
  analysis_path: null,
  analyze_queued_at: null,
  analyzed_at: null,
};


class Sample extends Immutable.Record(defaults){

}


export default Sample;
