import React from 'react';
import { connect } from 'react-redux';
import subDays from 'date-fns/sub_days'
import format from 'date-fns/format'

import ExplorersQuotasActions from '../actions/explorers_quotas_actions';

import ExplorersQuotaRow from './explorers_quota_row';
import CSVButton from './csv_button';


class ExplorersQuotasIndex extends React.PureComponent {

  componentDidMount(){
    ExplorersQuotasActions.index();
  }

  render(){

    const days = [];

    for(let i=0;i<14;i++){
      days.unshift(<th key={i} className="text-right">{format(subDays(new Date(),i),'M/D')}</th>)
    }

    return (
      <div id="explorersIndex">
        <div className="row mt-3">
          <div className="col-md-9 col">
            <CSVButton resource="explorer_payments" total={this.props.total}/>
          </div>
          <div className="col-md-3 col">
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <table className="table table-striped table-bordered table-sm table-hover">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Paypal Email</th>
                  <th>First</th>
                  <th>Last</th>
                  {days}
                  <th className="text-right">Payment</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.props.explorers_quotas.toIndexedSeq().map(explorer => {
                    return <ExplorersQuotaRow key={explorer.id} explorer={explorer} />
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    explorers_quotas: state.get('explorers_quotas'),
    // total: state.getIn(['page','totals','explorers_payments']),
  }
}

export default connect(mapStateToProps)(ExplorersQuotasIndex);