import React from 'react';
import { connect } from 'react-redux';
import { Marker } from 'react-leaflet'
import DataplorMap from '../../shared/components/dataplor_map';

const PlacesMapPanel = (props) => {
  const { place } = props;

  return (
    <DataplorMap center={[place.latitude, place.longitude]} zoom = {15} key = "place-map" style={{height: '75vh'}}>
      <Marker position={[place.latitude, place.longitude]} />
    </DataplorMap>
  );
}


const mapStateToProps = (state,initialProps) => {
  return (state) => {
    return {
      place: state.getIn(['places',initialProps.match.params.id]),
    }
  }
};

export default connect(mapStateToProps)(PlacesMapPanel);
