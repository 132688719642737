import Immutable from 'immutable';


// The popular one. This reducer stores collections of objects, and can
// usually be thought of as a cached subset of the API database. The data
// should look like this:
//
// {
//   123: ImmutableResourceModel,
//   456: ImmutableResourceModel,
//   789: ImmutableResourceModel,
// }
//
// Generally, the ImmutableResourceModels above inherit from Immutable.Record, so you
// can treat them like that.
//
// There are 4 actions here:
// load - Take my data and replace evrerything else so only my data survives
// merge - Merge my data with what is already there (essentially UPSERT if you think of it as a db)
// delete_in - Merge can't delete anything so this is how we can remove things
// reset - Get rid of everything and start from scratch with the default value.
//
export const createResourceReducer = (klass,singular,plural,defaultValue={}) => {
  return (state=Immutable.OrderedMap(defaultValue),action) => {
    switch (action.type) {
    case `${singular}.load`:
    case `${plural}.load`:
      return Immutable.OrderedMap( action.data.map(r => {
        return [r.id,new klass(Immutable.fromJS({id: r.id, ...r.attributes}))]
      }));
    case `${singular}.merge`:
    case `${plural}.merge`:
      return state.mergeDeep(
        action.data.reduce((o,r) => {
          const relationships = Object.keys(r.relationships || {}).reduce((rels, key) => {
            const data = r.relationships[key].data
            if(Array.isArray(data))
            {
              rels[key] = data.map(relation => relation.id)
            }
            else if(data)
            {
              rels[key] = data.id
            }

            return rels
          },{})
          const attrs = {id: r.id, ...r.attributes, relationships: relationships};
          const exists = state.has(r.id.toString());
          o[r.id] = exists ? attrs : new klass(Immutable.fromJS(attrs));
          return o;
        },{})
      );
    case `${singular}.delete_in`:
    case `${plural}.delete_in`:
      return state.withMutations(s => {
        action.data.forEach(p => {
          s.deleteIn(p);
        });
      });
    case 'all.reset':
      return Immutable.OrderedMap(defaultValue);
    default:
      return state;
    }
  }
}

// Similar to ResourceReducer, but for singletons. Takes the same Immutable.Record model class,
// but stores the instance directly instead of storing a collection of instances keyed by 'id'.
// Same 4 actions are available to this reducer as ResourceReducer.
//
export const createSingleResourceReducer = (klass,singular,plural,defaultValue=null) => {
  return (state=new klass(defaultValue || {}),action) => {
    const actionData = Array.isArray(action.data) ? action.data[0] : action.data;
    switch (action.type) {
    case `${singular}.load`:
    case `${plural}.load`:
      return new klass(Immutable.fromJS({id: actionData.id, ...actionData.attributes}))
    case `${singular}.merge`:
    case `${plural}.merge`:
      return state.mergeDeep({id: actionData.id, ...actionData.attributes});
    case `${singular}.delete_in`:
    case `${plural}.delete_in`:
      if(state){
        return state.withMutations(s => {
          action.data.forEach(p => {
            s.deleteIn(p);
          });
        });
      } else {
        return state;
      }

    case 'all.reset':
      return defaultValue;
    default:
      return state;
    }
  }
}


// Form reducers are an Immutable map of maps that store the data on each form. The data should
// be keyed by form, like so:
//
// {
//   'accounts/new': { attr: value, attr: value },
//   'visits/edit':  { attr: value, attr: value },
// }
//
// The keys of the data are commonly reffered to as formKey throughout the code.
//
// The form reducer can be loaded with existing data (say in the case of an existing model), and
// will track the overall state of the form given the prefilled attributes.
//
// Likewise here you can:
// load - replace old data with a starting state for the form, whether empty or with prefilled data
// merge - add a new attribute or update an existing one
// reset - essentially equivalent to loading the default value instead of a given value (some reducers have built in default values)
// all.reset - I don't like this one, it should be formas.reset_all and I need to look more into why this exists. Use sparingly
//
export const createFormReducer = (defaultValue) => {
  return (state=Immutable.fromJS(defaultValue),action) => {
    switch (action.type) {
    case 'forms.load':
      return Object.keys(action.data).reduce((state,formKey) => {
        return state.set(formKey,Immutable.fromJS(action.data[formKey]));
      },state);
    case 'forms.merge':
      return state.mergeDeep(action.data);
    // case 'forms.reset':
    //   return state.set(action.form,Immutable.fromJS( defaultValue[action.form] || {} ));
    case `forms.delete_in`:
      return state.withMutations(s => {
        action.data.forEach(p => {
          s.deleteIn(p);
        });
      });
    case 'all.reset':
      return Immutable.fromJS(defaultValue);
    default:
      return state;
    }
  }
};


// FormChanges is exactly like Forms except that on load it will start over with a blank object,
// thus capturing only the changes from the loaded state. This is useful if
// 1) You want to send only the fields that changed to the server
// 2) You want to know if there were any changes to the form
//
// You get the idea. It responds to the same action types as Form so you don't even really need to
// think about it, just use forms as usual and realize that this is tracking changes only and you can
// come grab it if you need it
//
export const createFormChangesReducer = (defaultValue) => {
  return (state=Immutable.fromJS(defaultValue),action) => {
    switch (action.type) {
    case 'form_changes.load':
    case 'forms.load':
      return Object.keys(action.data).reduce((state,formKey) => {
        return state.set(formKey,Immutable.fromJS({}));
      },state);
    case 'forms.merge':
      return state.mergeDeep(action.data);
    // case 'forms.reset':
    //   return state.set(action.form,Immutable.fromJS({}));
    case `forms.delete_in`:
      return state.withMutations(s => {
        action.data.forEach(p => {
          s.deleteIn(p);
        });
      });
    case 'all.reset':
      return Immutable.fromJS(defaultValue);
    default:
      return state;
    }
  }
};


// Another member of the Forms family. This one is mostly separate and it tracks
// the errors on the form. The data looks like this:
//
// {
//   'accounts/new': { attribute: 'i18nKeyForError'. attribute: 'errorI18nKey'  },
//   'visits/edit':  { attribute: ['i18nKey','fallbackKey'] },
// }
//
// Using this we can generate a keyed object of errors, and store them by form.
// The errors themselves are *not the text that displays on screen* but rather i18nKeys
// so we can internationalize the error messages.
// Further, an attribute can have either a single error as a string or an array of strings
// representing possible error messages. This is usually ordered from specific to general
// and the first i18nKey that exists should be rendered, ignoring the rest. IE:
//
// ['visits.errors.phone.missing_nine_at_beginning','visits.errors.phone.invalid','error_templates.invalid']
//
// Error reducers are used for two categories of "errors", straight up errors that are wrong,
// and also warnings that we want to show but shouldn't reject input. This reducer is instantiated as 2 separate
// instances, one for warnings and one for reducers.
//
export const createFormErrorsReducer = (defaultValue={},key='form_errors') => {
  return (state=Immutable.fromJS(defaultValue),action) => {
    switch (action.type) {
    case `${key}.load`:
      return Object.keys(action.data).reduce((state,formKey) => {
        return state.set(formKey,Immutable.fromJS(action.data[formKey]));
      },state);
    case `${key}.merge`:
      return state.mergeDeep(action.data).map(f => f.filterNot(v => v === null));
    case 'forms.merge':
      return Object.keys(action.data).reduce((st,formKey) => {
        return Object.keys(action.data[formKey]).reduce((st2,attr) => {
          return st2.setIn([formKey,attr],null);
        },st);
      },state).map(f => f.filterNot(v => v === null));
    case 'forms.load':
    // case 'forms.reset':
      return Object.keys(action.data).reduce((state,formKey) => {
        return state.set(formKey,Immutable.fromJS(defaultValue[action.form] || {}));
      },state);
    case 'all.reset':
      return Immutable.fromJS(defaultValue);
    default:
      return state;
    }
  }
};

// This is a reducer that isn't for forms or models but is just tying to store on object
// of data as an immutable object. General purpose stuff
//
export const createBasicReducer = (key,defaultValue={}) => {
  return (state=Immutable.fromJS(defaultValue),action) => {
    switch (action.type) {
    case `${key}.load`:
      return Object.keys(action.data).reduce((state,key) => {
        return state.set(key,Immutable.fromJS(action.data[key]));
      },state);
    case `${key}.merge`:
      return state.mergeDeep(action.data);
    case `${key}.delete_in`:
      return state.withMutations(s => {
        action.data.forEach(p => {
          s.deleteIn(p);
        });
      });
    case 'all.reset':
      return Immutable.fromJS(defaultValue);
    default:
      return state;
    }
  };
};


// This is like the basicReducer but instead of storing as an Immutable object, it
// stores as a vanilla js object. Useful for giant collections like our categories (of which
// there are >4000) where the collection isn't mutating so we don't really need the immutable
// features or cost
//
export const createVanillaReducer = (key,defaultValue={}) => {
  return (state=defaultValue,action) => {
    switch (action.type){
    case `${key}.load`:
      return action.data;
    default:
      return state;
    }
  };
};
