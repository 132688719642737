

export const extension = file => {
  switch(file.type){
    case 'image/jpeg':
      return 'jpg';
    case 'image/png':
      return 'png';
    default:
      return undefined;
  }
}

export default {
  extension,
}