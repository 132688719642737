import React from 'react';
import geodist from 'geodist';

import MetadataTag from './metadata_tag';

class DistanceMetadata extends React.PureComponent {
  render(){
    const placeCoords = {lat: this.props.visit.latitude, lon: this.props.visit.longitude};
    const imgCoords = {lat: this.props.image.latitude, lon: this.props.image.longitude};
    const v = geodist(placeCoords,imgCoords,{unit: 'meters'});
    const text = `${v} m from Place`;
    
    let style;

    if(v && v < 200){
      style = 'success';
    } else {
      style = 'danger';
    }

    return (
      <MetadataTag text={text} bstyle={style}/>
    )
  }
}

export default DistanceMetadata;