import React from 'react';

const I18nContext = React.createContext({});

export class I18nProvider extends React.PureComponent{
  render(){
    return (
      <I18nContext.Provider value={this.props}>
        {this.props.children}
      </I18nContext.Provider>
    )
  }
}

export const I18nConsumer = I18nContext.Consumer;

export default I18nContext;