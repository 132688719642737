import Immutable from 'immutable';
import isBefore from 'date-fns/is_before';
import subYears from 'date-fns/sub_years';
import isValid from 'date-fns/is_valid'

import EmailHelpers from '../helpers/email_helpers';

const defaults = {
  id: null,
  region_id: null,
  first_name: null,
  last_name: null,
  email: null,
  password: null,
  token: null,
  approved: null,
  number: null,
  phone: null,
  birthday: null,
  street_number: null,
  street_name: null,
  address2: null,
  city: null,
  state: null,
  postal_code: null,
  country: null,
  notes: null,
  status: null,
  created_at: null,
  updated_at: null,
  referral_code: null,
  dob: null,
  locale: null,
  suspended_at: null,
  agreed_to_conditions_at: null,
  opted_into_communications_at: null,
  agreed_to_contract_at: null,
  agreed_contract_md5: null,
  training_completed_at: null,
  quiz_completed_at: null,
  trial_run_completed_at: null,
  interview_completed_at: null,

  brazil_cpf: null,
  brazil_cnpj: null,
  has_correct_category: null,
  has_mei: null,
  has_smart_mei: null,

  paypal_email: null,

  is_done_with_forms: false,
  payable: 0,
  relationships: Immutable.fromJS({}),
};

class Explorer extends Immutable.Record(defaults){

  static validate(attr,value){
    const v = value || '';
    switch(attr){
    case 'email':
      if(!v){ return 'account.errors.email_presence'; }
      if(v && !EmailHelpers.emailValid(v) ){ return 'account.errors.email_format'; }
      break;
    case 'password':
      if(!v){ return 'account.errors.password_presence'; }
      if(v && v.length < 8){ return 'account.errors.password_length'; }
      break;
    case 'first_name':
      if(!v){ return 'account.errors.first_name_presence'; }
      break;
    case 'last_name':
      if(!v){ return 'account.errors.last_name_presence'; }
      break;
    case 'phone':
      if(!v){ return 'account.errors.phone_presence'; }
      if(v && !v.match(/^\d+$/)){ return 'places.errors.phone_format'; }
      if(v && v.length !== 10){ return 'places.errors.phone_length'; }
      if(v && (v.substring(0,2) === '01' || v.substring(0,2) === '52') ){ return 'places.errors.phone_prefixes' }
      break;
    case 'paypal_email':
      if(v && !EmailHelpers.emailValid(v) ){ return 'account.errors.paypal_email_format'; }
      break;
    case 'dob':
      if(!v){
        return 'account.errors.dob_presence';
      } else {
        const d = new Date(v);
        if(!isValid(d)){ return 'account.errors.dob_validity'; }
        if(!isBefore(d,subYears(new Date(),16))){ return 'account.errors.dob_age'; }
      }
      break;
    default:
      return null;
    }
  }

  static validateAll(params,attrs){
    for(let i=0; i<attrs.length; i++){
      if( this.validate(attrs[i],params.get(attrs[i]),params) ){ return false; }
    }
    return true;
  }

  static isRegistered(params){
    return this.validateAll(params,['email','first_name','last_name'])
  }

}

export default Explorer;
