import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import PaypalAutopayButton from './paypal_autopay_button';

import { regionToFlag } from '../../shared/helpers/emoji_helpers';

class ExplorersPaymentsRow extends React.PureComponent {

  render(){
    const {explorer} = this.props;

    const rowClasses = cn({
      'table-danger': (explorer.suspended_at || (explorer.region_id !== 'mx'))
    });

    return (
      <tr className={rowClasses}>
        <td><Link to={`/explorers/${explorer.id}/edit`} className="badge badge-explorers">{explorer.id}</Link></td>
        <td>{explorer.referral_code}</td>
        <td>{explorer.email}</td>
        <td>{explorer.paypal_email}</td>
        <td>{explorer.first_name}</td>
        <td>{explorer.last_name}</td>
        <td>{regionToFlag(explorer.region_id)}</td>
        <td className="text-right">${parseFloat(explorer.payable).toFixed(2)}</td>
        <td>
          <PaypalAutopayButton 
            explorer_id={explorer.id}
            paypal_email={explorer.paypal_email}
            amount={explorer.payable} 
            className="btn-sm py-0 d-block mx-auto"
          />
        </td>
      </tr>
    )
  }
}

export default ExplorersPaymentsRow;