import React from 'react';
import { connect } from 'react-redux';

import ObservationsActions from '../actions/observations_actions'

import IndexView from './index_view';
import DependentCard from './dependent_card';

class ObservationsDependentsPanel extends IndexView {
  constructor(props){
    super(props);
    this.state = {};
  }

  render(){
    const { total,page_size } = this.state;
    const { page_number } = this.parsedQuery();

    console.log("props",JSON.parse(JSON.stringify(this.props)))

    const cards = this.props.dependent_observations.toIndexedSeq().map(observation => {
      return <DependentCard observation={observation} key={observation.id} />
    })

    return (
      <div>
        <div className="row">
          <div className="col">
            {cards}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            {this.pagination({
              page_number: (page_number || 1),
              total,
              page_size,
            })}
          </div>
        </div>
      </div>
    );
  }

  fetchData = (query) =>
  {
    ObservationsActions.index(
      Object.assign(
        {},
        query,
        {filter: {parent_id: this.props.match.params.id }}
      ), "merge"
    )
  }
}

const mapStateToProps = (state,props) =>
{
  let dependent_observations;
  const parent = state.getIn(['observations',props.match.params.id]);
  if(parent)
  {
    dependent_observations = state.get('observations').filter(obs => obs.parent_id === parent.id )
  }
  return {
    dependent_observations
  };
};

export default connect(mapStateToProps)(ObservationsDependentsPanel);
