import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { faVolumeSlash } from '@fortawesome/pro-solid-svg-icons';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import format from 'date-fns/format';


class PlayButton extends React.PureComponent {

  render(){
    return (
      <FontAwesomeIcon icon={faPlay} className={this.props.className} onClick={this.onClick}/>
    );
  }
  onClick = (e) => this.props.onClick(e)
};

class PauseButton extends React.PureComponent {

  render(){
    return (
      <FontAwesomeIcon icon={faPause} className={this.props.className} onClick={this.onClick}/>
    );
  }
  onClick = (e) => this.props.onClick(e)
};


class SkinnyAudioPlayer extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = { canPlay: false, playing: false };
    this.audioPlayer = React.createRef();
  }

  render(){
    let content;
    if(this.props.src){
      if(this.state.canPlay){
        let className;
        let icon;
        let time;


        if(this.state.playing){
          className = 'text-primary'
          icon = <PauseButton className="mr-2" onClick={this.pause} />
          time = format(new Date((this.state.time || 0) * 1000),'mm:ss')
        } else {
          const t = this.audioPlayer.current.currentTime || this.audioPlayer.current.duration;

          icon = <PlayButton className="mr-2" onClick={this.play}/>
          time = format(new Date((t || 0) * 1000),'mm:ss')
        }

        content = <span className={className}>{icon} {time}</span>

      } else {
        content = <FontAwesomeIcon icon={faSpinner} spin className="mx-auto d-block"/>
      }
    } else {
      content = <span className="text-center"><FontAwesomeIcon icon={faVolumeSlash} /></span>
    }


    return (
      <span>
        {content}
        <audio 
          ref={this.audioPlayer}
          src={this.props.src} 
          style={{display: "hidden"}} 
          preload={this.props.preload}
          onTimeUpdate={this.onTimeUpdate} 
          onEnded={this.onEnded} 
          onCanPlayThrough={this.onCanPlay}/>
      </span>
    );
  }

  onCanPlay = (e) => {
    this.setState({canPlay: true});
  }

  play = (e) => {
    this.setState({playing: true})
    this.audioPlayer.current.play();
  }

  pause = (e) => {
    this.setState({playing: false})
    this.audioPlayer.current.pause();
  }

  onTimeUpdate = (e) => {
    this.setState({time: this.audioPlayer.current.currentTime})
  }

  onEnded = (e) => {
    this.setState({playing: false});
  }
}

SkinnyAudioPlayer.defaultProps = {
  preload: 'metadata'
}

export default SkinnyAudioPlayer;
