
export const blue =    '#2196F3';
export const indigo =  '#6610f2';
export const purple =  '#6f42c1';
export const pink =    '#e83e8c';
export const red =     '#e51c23';
export const orange =  '#fd7e14';
export const yellow =  '#ff9800';
export const green =   '#4CAF50';
export const teal =    '#20c997';
export const cyan =    '#9C27B0';
export const white =   '#fff';


export default {
  blue,
  indigo,
  purple,
  pink,
  red,
  orange,
  yellow,
  green,
  teal,
  cyan,
  white,
};
