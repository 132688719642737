import React, { useCallback } from 'react';
import cn from 'classnames';
import pick from 'lodash/pick';

import JFormLabel from './j_form_label';
import JFormPrepend from './j_form_prepend';
import JFormAppend from './j_form_append';
import JFormFeedback from './j_form_feedback';

import { validationClasses } from '../helpers/field_helpers';

import useFieldState from '../hooks/use_field_state';
import useForm from '../hooks/use_form';

const stopReturnsSubmitting = (e) => {
  if(e.which === 13){ e.stopPropagation(); }
}

const JFormBaseField = (props) => {
  const { id,show_validation,set_field_show_validation } = useFieldState();


  const { element } = props;
  const property = element.get('property');

  const { 
    disabled,
    value,
    error,
    warning,
    prevalidation,
    update_form,
    validate_form,
  } = useForm(property,mapFormToProps);

  const vc = show_validation ? validationClasses({error,warning,prevalidation,value}) : {};

  const onChange = useCallback((e) => {
    const v = e.target.value;
    update_form({[property]: v});
    set_field_show_validation(false);
  },[property,update_form,set_field_show_validation]);
  
  const onBlur = useCallback((e) => {
    validate_form([element]);
    set_field_show_validation(true);
  },[validate_form,element,set_field_show_validation]);

  const inputProps = {
    id,
    onChange,
    onBlur,
    name: property,
    className: cn('form-control',vc,props.className),
    value: (value || ''),
    autoComplete: (element.get('ac') || 'new-password'),
    disabled: (element.get('disabled') || disabled),
    rows: (element.get('rows') || 20)
  };
  
  return (
    <div className="form-group mb-4">
      <JFormLabel id={id} element={element} />
      <div className="input-group">
        <JFormPrepend element={element} />
        <textarea {...inputProps} onKeyDown={stopReturnsSubmitting}/>
        <JFormAppend element={element} />
      </div>
      <JFormFeedback element={element} show_validation={show_validation} />
    </div>
  );
};

const mapFormToProps = (form) => pick(form,[
  'disabled',
  'value',
  'error',
  'warning',
  'prevalidation',
  'update_form',
  'validate_form',
]);


JFormBaseField.defaultProps = {

}

export default React.memo(JFormBaseField);