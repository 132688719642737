import { authorizedFetch } from '../../shared/helpers/request_helpers';
import { handleResponse } from '../../shared/helpers/action_helpers';

export default {
  index: function(place_id, params, actionType = "load")
  {
    const q = Object.assign({sort: 'observations.id', sort_dir: 'desc'},params,{page_size: 25});
    return authorizedFetch(`/v3/admin/places/${place_id}/observations`,q).then(handleResponse("observations",actionType));
  }
};
