import React, { useCallback } from 'react';
import pick from 'lodash/pick';
import cn from 'classnames';

import JFormLabel from './j_form_label';
import JFormPrepend from './j_form_prepend';
import JFormAppend from './j_form_append';
import JFormFeedback from './j_form_feedback';

import { validationClasses } from '../helpers/field_helpers';

import useFieldState from '../hooks/use_field_state';
import useForm from '../hooks/use_form';
import isNil from 'lodash/isNil';

import JFormOptionsForSelect from './j_form_options_for_select';


const JFormSelectField = (props) => {
  const { element } = props;
  const property = element.get('property');

  const { id,show_validation,set_field_show_validation } = useFieldState();

  const { 
    i18n_prefix,
    schema,
    disabled,
    value,
    error,
    warning,
    prevalidation,
    update_form,
    validate_form,
  } = useForm(property,mapFormToProps);

  const onChange = useCallback((e) => {
    update_form({[property]: e.target.value});
    set_field_show_validation(true);
  },[property,update_form,set_field_show_validation]);

  const onBlur = useCallback((e) => {
    validate_form([element]);
    set_field_show_validation(true);
  },[validate_form,element,set_field_show_validation]);

  // props for select
  const vc = show_validation ? validationClasses({error,warning,prevalidation,value}) : {};
  const ac = element.get('autocomplete') || 'new-password';


  // props for JFormOptionsForSelect
  const prefix = element.get('i18n_prefix') || i18n_prefix;
  const options = schema.getIn(['properties',property,'enum']).reduce((obj,e) => { 
    obj[`${prefix}.${e}`] = e; 
    return obj; 
  },{});
  const default_null = isNil(element.get('default_null')) ? true : element.get('default_null');
  const null_selectable = element.get('null_selectable') || false;


  return (
    <div className="form-group mb-4">
      <JFormLabel id={id} element={element} />
      <div className="input-group">
        <JFormPrepend element={element} />
        <select 
          className={cn("custom-select",vc)} 
          name={property} 
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete={ac}
          disabled={disabled}
        >
          <JFormOptionsForSelect {...{options,default_null,null_selectable}} />
        </select>
        <JFormAppend element={element} />
      </div>
      <JFormFeedback element={element} show_validation={show_validation} />
    </div>
  );
};

const mapFormToProps = (form) => pick(form,[
  'i18n_prefix',
  'schema',
  'disabled',
  'value',
  'error',
  'warning',
  'prevalidation',
  'update_form',
  'validate_form',
]);

export default React.memo(JFormSelectField);