import Immutable from 'immutable';

const defaults = {
  id: null,
  amount: null,
  currency: 'MXN',
  pricing_item_id: null,
  observation_id: null,
  payment_id: null,
  created_at: null,
  updated_at: null,
  relationships: Immutable.fromJS({}),
};

class Payable extends Immutable.Record(defaults){

}

export default Payable;
