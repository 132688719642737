import React from 'react';
import { connect } from 'react-redux';
import LiveDate from './live_date';

import PaymentsActions from '../actions/payments_actions';

class ExplorersPaymentsPanelRow extends React.PureComponent {

  componentDidMount() {
    this.props.initForm();
  }

  render(){
    const { form,payment } = this.props;

    let notes
    if(form){
      notes = <input className="col form-control py-0" value={form.get('notes')} onChange={this.updateNotes}/>;
    } else {
      notes = payment.notes
    }

    return (
      <tr key={payment.id}>
        <td><span className="badge badge-dark">{payment.id}</span></td>
        <td><LiveDate date={payment.created_at}/></td>
        <td>{payment.address}</td>
        <td>{parseFloat(payment.amount).toFixed(2)}</td>
        <td>{payment.currency}</td>
        <td>{payment.vendor_id}</td>
        <td>{payment.vendor_batch_id}</td>
        <td>{notes}</td>
        <td><button className="btn btn-sm btn-primary py-0" onClick={this.save}>Save</button></td>
      </tr>
    );
  }

  updateNotes = (e) => {
    this.props.updateForm({notes: e.target.value});
  }

  save = () => {
    const body = { data: { type: 'payment', attributes: this.props.changes.toJSON() }}
    PaymentsActions.update(this.props.payment.id,body)
  }
}

const mapStateToProps = (initialState,initialProps) => {
  const formKey = `payments.${initialProps.payment.id}`;
  return (state) => ({
    form: state.getIn(['forms',formKey]),
    changes: state.getIn(['form_changes',formKey])
  })
};

const mapDispatchToProps = (initialState,initialProps) => {
  const formKey = `payments.${initialProps.payment.id}`;
  return dispatch => ({
    initForm: () => dispatch({type: 'forms.load', data: {[formKey]: {notes: initialProps.payment.notes || ''}}}),
    updateForm: (update) => dispatch({type: 'forms.merge', data: {[formKey]: update}}),
  })
}

export default connect(mapStateToProps,mapDispatchToProps)(ExplorersPaymentsPanelRow);
