import React, { useCallback } from 'react';
import cn from 'classnames';
import pick from 'lodash/pick';
import InputMask from 'react-input-mask';

import { validationClasses,maskFormatChars,unmaskValue,nullifyBlanks } from '../helpers/field_helpers';

import useForm from '../hooks/use_form';

const JFormInput = (props) => {
  const { 
    id,
    type,
    element,
    show_validation,
    set_field_show_validation,
    transform_value,
    transform_value_on_change,
    on_change,
    on_blur,
    event_to_value,
  } = props;

  const property = element.get('property');

  const { 
    disabled,
    value,
    error,
    warning,
    prevalidation,
    update_form,
    validate_form,
  } = useForm(property,mapFormToProps);

  const mask = props.mask || element.get('mask');
  const vc = show_validation ? validationClasses({error,warning,prevalidation,value}) : {};

  const onChange = useCallback((e) => {
    const v = event_to_value(e);
    update_form({[property]: transform_value_on_change(v,mask)});
    set_field_show_validation(false);
    on_change(v);
  },[property,update_form,transform_value_on_change,event_to_value,mask,on_change,set_field_show_validation]);
  
  const onBlur = useCallback((e) => {
    validate_form([element]);
    set_field_show_validation(true);
    on_blur(value);
  },[validate_form,element,set_field_show_validation,on_blur,value]);

  const inputProps = {
    id,
    type,
    onChange,
    onBlur,
    name: property,
    className: cn('form-control',vc,props.className),
    value: transform_value(value || ''),
    autoComplete: (element.get('ac') || 'new-password'),
    disabled: (element.get('disabled') || disabled),
  };
  
  if(type === 'checkbox'){ inputProps.checked = !!transform_value(value) }

  if(mask){
    return <InputMask mask={mask} formatChars={maskFormatChars} alwaysShowMask={true} {...inputProps} style={{height: '13.5px'}}/>
  } else {
    return <input {...inputProps} />
  }
};

const mapFormToProps = (form) => pick(form,[
  'disabled',
  'value',
  'error',
  'warning',
  'prevalidation',
  'update_form',
  'validate_form',
]);

const no_transform = (v) => v;

JFormInput.defaultProps = {
  event_to_value: (e) => e.target.value,
  transform_value: no_transform,
  transform_value_on_change: (v,m) => nullifyBlanks(unmaskValue(v,m)),
  on_change: () => {},
  on_blur: () => {},
}

export default React.memo(JFormInput);