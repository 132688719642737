import Store from '../reducers/store';
import { authorizedFetch,cleanBody } from '../../shared/helpers/request_helpers';

import { simulateSequence } from '../../shared/helpers/schema_helpers';


const handleResponse = (json) => {
  const region = json.included.find(i => i.type === 'region' );
  const explorer = json.data.attributes;

  const [is_done_with_forms,,] = simulateSequence(region.attributes.onboarding_form,explorer);

  Store.dispatch({type: 'explorer.merge', data: { is_done_with_forms, ...json.data.attributes } });
  Store.dispatch({type: 'regions.merge', data: region });
};


export default {
  create: function(params){
    const opts = {
      method: 'POST', 
      body: JSON.stringify({ data: { type: 'explorer', attributes: cleanBody(params)}})
    };
    return authorizedFetch(`/v2/explorers/registrations`,null,opts).then(handleResponse);
  },
  update: function(params){
    const opts = {
      method: 'PUT', 
      body: JSON.stringify({ data: { type: 'explorer', attributes: cleanBody(params)}})
    };
    return authorizedFetch(`/v2/explorers/account`,null,opts).then(handleResponse);
  },
  show: function(){
    return authorizedFetch(`/v2/explorers/account`).then(handleResponse);
  },
};