import Immutable from 'immutable';

const defaults = {
  id: null,
  validatable_id: null,
  validatable_type: null,
  type: null,
  attr: null,
  input: null,
  response: null,
  source: null,
  validated: null,
  validated_at: null,
  created_at: null,
  updated_at: null,
  relationships: Immutable.fromJS({}),
};

class AdminValidation extends Immutable.Record(defaults){

}

export default AdminValidation;
