import React from 'react';

class AnalysisPlacesMissingCardRow extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
    };
  }

  render(){
    const {id,attribute} = this.props;

    return (
      <li className="list-group-item">
        <div className="row">
          <div className="col">
            fetch data for place {id} and render, it's missing {attribute}          
          </div>
        </div>
      </li>
    )
  }
}


export default AnalysisPlacesMissingCardRow;
