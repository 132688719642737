
// This file is mostly a switch so that we can build different apps with this same
// create-react-app infrastructure.  
// 
// Specifically, we use the env variable
// REACT_APP_NAME to control which app gets built here. The individual apps have
// index.js files that resemble a more conventional create-react-app index.js except
// that they are wrapped in functions so that they can be imported without invoking.
// 
// "But doesn't this mean that all the apps get packed into each other?", you say. It
// would, except that we are relying on Webpack to tree-shake the other apps out
// given that this if statement essentially renders the other apps as dead code. As
// of 07/2019, this required the "sideEffects": false option to be set in the
// package.json file. I think it can also be done with __PURE__ comments but I
// haven't really explored that very well yet.
import ExplorerApp from './explorer/index';
import ValiadtorApp from './validator/index';
import AdminApp from './admin/index';

import './index.scss';

// Polyfills
import E6Promise from 'es6-promise';

E6Promise.polyfill();


// Switch
const appName = process.env.REACT_APP_NAME

if(appName === 'explorer'){
  ExplorerApp();
} else if(appName === 'validator'){
  ValiadtorApp();
} else if(appName === 'admin'){
  AdminApp(); 
}