import React, {useContext} from 'react';

import I18nContext from './i18n';

const SelectOption = React.memo((props) => {
  const { text_key,option_value,disabled } = props;

  const {t} = useContext(I18nContext);

  const text = text_key ? t(text_key) : '';
  return (
    <option key={text_key} value={option_value||''} disabled={disabled}>
      {text}
    </option>
  );
});


const JFormOptionsForSelect = (props) => {
  const { default_null,null_selectable,options } = props;
  const elements = [];

  if(default_null){
    elements.push(
      <SelectOption key="null" text_key="" option_value={null} disabled={!null_selectable}/>
    )
  }
  for(let key in options){
    const option_value = options[key];

    elements.push(
      <SelectOption {...{key,option_value,text_key: key}} />
    )
  }
  return elements;
}

export default JFormOptionsForSelect;