import { useState,useContext } from 'react';
import defaultTo from 'lodash/defaultTo';

import JFormContext from '../components/j_form_context';

const useFieldState = () => {
  const { show_validation } = useContext(JFormContext);

  const id = useState(Math.random().toString().slice(2,12))[0]

  const [field_show_validation,set_field_show_validation] = useState(false);
  
  return {
    // attributes
    id,
    show_validation: (defaultTo(show_validation,field_show_validation)),

    // methods
    set_field_show_validation,
  };
} 

export default useFieldState;
