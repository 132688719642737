import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Immutable from 'immutable';
import Ajv from 'ajv';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';

import JForm from './j_form';

import { abstractAuthorizedRequest } from '../actions/abstract_actions';

const ajv = new Ajv({unknownFormats: ['phone']});

const JFormView = (props) => {
  const { view,data_key,schema,navigate_to,on_finish } = props;
  const dispatch = useDispatch();

  const dk = view.get('data_key') || data_key;

  const on_submit = useCallback((data) => {
    // strip out some of these server set columns
    const sendData = omit(data,['id','number','token','created_at','updated_at']);
    if(view.get('type') === 'form_view'){
      if(!isEmpty(sendData)){
        // send the request
        return abstractAuthorizedRequest(view.get('url'),{},{ 
          method: view.get('method'),
          body: JSON.stringify({ data: { attributes: sendData } }),
        }).then(({json,actions}) => {
          // merge the server returned changes to the form
          dispatch({type: "forms.merge", data: {[dk]: json.data.attributes }});
          // dispatch other actions to update state
          actions.forEach(a => dispatch(a) );
          // reset form_changes
          dispatch({type: 'form_changes.load', data: {[dk]: {} }});
        }); 
      }
    }
    return Promise.resolve();
  },[view,dispatch,dk]);

  const on_complete = useCallback((data) => {
    view.get('on_next').forEach(path => {
      if(path.get('finish')){ 
        on_finish(data); 
      } else {
        const validator = ajv.compile(path.get('test').toJSON());
        const shouldWeNaviagateHere = validator(data); 
        if(shouldWeNaviagateHere){ navigate_to(path.get('view')) }
        return Promise.resolve();
      }
    });
  },[view,navigate_to,on_finish]);


  return (
    <div className="j-form-view position-relative">
      <JForm
        key={view.hashCode()}
        schema={schema}
        elements={view.get('elements')}
        data_key={dk}
        i18n_prefix={props.i18n_prefix || view.get('i18n_prefix')}
        i18n_error_prefix={props.i18n_error_prefix || view.get('i18n_error_prefix')}
        on_submit={on_submit}
        on_complete={on_complete}
      />
    </div>
  );
};

JFormView.defaultProps = {
  view: Immutable.fromJS({}),

}

export default React.memo(JFormView);