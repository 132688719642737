import isNil from 'lodash/isNil';

export const pointInPolygon = (point, vs) => {
  // https://github.com/substack/point-in-polygon/blob/master/index.js
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

  var x = point[0], y = point[1];

  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0], yi = vs[i][1];
      var xj = vs[j][0], yj = vs[j][1];

      var intersect = ((yi > y) !== (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
  }

  return inside;
}

export const calculateBounds = (points=[],buffer=0.1) => {
  let east, west, south, north;

  for(let i=0;i<points.length;i++){

    east = Math.max(...[east,points[i].lng].filter(x => !isNil(x)));
    west = Math.min(...[west,points[i].lng].filter(x => !isNil(x)));
    north = Math.max(...[north,points[i].lat].filter(x => !isNil(x)));
    south = Math.min(...[south,points[i].lat].filter(x => !isNil(x)));
  }

  const dx = (east - west);
  const dy = (north - south);

  return {
    east: (east + (dx * buffer)),
    west: (west - (dx * buffer)),
    south: (south - (dy * buffer)),
    north: (north + (dy * buffer)),
  }
}
