import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { isLoggedIn } from '../../shared/helpers/authentication_helpers';

class AuthenticatedRoute extends React.Component {

  render(){
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {          
          if( isLoggedIn() ){ 
            return <Component {...props} /> 
          }  else {
            return <Redirect to={{ pathname: '/signin', state: { from: props.location } }} /> 
          }
        }}
      />
    );
  }
}

export default AuthenticatedRoute;