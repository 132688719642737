export const timezoneParams = () => {
  const r = {}
  if(window && window.Intl && window.Intl.DateTimeFormat && window.Intl.DateTimeFormat.call){
    r.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  let offset = new Date().getTimezoneOffset();
  if(offset){
    r.timezone_offset = offset;
  }

  return r;
}