import React from 'react';
import { FixedSizeList as List } from 'react-window';

import AnalysisDuplicate from './analysis_duplicate';

class AnalysisDuplicates extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
    };
  }

  render(){
    const {duplicates} = this.props;

    let body;
    let Row, list


    if(this.state.open)
    {
      Row = ({ index, style }) => (
        <AnalysisDuplicate duplicate ={duplicates.get(index)} key= {duplicates.get(index).hashCode()} onExclude={this.props.onExclude} style={style}/>
      );

      list = (
        <List
          className="List"
          height={800}
          itemCount={duplicates.size}
          itemSize={150}
        >
          {Row}
        </List>
      )

    }

    return (
      <div className="card border-danger mb-2">
        <div className="card-header text-danger cursor-pointer" onClick={this.toggle}>
          Duplicates [{duplicates.size}]
        </div>
        {list}
      </div>
    )
  }

  toggle = () => {
    this.setState({open: !this.state.open})
  }
}


export default AnalysisDuplicates;
