
import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';

import Store from '../reducers/store';

export default {
  show: function(sampleId,params){
    return authorizedFetch(`/v3/admin/samples/${sampleId}/analysis`,params).then( data => {
      Store.dispatch({
        type: 'samples.merge',
        data: [{id: sampleId, attributes: {analysis: data}}],
      })
    });
  },
  create: function(sampleId, params, actionType = "merge"){
    const opts = { method: 'POST'};
    return authorizedFetch(`/v3/admin/samples/${sampleId}/analysis`,params,opts).then(handleResponse("samples", actionType));;
  }
};
