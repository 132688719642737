import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import LeadsActions from '../actions/leads_actions';

import JSONForm from '../../shared/components/json_form';

const schema = Immutable.fromJS({
  "required": [
    "name",
    "city",
    "state",
    "country"
  ],
  "properties": {
    "name": { "type": "string", },
    "address": { "type": "string", },
    "neighborhood": { "type": "string", },
    "city": { "type": "string", },
    "state": { "type": "string", },
    "postal_code": { "type": "string", },
    "country": { "type": "string" },
  }
});

const form_schema = Immutable.fromJS({
  elements: [
    { 
      "name": "name",
      "type": "section", 
      "elements": [
        { "type": "field", "field_type": "text", "property": "name" },
      ]
    },
    { 
      "name": "attributes",
      "type": "section", 
      "elements": [
        { "type": "field", "field_type": "text", "property": "address", "required": true },
        { "type": "field", "field_type": "text", "property": "neighborhood"},
        { "type": "field", "field_type": "text", "property": "city", "required": true },
        { "type": "field", "field_type": "text", "property": "state" , "required": true },
        { "type": "field", "field_type": "text", "property": "postal_code", "required": true },
        { "type": "field", "field_type": "text", "property": "country", "required": true}
      ]
    }
    
  ]
});

class LeadsFormPanel extends React.Component {
  render(){
    return (
      <div className="leads-form mb-2">
        <JSONForm
          i18nKey="leads.form"
          schema={schema}
          form_schema={form_schema}
          formKey="leads/edit"
          onSubmit={this.onSubmit}
          showValidation={true}
        />
      </div>
    )
  }

  onSubmit = () => {
    const body = { data: { type: 'lead', attributes: this.props.changes }}
    return LeadsActions.update(this.props.lead.id,body)
  }
}

const mapStateToProps = (state,props) => {
  const lead = state.getIn(['leads',props.match.params.id]);
  return {
    lead,
    changes: state.getIn(['form_changes','leads/edit']),
  };
};

export default connect(mapStateToProps)(LeadsFormPanel);

