import React from 'react';
import RetinaImage from 'react-retina-image';

import MetadataTag from './metadata_tag';
import TakenAtMetadata from './taken_at_metadata';
import DimensionMetadata from './dimension_metadata';
import DistanceMetadata from './distance_metadata';

import { versionKey } from '../../shared/helpers/image_helpers';

class ImagesColumnItem extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {};
  }

  render(){
    const { visit,image,image_index,image_role } = this.props;
    if(visit){

      if(image){
        const tags = [];
        
        if( image.key ){
          tags.push( <MetadataTag key="role" text={image_role + ' #' + image_index} bstyle="secondary"/> )
          tags.push( <MetadataTag key="make" text={`make: ${image.make}`} bstyle={image.make ? 'success' : 'danger'}/>)
          tags.push( <MetadataTag key="model" text={`model: ${image.model}`} bstyle={image.make ? 'success' : 'danger'}/>)
          tags.push( <TakenAtMetadata key="taken_at" image={image}/> )
          tags.push( <DimensionMetadata key="width" image={image} attr="width"/>)
          tags.push( <DimensionMetadata key="height" image={image} attr="height"/>)
          tags.push( <MetadataTag key="format" text={`format: ${image.format}`} bstyle={image.format === 'jpeg' ? 'success' : 'danger'}/>)
          if(image.latitude && image.longitude){
            tags.push( <DistanceMetadata key="coordinates" image={image} visit={visit}/>)
          } else {
            tags.push( <MetadataTag key="coordinates" text={`No GPS`} bstyle="danger"/>)
          }
        } else {
          tags.push( <MetadataTag text="no tags" bstyle="secondary" /> )
        }

        return (
          <div className="position-relative mt-3" key={image.id}>
            <a href={versionKey(image.key,'800@2x')} target="_blank" rel="noopener noreferrer">
              <RetinaImage
                className="col p-0"
                src={versionKey(image.key,'800')}
                alt={image_role}
                checkIfRetinaImgExists={false}
              />
              <div className="position-absolute metadata-tags-container p-2">
                {tags}
              </div>
            </a>
          </div>
        )
      }
    }
    return null;
  }
}

export default ImagesColumnItem;
