import { useContext,useCallback } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import Immutable from 'immutable';
import isNil from 'lodash/isNil';

import { calculateError } from '../helpers/field_helpers'; 

import JFormContext from '../components/j_form_context';

const defaultMapFormToProps = (form) => form;

const useForm = (property,mapFormToProps=defaultMapFormToProps) => {
  const context = useContext(JFormContext);
  const data_key = context.data_key;

  const data = useSelector(state => {
    return ({
      value: state.getIn(['forms',data_key,property]),
      error: (state.getIn(['form_errors',data_key,property]) || state.getIn(['form_async_errors',data_key,property])),
      warning: (state.getIn(['form_warnings',data_key,property]) || state.getIn(['form_async_warnings',data_key,property])),
      prevalidation: state.get('prevalidations').find(pv => pv.data_key === data_key && pv.attr === property ),
    });
  },Immutable.is);

  const dispatch = useDispatch();

  const update_form = useCallback((update) => {
    if(data.prevalidation){
      dispatch({type: 'prevalidations.delete_in', data: [[data.prevalidation.id.toString()]]}) 
    }
    return dispatch({ type: 'forms.merge', data: { [data_key]: update } });
  },[data_key,data.prevalidation,dispatch]);

  return defaultMapFormToProps({
    // attributes
    schema: context.schema,
    i18n_prefix: context.i18n_prefix,
    disabled: context.disabled,
    value: (isNil(data.value) ? '' : data.value),
    error: calculateError({error: data.error, prevalidation: data.prevalidation, property}),
    warning: data.warning,
    prevalidation: data.prevalidation,
    show_validation: context.showValidation,
    raw_value: data.value,

    // methods
    update_form,
    validate_form: context.validate_form,
  });
} 

export default useForm;
