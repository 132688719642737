
import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';


export default {
  show: function(placeId,id)
  {
     return authorizedFetch(`/v3/admin/places/${placeId}/comparisons/${id}`).then(handleResponse("places", "merge"));
  }
};
