import React from 'react';
import cn from 'classnames';
import isNil from 'lodash/isNil';

import JSONInputField from './json_input_field';
import JSONOptionsForSelect from './json_options_for_select';

class JSONSelectField extends JSONInputField {

  input = () => {
    const { i18nKey,element,property,schema,value,disabled } = this.props;

    const tKey = element.get('i18n_key') || `${i18nKey}.${property}`;

    let options;
    options = schema.getIn(['properties',property,'enum']).reduce((obj,e) => { obj[`${tKey}.${e}`] = e; return obj },{});

    const defaultNull = isNil(element.get('defaultNull')) ? true : element.get('defaultNull');
    const nullSelectable = element.get('nullSelectable') || false;
    return (
      <select className={cn('custom-select',this.validation())} name={property} value={value||''} onChange={this.onSelectChange} autoComplete="new-password" disabled={disabled}>
        <JSONOptionsForSelect {...{options,defaultNull,nullSelectable}} />
      </select>
    );
  }

  onSelectChange = (e) => {
    this.onChange(e);
    this.setState({ showValidation: true });
  }

  onBlur = () => {
    this.props.validateFormProperties([this.props.property]);
  }

}

export default JSONSelectField;