import React from 'react';
import parse from 'date-fns/parse';
import addMinutes from 'date-fns/add_minutes';
import format from 'date-fns/format';

import PlaceValidationsActions from '../actions/place_validations_actions';


class SnoozeControls extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      date: null,
      time: null,
      notes: props.place_validation.get('snooze_notes')
    };
  }

  componentDidMount(){
    const now = new Date();
    const times = this.props.place_validation.get('times_snoozed') || 0;
    const minutes = Math.pow(2,times) * 20;
    const t = addMinutes(now,minutes);

    this.setState({date: format(t,'YYYY-MM-DD'), time: format(t,'HH:mm')})
  }
  
  render(){
    const {date,time,notes} = this.state;

    return (
      <React.Fragment>
      <div className="row">
          <div className="col">
            <p>Snooze until:</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <input type="date" value={date} onChange={this.changeDate} className="form-control"/>
          </div>
          <div className="col">
            <input type="time" value={time} onChange={this.changeTime} className="form-control"/>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <textarea value={notes} onChange={this.changeNotes} className="form-control" placeholder="Snooze notes (won't be shown to explorers)" rows="3"/>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <button className="btn btn-primary col" onClick={this.updateSnooze}>{`Snooze! (snoozed ${this.props.place_validation.getIn(['response','meta','times_snoozed']) || 0} times)`}</button>
          </div>
        </div>
      </React.Fragment>
    )
  }

  changeDate = (e) => {
    this.setState({ date: e.target.value });
  }

  changeTime = (e) => {
    this.setState({ time: e.target.value });
  }

  changeNotes = (e) => {
    this.setState({ notes: e.target.value });
  }

  updateSnooze = () => {
    const { place_validation,validator } = this.props;
    const id = place_validation.get('id');
    const snoozed_until = parse(`${this.state.date}T${this.state.time}`).toISOString()

    PlaceValidationsActions.update(id,{
      data: { type: 'place_validation', attributes: {} },
      meta: {
        snoozed_until, 
        snooze_notes: this.state.notes,
        snoozer_name: validator.first_name,
        times_snoozed: ((place_validation.getIn(['response','meta','times_snoozed']) || 0) + 1),
        snoozed_at: (new Date()).toISOString(),
      }
    }).then( () => { this.props.nextTask() })
  }
}

export default SnoozeControls;
