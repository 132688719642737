import React from 'react';
import { connect } from 'react-redux';
import { Marker } from 'react-leaflet'
import DataplorMap from '../../shared/components/dataplor_map';


class LeadsMapPanel extends React.PureComponent {
  render(){
    const { lead } = this.props;

    return (
      <DataplorMap center={[lead.latitude, lead.longitude]} zoom = {15} key = "place-map" style={{height: '75vh'}}>
        <Marker position={[lead.latitude, lead.longitude]} />
      </DataplorMap>
    );
  }
};

const mapStateToProps = (state,initialProps) => {
  return (state) => {
    const leadId = initialProps.match.params.id;
    const lead = state.getIn(['leads',leadId]);

    return {
      lead: lead
    }
  }
};

export default connect(mapStateToProps)(LeadsMapPanel);
