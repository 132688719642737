import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/pro-solid-svg-icons';


class GooglePlacesMatchCard extends React.PureComponent {
  render(){
    const { candidate } = this.props

    const json = candidate.get('json');

    const classes = cn({
      hovered: this.props.hovered,
      active: this.props.active,
    },'card google-match-card mb-3',this.props.className)

    const link = <a href={`https://www.google.com/maps/place/?q=place_id:${json.get('place_id')}`} target="_blank" rel="noopener noreferrer" onClick={this.sp}><FontAwesomeIcon icon={faMap}/></a>

    const cats = json.get('types').map(cat => <span key={cat} className="badge badge-secondary mr-1">{cat}</span> )

    return (
      <div className={classes} onClick={this.onClick} onMouseEnter={this.ome} onMouseLeave={this.oml}>
        <div className="card-body p-2">
          <h5 className="card-title">{json.get('name')} {link}</h5>
          <h6 className="card-subtitle mb-2 text-monospace" style={{fontSize: '0.5rem'}}>{json.get('formatted_address') || json.get('vicinity')}</h6>
          <p className="mb-0">{cats}</p>
        </div>
      </div>
    );

  }

  sp = (e) => e.stopPropagation();

  onClick = (e) => {
    const newVal = this.props.active ? null : this.props.place_id;
    this.props.setTaskProperties({google_match_task_active: newVal})
  }

  ome = (e) => this.props.setTaskProperties({google_match_task_hovered: this.props.place_id})
  oml = (e) => this.props.setTaskProperties({google_match_task_hovered: null });
}

const mapStateToProps = (initialState,initialProps) => {
  const place_id = initialProps.candidate.getIn(['json','place_id']);
  return (state) => {
    const hovered = state.getIn(['page','google_match_task_hovered']);
    const active = state.getIn(['page','google_match_task_active']);

    return {
      place_id,
      hovered: hovered && (hovered === place_id),
      active: active && (active === place_id),
    };
  };
}

const mapDispatchToProps = {
  setTaskProperties: (props={}) => ({ type: 'page.merge', data: props })
}

export default connect(mapStateToProps,mapDispatchToProps)(GooglePlacesMatchCard);
