import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { isLoggedIn } from '../../shared/helpers/authentication_helpers';
import Loading from '../components/loading';

class AuthenticatedRoute extends React.Component {
  render(){
    const { component: Component,categoriesLoaded, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {          
          // if users are not logged in , send them to the login page
          if( isLoggedIn() ){
            if( categoriesLoaded ){
              return <Component {...props} /> 
            } else {
              return <Loading />
            }
            
          } else {
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          }
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  categoriesLoaded: !isEmpty(state.get('business_categories'))
})

export default connect(mapStateToProps)(AuthenticatedRoute);