import React from 'react';
import { connect } from 'react-redux';
import DuplicateChecksIndex from './duplicate_checks_index';

class PlaceDuplicatesReviewTask extends React.PureComponent {

  render()
  {
    const{ task,original,duplicate} = this.props;

    return <DuplicateChecksIndex task={task} original={original} duplicate={duplicate} onMerge={this.onMerge}/>
  }

  onMerge = (shouldMerge) => {
    return this.props.onComplete({
      data: {
        attributes: {
          should_merge: shouldMerge,
          original_id: this.props.original.id,
          duplicate_id: this.props.duplicate.id,
        }
      }
    });
  }
}

const mapStateToProps = state => {
  let original;
  let duplicate;
  const task = state.get('tasks').sortBy(t => t.created_at).first();

  if(task){
    original = state.getIn(['places',task.getIn(['association_ids','original_id']).toString()]);
    duplicate = state.getIn(['places',task.getIn(['association_ids','duplicate_id']).toString()]);
  }

  return {
    task,
    original,
    duplicate
  }
};

const mapDispatchToProps = {
  clearTask: (task) => ({type: 'tasks.delete_in', data: [[task.id.toString()]]}),
}

export default connect(mapStateToProps,mapDispatchToProps)(PlaceDuplicatesReviewTask);
