import Immutable from 'immutable';

export const raw = {
  "type": "array",
  "items": {
    "type": "object",
    "required": ["o","p","c","v"],
    "properties": {
       "o": { "type": "boolean" },
       "p": { "type": "string", "pattern": "/" },
       "c": { "type": "number", "minimum": 0, "maximum": 1 },
       "v": {}
    }
  }
}

export default Immutable.fromJS(raw)
