import React from 'react';
import { connect } from 'react-redux';

class TasksDataPanel extends React.Component {

//<pre>{JSON.stringify(place.toJSON(),null,2)}</pre>
  render(){
    const { task } = this.props;
    return (
      <div className="visits-data mb-2">
        <pre>{JSON.stringify(task.toJSON(),null,2)}</pre>
      </div>
    )
  }
}

const mapStateToProps = (state,props) =>
{
  return{
    task: state.getIn(['tasks',props.match.params.id])
  };
};

export default connect(mapStateToProps,null)(TasksDataPanel);
