import React from 'react';

import JSONOperatingHoursWidget from './json_operating_hours_widget';
import JSONAddressWidget from './json_address_widget';
import JSONPhotoWidget from './json_photo_widget';
import JSONNewBusinessNameWidget from './json_new_business_name_widget';
import JSONMultiCheckboxWidget from './json_multi_checkbox_widget';

class JSONFormWidget extends React.PureComponent {
  render(){
    const {element,...rest} = this.props;

    const type = element.get('widget_type');

    if(type === 'operating_hours'){
      return <JSONOperatingHoursWidget {...this.props} />
    } else if(type === 'address'){
      return <JSONAddressWidget {...rest} />
    } else if(type === 'photo'){
      return <JSONPhotoWidget {...this.props} />
    } else if(type === 'new_business_name'){
      return <JSONNewBusinessNameWidget {...this.props} />
    } else if(type === 'multi_checkbox'){
      return <JSONMultiCheckboxWidget {...this.props} />
    } else {  
      return null;
    }
  }
}

export default JSONFormWidget;

