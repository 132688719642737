import React from 'react';
import { connect } from 'react-redux';
import BotCallAuditObservationsActions from '../actions/bot_call_audit_observations_actions';
import { saveObservationChanges } from '../helpers/observation_helpers';
import ObservationsBotCallCard from './observations_bot_call_card';
import isNil from 'lodash/isNil';

class ObservationsBotCall extends React.Component{
  constructor(props){
    super(props);
    this.state = { submitted: false };
  }

  onComplete = (id, changes, patchForm, errors) =>
  {
    if(isNil(errors) || errors.size === 0)
    {
      saveObservationChanges(id, changes, patchForm, BotCallAuditObservationsActions.create)
      this.setState({submitted: true});
    }
  }

  render()
  {
    const { observation} = this.props;

    if(this.state.submitted){
      return (
        <div><span>Submitted! Refresh to review again.</span></div>
      );
    } else {
      const card = <ObservationsBotCallCard  observation = {observation} id = {observation.id} onComplete = {this.onComplete}/>
      return (
        <div>
          {card}
        </div>
      );
    }
  }


}

const mapStateToProps = (state,props) =>
{
  const observation = state.getIn(['observations',props.match.params.id]);

  return{
    observation: observation,
  };
}

export default connect(mapStateToProps)(ObservationsBotCall);
