import React from 'react';
import scrollToComponent from 'react-scroll-to-component';

// TODO add keyboard controls (arrows, tabs)
class Autosuggest extends React.PureComponent {
  constructor(props){
    super(props);
    this.input = React.createRef();
    this.state = { query: '', selected: false };
  }

  render(){
    const { suggestions,onBlur,inputRef,disabled, ...rest } = this.props;
    let resultsBox;
    let inputValue;

    if(this.state.query){
      inputValue = this.state.query;
      const splitQuery = this.state.query.toLowerCase().split(' ');

      const filtered = [];

      for(let key in suggestions){
        if(filtered.length > 9){ break; }
        const s = suggestions[key];
        const label = (s.text || '').toLowerCase();
        const matches = splitQuery.every(q => label.includes(q) );
        if(matches){ filtered.push(s) }
      }

      const results = filtered.map( s => {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a className="list-group-item p-2 list-group-item-action" key={s.key} data-to={s.key} onClick={this.onSelect}>
            {s.text}
          </a>
        );
      });

      if(results.length > 0 && !this.state.selected){
        resultsBox = (
          <div key="rb" className="border-left border-right border-bottom rounded-bottom" style={{maxHeight: '60vh', overflow: 'scroll'}}>
            <div className="list-group-flush">
              {results}
            </div>
          </div>
        );
      }
    } else {
      if(this.props.value){
        if(this.props.suggestions[this.props.value] && this.props.suggestions[this.props.value]['text']){
          // desired, means that there is a value, a suggestion entry, and that entry has a text key
          inputValue = this.props.suggestions[this.props.value]['text'];
        } else {
          // fallback for now, some languages don't have some keys, just show the key as is
          inputValue = this.props.value;
        }
      } else {
        // no value, so make the input empty
        inputValue = '';
      }
    }

    return (
      <React.Fragment>
        <input {...rest} ref={this.input} onFocus={this.onFocus} value={inputValue} onChange={this.queryChange} onBlur={onBlur} placeholder="search..." disabled={disabled}/>
        {resultsBox}
      </React.Fragment>
    );

  }

  onFocus = () => {
    const opts = {align: 'top', offset: -40, behavior: 'smooth', duration: 500};
    scrollToComponent(this.input.current,opts);
  }

  onSelect = (e) => {
    const to = e.target.getAttribute('data-to');
    this.setState({ query: '', selected: true });
    this.props.onChange({[this.props.attribute]: to} );
  }

  queryChange = (e) => {
    this.setState({ query: e.target.value, selected: false });
    this.props.onChange({[this.props.attribute]: null});
  }
}

export default Autosuggest;