import React from 'react';

import VisitsListItem from './visits_list_item';

class VisitsList extends React.PureComponent {

  render(){
    const visits = this.props.visits.toIndexedSeq().map(v => <VisitsListItem key={v.id} visit={v} />)
    return (
      <div>
        <div>{visits.size > 0 ? visits : 'No visits added yet'}</div>
      </div>
    );
  }
}

export default VisitsList;