import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import AccountActions from '../actions/account_actions';
import BusinessCategoryActions from '../actions/business_category_actions';

import { timezoneParams } from '../helpers/timezone_helpers';

import JSONForm from '../../shared/components/json_form';

const schema = Immutable.fromJS({
  required: [
    "first_name",
    "last_name",
    "phone",
    "dob",
    "locale",
    "country"
  ],
  properties: {
    "first_name": {type: "string"},
    "last_name": {type: "string"},
    "phone": {type: "string", format: "phone"},
    "paypal_email": {type: "string", format: "email"},
    "dob": {type: "string", format: "date" },
    "country": {type: "string", enum: ["mexico","nigeria","brazil","colombia","other"]},
    "brazil_cpf": {"type": ["string","null"], "minLength": 11, "maxLength": 11 },
    "brazil_cnpj": {"type": ["string","null"], "minLength": 14, "maxLength": 14 }
  },
  allOf: [
    {
      anyOf: [
        { not: { "required": ["country"], "properties": {"country": {"enum": ["brazil"]}}} },
        { required: ["brazil_cpf"] }
      ]
    }
  ]
});

const form_schema = Immutable.fromJS({
  elements: [
    { 
      "name": "attributes",
      "type": "section", 
      "elements": [
        { "type": "field", "field_type": "text", "property": "first_name", "required": true },
        { "type": "field", "field_type": "text", "property": "last_name", "required": true }, 
        { "type": "field", "field_type": "date", "property": "dob", "required": true },
        { "type": "field", "field_type": "select", "property": "country", "required": true },
      ]
    },{
      "name": "mexico",
      "type": "section",
      "elements": [
        { "type": "field", "field_type": "phone", "property": "phone", "country_code": "52", "mask": "(##) #### ####", "required": true },
        { "type": "field", "field_type": "email", "property": "paypal_email" }
      ],
      "display_if": {"required": ["country"], "properties": {"country": {"enum": ["mexico"]}} } 
    },{ 
      "name": "brazil",
      "type": "section", 
      "elements": [
        { "type": "field", "field_type": "phone", "property": "phone", "country_code": "55", "mask": "(##) ##### ####", "required": true },
        { "type": "field", "field_type": "masked_number", "property": "brazil_cpf", "required": true, "mask": "###.###.###-##" },
        { "type": "field", "field_type": "masked_number", "property": "brazil_cnpj", "mask": "##.###.###/####-##", "help": "explorers.form.brazil_cnpj.help" },
      ],
      "display_if": { "required": ["country"], "properties": {"country": {"enum": ["brazil"]}} }
    }
    
  ]
});


class AccountForm extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = { 
      googleQuery: '',
      submitting: false,
    } 
  }

  componentDidMount(){
    if(this.props.explorer){
      this.props.loadForm(this.props.explorer.toJSON());
    }
  }

  render(){
    const { formKey, i18nKey, mode } = this.props;

    return (
      <div className="account-form">
        <JSONForm
          i18nKey={i18nKey}
          errori18nKey="explorers.errors"
          schema={schema}
          form_schema={form_schema}
          formKey={formKey}
          sendRemoteValidation={this.sendRemoteValidation}
          onSubmit={this.onSubmit}
          onComplete={this.onComplete}
          mode={mode}
        />
      </div> 
    );
  }

  onSubmit = () => {
    const params = Object.assign({},this.props.form.toJSON(),timezoneParams());
    BusinessCategoryActions.index(params.locale)
    return AccountActions.update(params).then(account => {
      if(this.props.onSubmit){ 
        return this.props.onSubmit(account) 
      } else {
        return Promise.resolve();
      }
    });
  }

}

const mapStateToProps = (initialState,initialProps) => {
  const { formKey } = initialProps;
  return (state) => ({
    form: state.getIn(['forms',formKey]),
  });
};

const mapDispatchToProps = (initialState,initialProps) => {
  const { formKey } = initialProps;
  return (dispatch) => ({
    loadForm: (data) => dispatch({ type: 'forms.load', data: {[formKey]: data }}),
  });
};

export default connect(mapStateToProps,mapDispatchToProps)(AccountForm);
