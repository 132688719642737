import React from 'react';

import { I18nProvider } from './i18n';
import { translate } from '../helpers/i18n_helpers';

import en from '../../locales/en.json';
import es from '../../locales/es.json';
import pt from '../../locales/pt.json';

const locales = {
  en,
  es,
  pt
}

class AppI18nProvider extends React.PureComponent{
  render(){
    const locale = this.props.locale;
    
    return (
      <I18nProvider translations={this.translations()} locale={locale} t={this.t}>
        {this.props.children}
      </I18nProvider>
    )
  }

  translations = () => {
    const locale = this.props.locale;
    return (locales[locale] || en);
  }

  t = (k,params) => {
    const translations = this.translations();
    let templateKey;
    if(k && k.reduce){
      templateKey = k.reduce((chosenKey,key) => {
        if(chosenKey){ return chosenKey; }
        if(translations[key]){ return key; }
        return null;
      },undefined);
    } else {
      templateKey = k;
    }
    const template = translations[templateKey];
    if(template){
      return translate(this.props.locale,template,params); 
    } else {
      return '`'+(templateKey || k)+'`'
    }
    
  }
}

AppI18nProvider.defaultProps = {
  locale: 'en',
}

export default AppI18nProvider;
