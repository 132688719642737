import React from 'react';
import { connect } from 'react-redux';
import isNil from 'lodash/isNil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import LiveTimer from '../../shared/components/live_timer';


class ValidatorTaskBar extends React.PureComponent
{
  render()
  {
    const{ task,prevTaskId } = this.props;
    let left;
    let timer;

    if(task){
      left = (
        <div>
          {task.type}
        </div>
      );

      timer = (
        <LiveTimer start={task.assigned_at}/>
      );
    }

    let queue
    if(!isNil(this.props.queue_size)){
      queue = new Intl.NumberFormat('en-US', { notation: "compact" , compactDisplay: "short" }).format(this.props.queue_size);
    } else {
      queue = <FontAwesomeIcon icon={faSpinner} spin size="sm"/>;
    }

    let completed
    if(!isNil(this.props.completed_today)){
      completed = this.props.completed_today
    } else {
      completed = <FontAwesomeIcon icon={faSpinner} spin size="sm"/>;
    }

    let linkPrevTask
    if(prevTaskId){
      linkPrevTask = <a href ={`/tasks/${prevTaskId}/edit`} target="_blank" rel="noopener noreferrer" className="badge badge-tasks">{prevTaskId}</a>
    }
    return (
      <div className="row p-3" style={{marginTop: '-0.5rem'}}>
        <div className="col">
          {left}
        </div>
        <div className="col text-center">
          {timer}
        </div>
        <div className="col text-right">
          <span className="ml-2">Queue: {queue}</span>
          <span className="ml-2">Completed Today: {completed}</span>
          <span className="ml-2">Prev task: {linkPrevTask}</span>
        </div>
      </div>

    )
  }
}

const mapStateToProps = state => {
  return {
    queue_size: state.getIn(['page','queue_size']),
    completed_today: state.getIn(['page','completed_today']),
  }
};


export default connect(mapStateToProps)(ValidatorTaskBar);
