

import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  index: function(params){
    const q = Object.assign({sort: 'explorer_projects.id', sort_dir: 'desc'},params,{page_size: 25});
    return authorizedFetch('/v1/admin/explorer_projects',q).then(handleResponse("explorer_projects", "load"));
  },
  create: function(params){
    const opts = {
      method: 'POST',
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v1/admin/explorer_projects`,null,opts).then(handleResponse("explorer_projects", "merge"));
  },
  update: function(id,params){
    const opts = {
      method: 'PUT',
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v1/admin/explorer_projects/${id}`,null,opts).then(handleResponse("explorer_projects", "merge"));
  }
};
