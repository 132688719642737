
import Immutable from 'immutable';

const defaults = {
  currency: null,
  currency_prefix: null,
  id: null,
  minimum_contract_date: null,
  minimum_training_date: null,
  minimum_conditions_date: null,
  name: null,
  onboarding_form: Immutable.fromJS({}),
  quiz: Immutable.fromJS({}),
  training: Immutable.fromJS({}),
  contract_key: null,
  relationships: Immutable.fromJS({}),
};

class Region extends Immutable.Record(defaults){

}

export default Region;
