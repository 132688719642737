import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import ExplorersActions from '../actions/explorers_actions';

import { regionIds } from '../../shared/helpers/region_helpers';

import JForm from '../../shared/components/j_form';

const schemas = {
  br: {
    schema: Immutable.fromJS({
      "properties": {
        "email": { "type": ["string","null"] },
        "first_name": {"type": ["string","null"]},
        "last_name": {"type": ["string","null"]},
        "phone": {"type": ["string","null"], "pattern": "^\\+55[0-9]{2}9[0-9]{8}$", "format": "phone"},
        "dob": {"type": ["string","null"], "format": "date"},
        "brazil_cpf": {"type": ["string","null"], "minLength": 11, "maxLength": 11, "pattern": "^[0-9]{11}$" },
        "brazil_cnpj": {"type": ["string","null"], "minLength": 14, "maxLength": 14, "pattern": "^[0-9]{14}$"  },
        "referral_code": {"type": ["string","null"]},
        "region_id": { type: "string", enum: regionIds },
      }
    }),
    elements: Immutable.fromJS([
      { "property": "email", "type": "email_field", "i18n_prefix": "explorers.form","required": true },
      { "property": "first_name", "type": "text_field", "required": true },
      { "property": "last_name", "type": "text_field", "required": true },
      { "property": "phone", "type": "phone_widget", "mask": "+55 (##) ##### ####", "country_code": "55", "required": true},
      { "property": "dob", "type": "dob_widget", "required": true, "minimum_age": 16 },
      { "property": "referral_code", "type": "text_field", "i18n_prefix": "explorers.form", "required": true },
      { "property": "brazil_cpf", "type": "text_field", "required": true, "mask": "###.###.###-##", "i18n_prefix": "onboarding.form.region.br" },
      { "property": "brazil_cnpj", "type": "text_field", "required": true, "mask": "##.###.###/####-##", "i18n_prefix": "onboarding.form.region.br" },
      { "type": "select_field", "property": "region_id", "i18n_prefix": "regions", "required": true, "null_selectable": true },
    ])
  },
  mx: {
    schema: Immutable.fromJS({
      "properties": {
        "email": { "type": ["string","null"] },
        "first_name": {"type": ["string","null"]},
        "last_name": {"type": ["string","null"]},
        "phone": { "type": ["string","null"], "pattern": "^\\+52[0-9]{10}$", "format": "phone" },
        "dob": {"type": ["string","null"], "format": "date"},
        "paypal_email": { "type": [ "string", "null"], "format": "email" },
        "referral_code": {"type": ["string","null"]},
        "region_id": { type: "string", enum: regionIds },
      }
    }),
    elements: Immutable.fromJS([
      { "property": "email", "type": "email_field", "i18n_prefix": "explorers.form", "required": true },
      { "property": "first_name", "type": "text_field", "required": true },
      { "property": "last_name", "type": "text_field", "required": true },
      { "property": "phone", "type": "phone_widget", "mask": "+52 (##) #### ####", "country_code": "52", "required": true},
      { "property": "dob", "type": "dob_widget", "required": true, "minimum_age": 16 },
      { "property": "referral_code", "type": "text_field", "i18n_prefix": "explorers.form", "required": true },
      { "type": "email_field", "property": "paypal_email", "i18n_prefix": "onboarding.form.region.mx" },
      { "type": "select_field", "property": "region_id", "i18n_prefix": "regions", "required": true, "null_selectable": true },
    ])
  },
  default: {
    schema: Immutable.fromJS({
      "properties": {
        "email": { "type": ["string","null"] },
        "first_name": {"type": ["string","null"]},
        "last_name": {"type": ["string","null"]},
        "phone": {"type": ["string","null"], "format": "phone"},
        "referral_code": {"type": ["string","null"]},
        "region_id": { type: "string", enum: regionIds },
      }
    }),
    elements: Immutable.fromJS([
      { "property": "email", "type": "email_field", "i18n_prefix": "explorers.form","required": true },
      { "property": "first_name", "type": "text_field", "required": true },
      { "property": "last_name", "type": "text_field", "required": true },
      { "property": "phone", "type": "phone_widget", "required": true},
      { "property": "dob", "type": "dob_widget", "required": true, "minimum_age": 16 },
      { "property": "referral_code", "type": "text_field", "i18n_prefix": "explorers.form", "required": true },
      { "type": "select_field", "property": "region_id", "i18n_prefix": "regions", "required": true, "null_selectable": true },
    ])
  }
};


class ExplorersFormPanel extends React.PureComponent {

  render(){
    const {region,data_key,form} = this.props;

    const {schema,elements} = schemas[form.get('region_id') || region.id] || schemas.default;

    return (
      <div>
        <div className="row">
          <div className="col">
            <pre>
              {JSON.stringify(form.toSeq().sortBy((v,k) => k).toJSON(),null,2)}
            </pre>
          </div>
          <div className="col">
            <JForm 
              elements={elements}
              schema={schema}
              data_key={data_key}
              i18n_prefix="onboarding.form"
              submit_type="button"
              show_validation={true}
              on_submit={this.onSubmit}
              mode="update"
            />
          </div>
        </div>
      </div>
    );
  }

  onSubmit = (formData) => {
    return ExplorersActions.update(this.props.explorer.id,{explorer: formData}).then(json => {
      this.props.dispatch({type: "forms.load", data: {[this.props.data_key]: json.data.attributes}});
    });
  }

}

const mapStateToProps = (initialState,initialProps) => {
  const explorerId = initialProps.match.params.id;
  const data_key = `explorer/${explorerId}`;
  return (state) => {
    const explorer = state.getIn(['explorers',explorerId]);
    return ({
      explorer,
      data_key,
      region: state.getIn(['regions',explorer.region_id]),
      form: state.getIn(['forms',data_key])
    });
  };
};


export default connect(mapStateToProps)(ExplorersFormPanel);
