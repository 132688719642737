import React from 'react';
import { Route } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux';

import Store from '../reducers/store';

import AuthenticatedRoute from './authenticated_route';
import Navbar from './navbar';
import ExplorersIndex from './explorers_index';
import ExplorersEdit from './explorers_edit';
import ExplorersPaymentsIndex from './explorers_payments_index';
import ExplorersQuotasIndex from './explorers_quotas_index';
import SamplesIndex from './samples_index';
import DeliveriesIndex from './deliveries_index'
import SamplesShow from './samples_show';
import DeliveriesShow from './deliveries_show';
import PlacesIndex from './places_index';
import AdminsIndex from './admins_index';
import PlacesShow from './places_show';
import TasksIndex from './tasks_index';
import TasksShow from './tasks_show';
import ObservationsIndex from './observations_index';
import ObservationsBotCallsIndex from './observations_botcalls_index';
import ObservationsShow from './observations_show';
import LeadsIndex from './leads_index';
import AdminsShow from './admins_show';
import LeadsEdit from './leads_edit';
import ProjectsIndex from './projects_index';
import ProjectsEdit from './projects_edit';
import SignIn from './sign_in';
import ValidatorIndex from './validator_index';

import AppI18nProvider from '../../shared/components/app_i18n_provider';

import BusinessCategoryActions from '../actions/business_category_actions';

class App extends React.Component {

  componentDidMount(){
    BusinessCategoryActions.index('en');
  }

  render() {
    return (
      <Provider store={Store}>
        <AppI18nProvider locale="en">
          <BrowserRouter>
            <div id="adminApp" className="App">
              <Navbar />
              <Route exact path="/signin" component={SignIn} />
              <div className="container-fluid mt-2">
                <AuthenticatedRoute exact path="/" component={PlacesIndex} />
                <AuthenticatedRoute path="/explorers/:id" component={ExplorersEdit} />
                <AuthenticatedRoute exact path="/explorers" component={ExplorersIndex} />
                <AuthenticatedRoute exact path="/explorers_payments" component={ExplorersPaymentsIndex} />
                <AuthenticatedRoute exact path="/explorers_quotas" component={ExplorersQuotasIndex} />
                <AuthenticatedRoute path="/samples/:id" component={SamplesShow} />
                <AuthenticatedRoute exact path="/samples" component={SamplesIndex} />
                <AuthenticatedRoute exact path="/deliveries" component={DeliveriesIndex} />
                <AuthenticatedRoute path="/deliveries/:id" component={DeliveriesShow} />
                <AuthenticatedRoute path="/places/:id" component={PlacesShow} />
                <AuthenticatedRoute exact path="/places" component={PlacesIndex} />
                <AuthenticatedRoute path="/observations/:id" component={ObservationsShow} />
                <AuthenticatedRoute exact path="/observations" component={ObservationsIndex} />
                <AuthenticatedRoute exact path="/observations_botcalls" component={ObservationsBotCallsIndex} />
                <AuthenticatedRoute path="/leads/:id" component={LeadsEdit} />
                <AuthenticatedRoute exact path="/leads" component={LeadsIndex} />
                <AuthenticatedRoute path="/projects/:id" component={ProjectsEdit} />
                <AuthenticatedRoute exact path="/projects" component={ProjectsIndex} />
                <AuthenticatedRoute path="/tasks/:id" component={TasksShow} />
                <AuthenticatedRoute exact path="/tasks" component={TasksIndex} />
                <AuthenticatedRoute exact path="/admins" component={AdminsIndex} />
                <AuthenticatedRoute path="/admins/:id" component={AdminsShow} />
                <AuthenticatedRoute exact path="/validator" component={ValidatorIndex} />
              </div>
            </div>
          </BrowserRouter>
        </AppI18nProvider>
      </Provider>
    );
  }
}

export default App;
