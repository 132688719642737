import geodist from 'geodist';
import natural from 'natural';
import isNil from 'lodash/isNil';


export const isSaleable = (place) => {
  const c = place.confidence;
  if(c){
    if( ((c.name||0) > 0.5) && ((c.street_number||0) > 0.5) && ((c.street_name||0) > 0.5) && ((c.phone||0) > 0.5)){
      return true
    }
  }
  return false;
}

export const compare_score = (place1, place2) =>
{
  const scores = [
  distanceScore(place1, place2),
  place1.name && place2.name ? jwScore(place1.name, place2.name): undefined,
  place1.phone && place2.phone ? phoneScore(place1.phone, place2.phone): undefined,
  place1.mobile && place2.mobile ?  phoneScore(place1.mobile, place2.mobile): undefined,
  place1.address && place2.address ? jwScore(place1.address, place2.address): undefined,
  place1.address2 && place2.address2 ? jwScore(place1.address2, place2.address2): undefined,
  place1.neighborhood && place2.neighborhood ? jwScore(place1.neighborhood, place2.neighborhood): undefined,
  place1.city && place2.city ? jwScore(place1.city, place2.city): undefined,
  place2.postal_code && place2.postal_code ? jwScore(place1.postal_code, place2.postal_code): undefined
  ]

  const weights = [2,4,2,2,2,1,1,1,1]

  var sumWeights = 0
  var sumWeightedScores = 0
  for (var index = 0; index < scores.length; index++)
  {
    if(!isNil(scores[index]))
    {
      sumWeights += weights[index]
      sumWeightedScores += scores[index] * weights[index]
    }
  }
  return sumWeightedScores / sumWeights
}

export const jwScore = (string1, string2) =>
{
  if(isNil(string1) || isNil(string2)){ return null; }  
  return natural.JaroWinklerDistance(string1.toLowerCase().trim(), string2.toLowerCase().trim())
}

export const jwScoreAsPercentage = (string1, string2) =>
{
  return parseInt(jwScore(string1, string2) * 100)
}

export const distanceScore = (point1, point2) =>
{
  const distance = geodist({lat: point1.latitude, lon: point1.longitude},{lat: point2.latitude,lon: point2.longitude},{unit: 'meters'})
  return Math.max(0, 1- distance/150)
}

export const distanceScoreAsPercentage = (point1, point2) =>
{
  return parseInt(distanceScore(point1, point2) * 100)
}

export const phoneScore = (phone1, phone2) =>
{
  if(isNil(phone1) || isNil(phone2)){ return null; }
  return Math.pow(natural.DamerauLevenshteinDistance(phone1, phone2),4)
}

export const phoneScoreAsPercentage = (phone1, phone2) =>
{
  return parseInt(phoneScore(phone1, phone2) * 100)
}
