import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import isNil from 'lodash/isNil';
import RetinaImage from 'react-retina-image';

import JSONInputField from './json_input_field';

// eslint-disable-line no-undef
const assetsServer = process.env.REACT_APP_ASSETS_SERVER;
const fieldElement = Immutable.fromJS({ "type": "field", "field_type": "text", "property": "name", "required": true });

class JSONNewBusinessNameWidget extends React.PureComponent {

  render(){
    const {element,form,errors,warnings,matching_places,validateFormProperties,i18nKey,...rest} = this.props
    
    const v = form.get('name');
    const value = isNil(v) ? '' : v;

    const fieldProps = {
      ...rest,
      element: fieldElement,
      i18nKey,
      property: 'name',
      error: errors.get('name'),
      warning: warnings.get('name'),
      value,
      validateFormProperties,
      updateForm: this.updateForm,
    };

    let matches;

    if(matching_places && matching_places.size > 0){
      matches = matching_places.toIndexedSeq().map(mp => {
        const key = mp.exterior_photo.replace(/\.\w+$/,'');
        return (
          <li className="list-group-item border-warning" key={mp.id}>
            <div className="media">
              <RetinaImage 
                className="mr-3"
                height="64"
                width="64"
                src={`${assetsServer}/${key}_64.jpg`} 
                alt={mp.name}
                checkIfRetinaImgExists={false}
              />
              <div className="media-body">
                <h5 className="mt-0">{mp.name}</h5>
                <p className="small">{mp.address}</p>
              </div>
            </div>
          </li>
        );
      });
    }

    
    
    return(
      <div className="mb-2">
        <JSONInputField {...fieldProps} />
          <ul className="list-group">
            {matches}
          </ul>
      </div>
    );
  }

  updateForm = (update) => {
    const {matching_places,resetMatchingPlaces,updateForm} = this.props;

    if(matching_places && matching_places.size > 0){
      resetMatchingPlaces();
    }
    updateForm(update);
  }

}

const mapStateToProps = state => ({
  matching_places: state.get('matching_places')
});

const mapDispatchToProps = {
  resetMatchingPlaces: () => ({ type: 'matching_places.load', data: [] })
};

export default connect(mapStateToProps,mapDispatchToProps)(JSONNewBusinessNameWidget);
