import React from 'react';

import LiveDate from '../../shared/components/live_date';

const SnoozeCard = (props) => {
  const {place_validation} = props;
  return (
      <div className="card border-primary">
        <div className="card-header">Snooze info</div>
        <div className="card-body border-primary">
          <p>Snoozed by <b>{place_validation.getIn(['response','meta','snoozer_name'])}</b> <LiveDate date={place_validation.getIn(['response','meta','snoozed_at'])} /></p>
          <p>Notes:</p>
          <pre>{place_validation.getIn(['response','meta','snooze_notes'])}</pre>
        </div>
      </div> 
    );
}

export default React.memo(SnoozeCard);