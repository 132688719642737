import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { Switch,Link,Route } from 'react-router-dom';

import ProjectsActions from '../actions/projects_actions';

import ProjectsFormPanel from './projects_form_panel';
import ProjectsExplorersPanel from './projects_explorers_panel';
import ProjectsVisitsPanel from './projects_visits_panel';
import LiveDate from './live_date';


// <Route exact path="/projects/:id/edit" component={ProjectsFormPanel} />
// <Route exact path="/projects/:id/visits" component={ProjectsVisitsPanel} />

class ProjectsEdit extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = { 
      tab: 'details',
      loaded: false,
    };
  }

  componentDidMount(){
    ProjectsActions.show(this.props.match.params.id).then(json => {
      this.props.loadForm({'projects/edit': json.data.attributes});
      this.setState({loaded: true});
    })
  }

  render(){
    if(this.state.loaded && this.props.project){
      const project = this.props.project;
      const subpath = this.props.location.pathname.match(/projects\/\d+\/(\w+)/)[1];

      return (
        <div id="projectsShow" className="mb-5">
          <div className="row mt-3">
            <div className="col">
              <h1 className="h2"><span className="badge badge-danger">{project.id}</span> {project.name}</h1>
              <p>Added <LiveDate date={project.created_at} />, updated <LiveDate date={project.updated_at} /></p>
              
            </div>
          </div>

          <div className="row mt-3">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'edit')})} to={`/projects/${project.id}/edit`}>Details</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'explorers')})} to={`/projects/${project.id}/explorers`}>Explorers</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'visits')})} to={`/projects/${project.id}/visits`}>Visits</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'actions')})} to={`/projects/${project.id}/actions`}>Actions</Link>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <Switch>
                    <Route exact path="/projects/:id/edit" component={ProjectsFormPanel} />
                    <Route exact path="/projects/:id/explorers" component={ProjectsExplorersPanel} />
                    <Route exact path="/projects/:id/visits" component={ProjectsVisitsPanel} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      )  
    } else {
      return 'loading...'
    }
    
  }

  switchTab = (e) => {
    e.preventDefault();
    this.setState({tab: e.target.getAttribute('data-tab')})
  }
}

const mapStateToProps = (state,props) => {
  const id = parseInt(props.match.params.id,10);
  return {
    project: state.get('projects').find(p => p.id === id),
  }
};

const mapDispatchToProps = {
  loadForm: (data) => ({ type: 'forms.load', data }),
}

export default connect(mapStateToProps,mapDispatchToProps)(ProjectsEdit);

