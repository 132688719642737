import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  create: function(params){
    const opts = {
      method: 'POST',
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v3/admin/merge_events`,null,opts);
  },
};
