import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers,faLock,faTimes,faCheck } from '@fortawesome/pro-light-svg-icons';

import LiveDate from './live_date';

import ExplorerProjectsActions from '../actions/explorer_projects_actions';

class ExplorersProjectRow extends React.PureComponent {

  render(){

    const { explorer_project } = this.props;

    let closedIcon;
    let closedColor;
    if(explorer_project.closed){
      closedIcon = faLock;
      closedColor = "warning";
    } else {
      closedIcon = faUsers;
      closedColor = "success";
    }


    let disabledIcon;
    let disabledColor;
    if(explorer_project.disabled){
      disabledIcon = faTimes;
      disabledColor = "danger";
    } else {
      disabledIcon = faCheck;
      disabledColor = "success";
    }

    let disabled_at;
    if(explorer_project.disabled_at){
      disabled_at = <LiveDate date={explorer_project.disabled_at} />
    } else {
      disabled_at = <button className="btn btn-danger btn-sm py-0" onClick={this.disable}>Disable</button>
    }

    return (
      <tr>
        <td><Link to={`/projects/${explorer_project.project_id}/edit`} className="badge badge-danger">{explorer_project.project_id}</Link></td>
        <td>{explorer_project.name}</td>
        <td><FontAwesomeIcon icon={closedIcon} className={`mx-auto d-block text-${closedColor}`} size="lg" /></td>
        <td><FontAwesomeIcon icon={disabledIcon} className={`mx-auto d-block text-${disabledColor}`} size="lg" /></td>
        <td><LiveDate date={explorer_project.created_at} /></td>
        <td><LiveDate date={explorer_project.training_completed_at} /></td>
        <td><LiveDate date={explorer_project.quiz_completed_at} /></td>
        <td className="text-center">{disabled_at}</td>
      </tr>
    )
  }

  disable = () => {
    ExplorerProjectsActions.update(this.props.explorer_project.id,{
      data: {
        attributes: {
          disabled_at: (new Date()).toISOString()
        }
      }
    });
  }
}

ExplorersProjectRow.columns = [
  { col: "project_id", label: "Id" },
  { col: "name", label: "Name" },
  { col: "closed", label: "Closed" },
  { col: "disabled", label: "Active" },
  { col: "created_at", label: "Joined" },
  { col: "training_completed_at", label: "Training" },
  { col: "quiz_completed_at", label: "Quiz" },
  { col: "disabled_at", label: "Disabled" },
];

export default ExplorersProjectRow;