import React from 'react';
import cn from 'classnames';

import T from '../../shared/components/t';

import { statusBadgeClassColor } from '../helpers/place';

const validationStatusKey = (validated) => {
  if(validated === true){
    return 'validated';
  } else if (validated === false){
    return 'rejected';
  } else {
    return 'pending';
  }
}

class PlaceStatusBadge extends React.PureComponent {
  render(){
    const { validated,className } = this.props;
    const status = validationStatusKey(validated)

    const classes = cn(`place-status-badge badge badge-${statusBadgeClassColor(validated)}`,className);
    return (
      <span className={classes} data-state={status}>
        <T k={`visits.index.status.${status}`} />
      </span>
    );
  }
}

export default PlaceStatusBadge;