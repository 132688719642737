import React from 'react';
import { Link } from 'react-router-dom';
import PlacesPatchRow from './places_patch_row'
import {raw} from '../../shared/schemas/data_schema';


class TasksBotCallAuditCardBody extends React.PureComponent
{

  render()
  {
    const {task} = this.props;

    const patch = task.response.getIn(["data","attributes","patch"])
    const associationIds = task.get("association_ids")
    const attrOrder = Object.keys(raw.properties)


    let rows
    if(patch)
    {
      rows = patch.sortBy((v,k) => ((attrOrder.indexOf(v.get("p").substring(1))+1) || 999 )).map(row => {
        return <PlacesPatchRow patch = {row}/>
      });
    }
    let alinksrows
    if(associationIds)
    {
      alinksrows = associationIds.map(row => {
        return <div className="row mb-2">
          <div className="col">
            <div><Link to={`/observations/${row}/`} className={"badge badge-primary"}>{row}</Link></div>
          </div>
        </div>
      });
    }
   return (
      <div>
        <div className="row mb-2">
          <div className="col">
            <h6>Admin id:</h6>
            <code className="mb-2">{JSON.stringify(task.admin_id)}</code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Assigned at:</h6>
            <code className="mb-2">{JSON.stringify(task.assigned_at)}</code>
          </div>
        </div>
        {alinksrows}
        <div className="card-body">
          {rows}
        </div>
      </div>
    );
  }
}

export default TasksBotCallAuditCardBody;
