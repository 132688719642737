import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';


export default {
  index: function(params, actionType = "load")
  {
    const q = Object.assign({sort: 'delivery.id', sort_dir: 'desc'},params,{page_size: 25});
    return authorizedFetch('/v3/admin/deliveries').then(handleResponse("deliveries", actionType));

  },
  show: function(id)
  {
     return authorizedFetch(`/v3/admin/deliveries/${id}`).then(handleResponse("deliveries", "merge"));
  },

  destroy: function(id, place_ids)
  {
    const opts = {
    method: 'DELETE',
    ids: place_ids
    };    
    return authorizedFetch(`/v3/admin/deliveries/${id}/places`, null, opts).then(handleResponse("deliveries", "merge"));
  }
};
