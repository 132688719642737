import React from 'react';
import { connect } from 'react-redux';
import PlacesPatchRow from './places_patch_row'
import {raw} from '../../shared/schemas/data_schema';
import PlacesOverviewPatchPanel from './places_overview_patch_panel'
import PlacesActions from '../actions/places_actions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
;
class PlacesOverviewPanel extends React.Component
{
  constructor(props){
    super(props);
    this.state = {
      patch: [],
      tab: 'overview',
      loaded: false,
    };
  }


  componentDidMount()
  {
    PlacesActions.show(this.props.match.params.id).then(json => {
      this.setState({patch: json.data.attributes.patch})
      this.setState({loaded: true});
      this.setState({date: new Date()})
    })
  }

  render()
  {
    if(this.state.loaded == false)
      return null
    const onChangeDate = (date) => {
        this.setState({date: date})
    }

    const { place } = this.props;

    const patch = this.state.patch
    const attrOrder = Object.keys(raw.properties)

    let rows
    if(patch)
    {
      rows = <PlacesOverviewPatchPanel patch = {patch} date ={this.state.date}/>
    }

    return (
      <div className="row">
        <div className="col-12">
          <div  className="card">
            Select a date:
            <DatePicker
              selected={this.state.date}
              onChange ={onChangeDate}
            />
            <div className="card-header h5">
              Patch:
            </div>
            <div className="card-body">
              {rows}
            </div>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state,props) =>
{
  return{
    place: state.getIn(['places',props.match.params.id])
  };
};

export default connect(mapStateToProps)(PlacesOverviewPanel);
