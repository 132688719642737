import React from 'react';
import { connect } from 'react-redux';

class DeliveriesDataPanel extends React.Component {

  render(){
    const { delivery } = this.props;
    return (
      <div className="samples-data mb-2">
        <pre>{JSON.stringify(delivery.toJSON(),null,2)}</pre>
      </div>
    )
  }
}

const mapStateToProps = (state,props) =>
{
  return{
    delivery: state.getIn(['deliveries',props.match.params.id])
  };
};

export default connect(mapStateToProps,null)(DeliveriesDataPanel);
