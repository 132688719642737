import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import ExplorersActions from '../actions/explorers_actions';

import JForm from '../../shared/components/j_form';


const schema = Immutable.fromJS({});

const elements = Immutable.fromJS([
  { "property": "notes", "type": "textarea_field"},
]);


class ExplorersNotesPanel extends React.PureComponent {
  render(){
    const { data_key } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col">
            <JForm 
              elements={elements}
              schema={schema}
              data_key={data_key}
              i18n_prefix="explorers.form"
              submit_type="button"
              show_validation={true}
              on_submit={this.onSubmit}
              mode="update"
            />
          </div>
        </div>
      </div>
    );
  }

  onSubmit = (formData) => {
    return ExplorersActions.update(this.props.explorer.id,{explorer: formData}).then(json => {
      this.props.dispatch({type: "forms.load", data: {[this.props.data_key]: json.data.attributes}});
    });
  }

}

const mapStateToProps = (initialState,initialProps) => {
  const explorerId = initialProps.match.params.id;
  const data_key = `explorer/${explorerId}`;
  return (state) => {
    const explorer = state.getIn(['explorers',explorerId]);
    return ({
      explorer,
      data_key,
      notes: state.getIn(['forms',data_key,'notes']),
    });
  };
};


export default connect(mapStateToProps)(ExplorersNotesPanel);
