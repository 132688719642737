import { createStore } from 'redux';
import { combineReducers } from 'redux-immutable';

import Pin from '../models/pin';
import MatchingPlace from '../models/matching_place';

import explorerReducer from './explorer_reducer';

import Place from '../../shared/models/place';
import Visit from '../../shared/models/visit';
import Project from '../../shared/models/project';
import Prevalidation from '../../shared/models/prevalidation';
import PlaceValidation from '../../shared/models/place_validation';
import Payable from '../../shared/models/payable';
import PricingItem from '../../shared/models/pricing_item';
import PricingStructure from '../../shared/models/pricing_structure';
import Region from '../../shared/models/region';

import { 
  createResourceReducer,
  createFormReducer,
  createFormChangesReducer,
  createFormErrorsReducer,
  createBasicReducer,
  createVanillaReducer,
  createSingleResourceReducer,
} from '../../shared/helpers/reducer_helpers';

const formsDefaults = {
  'registrations/new': {},
  'accounts/new': {},
  'visits/new': {},
  'accounts/edit': {},
  'leads/edit': {},
};

const referral_code = new URL(window.location.href).searchParams.get('referral_code') || window.localStorage.getItem('dpexplorer_referral_code');
window.localStorage.setItem('dpexplorer_referral_code',referral_code);

const pageDefaults = {
  referral_code: referral_code,
  visits_page: 1,
  visits_total: 0,
}

// this is the root reducer
const reducers = combineReducers({
  places:              createResourceReducer(Place,'place','places'),
  matching_places:     createResourceReducer(MatchingPlace,'matching_place','matching_places'),
  visits:              createResourceReducer(Visit,'visit','visits'),
  place_validations:   createResourceReducer(PlaceValidation,'place_validation','place_validtaions'),
  payables:            createResourceReducer(Payable,'payable','payables'),
  pricing_items:       createResourceReducer(PricingItem,'pricing_item','pricing_items'),
  pricing_structures:  createResourceReducer(PricingStructure,'pricing_structure','pricing_structures'),
  prevalidations:      createResourceReducer(Prevalidation,'prevalidation','prevalidations'),
  projects:            createResourceReducer(Project,'project','projects'),
  pins:                createResourceReducer(Pin,'pin','pins'),
  page:                createBasicReducer('page',pageDefaults),
  contracts:           createBasicReducer('contracts'),
  location:            createBasicReducer('location'),
  quiz_answers:        createBasicReducer('quiz_answers'),
  forms:               createFormReducer(formsDefaults),
  form_changes:        createFormChangesReducer(formsDefaults),
  form_errors:         createFormErrorsReducer(formsDefaults),
  form_warnings:       createFormErrorsReducer(formsDefaults,'form_warnings'),
  form_async_errors:   createFormErrorsReducer(formsDefaults,'form_async_errors'),
  form_async_warnings: createFormErrorsReducer(formsDefaults,'form_async_warnings'),
  business_categories: createVanillaReducer('business_categories',{}),
  region:              createSingleResourceReducer(Region,'region','regions'),
  explorer:            explorerReducer,
});

window.history.replaceState({},document.title,window.location.pathname);

const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);


export default store;
