import React from 'react';
import { connect } from 'react-redux';
import BotDialogPanel from './bot_dialog_panel'
import ObservationsBotCallStatusDataRow from './observations_bot_call_status_data_row'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlienMonster , faDeaf, faPhoneSlash} from '@fortawesome/pro-solid-svg-icons';
import ObservationsBotCallBotResults from './observations_bot_call_bot_result';
import { stringToBool } from '../helpers/observation_helpers';
import isNil from 'lodash/isNil';
import { v4 as uuidv4 } from 'uuid';
import PatchForm from './patch_form';
import Immutable from 'immutable';

class ObservationsBotCallCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wrong_intent:false,
      speech_to_text:false,
      telephony_issue:false
    };
  }

  componentDidMount(){
    const observation = this.props.observation
    const id = observation.id;
    this.props.dispatch({type:"forms.load", data:{[`observation/${id}`]: {
      name:observation.getIn(["response","name"]),
      address:observation.getIn(["response","address"]),
      name_verified:observation.getIn(["response", "name_verified"]),
      address_verified:observation.getIn(["response", "address_verified"]),
      error_wrong_intent:false,
      error_speech_to_text:false,
      error_telephony_issue:false,
    }}});
  }

  onClickError(error)
  {
    const observation = this.props.observation
    if(isNil(observation.response.get("audio_url")))
      return
    const id = observation.id;
    let toDispatch
    switch(error)
    {
      case 0://wrong intent
      toDispatch = {type:"forms.merge",
        data:{[`observation/${id}`]: {
          error_wrong_intent: !this.state.wrong_intent
        }}};
        this.props.dispatch(toDispatch);
        this.setState({wrong_intent:!this.state.wrong_intent})
      break;
      case 1://speech to text
      toDispatch = {type:"forms.merge",
        data:{[`observation/${id}`]: {
          error_speech_to_text: !this.state.speech_to_text
        }}};
        this.props.dispatch(toDispatch);
        this.setState({speech_to_text:!this.state.speech_to_text})
      break;
      case 2://telephony issue
      toDispatch = {type:"forms.merge",
        data:{[`observation/${id}`]: {
          error_telephony_issue: !this.state.telephony_issue
        }}};
        this.props.dispatch(toDispatch);
        this.setState({telephony_issue:!this.state.telephony_issue})
      break;
      default:
      break
  }
}

  confidenceChange = (value) => {
    this.setState({confidence: value})
    this.props.dispatch({
      type:"forms.merge", data:{
        [`observation/${this.props.observation.id}`]: {
          [`${this.props.attribute}_confidence`]: value
        }
      }
    })
  }

  onComplete = () =>
  {
    const { observation, form, form_errors, changes, patchData} = this.props;
    if(!isNil(form_errors) && form_errors.size === 0)
      this.props.onComplete(changes, form)
  }

  render()
  {
    const { observation, changes, formKey} = this.props;

    //const changes = observation.get("status_call")
    const audioUrl = observation.response.get("audio_url")
    const name     = observation.response.get("name")
    // const address  = observation.response.get("address");

    let botResults = []

    botResults.push(<ObservationsBotCallBotResults key="connected" attribute="connected" confirmed={stringToBool(observation.getIn(['response','connected']))}/> )
    botResults.push( <ObservationsBotCallBotResults key="answered" attribute="answered" confirmed={stringToBool(observation.getIn(['response','answered']))}/> )
    if(stringToBool(observation.getIn(['response','voicemail']))){
      botResults.push( <ObservationsBotCallBotResults key="voicemail" attribute="voicemail" confirmed={stringToBool(observation.getIn(['response','voicemail']))}/> )
    }
    let respContexts = observation.getIn(['response','contexts'])
    if(respContexts)
    {
      observation.getIn(['response','contexts']).forEach(context => {
      const attr = context.get('attribute')
      const confirmed = context.get("verified")
      // const stuff = observation.getIn(['input','contexts'])
      if(attr !== "voicemail")
        botResults.push(<ObservationsBotCallBotResults key={attr} attribute={attr}  confirmed={confirmed}/>);
      })
    }


    // const messages = observation.getIn(['response', 'transcript', 'transcript'])
    let statusCall
    let rows
    const status = changes ? changes.get("status_call"):undefined


    let patchFormData
    if(isNil(name))
    {
      statusCall = <ObservationsBotCallStatusDataRow observation = {observation}/>
        patchFormData = Immutable.fromJS({
          name: observation.getIn(['input','data', 'name']),
          address: observation.getIn(['input','data', 'address'])
        })
    }
    else
    {
      statusCall = <ObservationsBotCallStatusDataRow observation = {observation}/>
      const contexts = observation.getIn(['response', 'contexts'])
        const nameJO = contexts.find(c => c.get("attribute") === "name")
        const addressJO = contexts.find(c => c.get("attribute") === "address")
        patchFormData = Immutable.fromJS({
          name: isNil(nameJO)?undefined:nameJO.get("value"),
          address: isNil(addressJO)?undefined:addressJO.get("value"),
        })
    }

    const patchForm = <PatchForm formKey={formKey} pin={['name','address']} data={patchFormData}/>
    if(status)
    {
      rows = patchForm
    }
    const logs_fallback = isNil(observation.getIn(['response', 'logs_url']))?observation.getIn(['response', 'logs_url']):`https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logsV2:log-groups/log-group/bot_calls/log-events/${observation.uuid}`

    return (
      <div className="container mt-3" style ={{paddingLeft: "0px", paddingRight: "0px", maxWidth:"100%"}}>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <audio style={{width:"100%"}}
                controls
                src={audioUrl}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>

            </div>
            <div className="card mb-3" style={{borderColor:"white"}}>
              <div className="card-body">
                <BotDialogPanel observation={observation}/>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mb-3" style={{borderColor:"white"}}>
              <div className ="card-body">
                <div className="row border-bottom">
                  <div className = "col text-white mb-1">
                    Bot Results:
                  </div>
                </div>
                <div className = "row justify-content-start">
                  {botResults}
                </div>
              </div>
            </div>
            <div className="card mb-3" style={{borderColor:"white"}}>
              <div className ="card-body">
                <div className="row border-bottom">
                  <div className = "col text-white mb-1">
                    Human Results:
                  </div>
                </div>
                {statusCall}
                {rows}
              </div>
            </div>
            <div className="card"  style={{borderColor:"white"}}>
              <div className ="card-body">
                <div className = "my-3 text-white">
                  Errors
                </div>
                <div className = "row">
                  <div className = "col-4 text-center">
                    <div className = "row">
                      <div className = "col">
                        <button className={`btn btn-lg btn-${this.state.wrong_intent === true?"danger":"dark"}`} onClick ={() =>this.onClickError(0)} style={{borderRadius:"100%", width:"128px", height:"128px"}}><FontAwesomeIcon className="fa-4x" icon={faAlienMonster} style ={{color:"#000000"}}/></button>
                      </div>
                    </div>
                    <div className = "row my-2 text-white">
                      <div className = "col">
                        Wrong Intent
                      </div>
                    </div>
                  </div>
                  <div className = "col-4 text-center">
                    <div className = "row">
                      <div className = "col">
                        <button className={`btn btn-lg btn-${this.state.speech_to_text === true?"danger":"dark"}`} onClick ={() =>this.onClickError(1)} style={{borderRadius:"100%", width:"128px", height:"128px"}}><FontAwesomeIcon className="fa-4x" icon={faDeaf} style ={{color:"#000000"}}/></button>
                      </div>
                    </div>
                    <div className = "row my-2 text-white">
                      <div className = "col">
                        Speech to text
                      </div>
                    </div>
                  </div>
                  <div className = "col-4 text-center">
                    <div className = "row">
                      <div className = "col">
                        <button className={`btn btn-lg btn-${this.state.telephony_issue === true?"danger":"dark"}`} onClick ={() =>this.onClickError(2)} style={{borderRadius:"100%", width:"128px", height:"128px"}}><FontAwesomeIcon className="fa-4x" icon={faPhoneSlash} style ={{color:"#000000"}}/></button>
                      </div>
                    </div>
                    <div className = "row my-2 text-white">
                      <div className = "col">
                        Telephony issue
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-3" style={{borderColor:"white"}}>
              <div className ="card-body text-white">
                <div className = "my-3">
                  <a href={logs_fallback} target="_blank" rel="noopener noreferrer">View debug logs</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fixed-bottom">
          <div className="row my-2 justify-content-center">
            <button className="col-2 btn btn-primary align-self-center" onClick={() => this.onComplete()}>Save</button>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state,props) =>
{
  const formKey = uuidv4();
  return (state) =>
  {
    const changes = state.getIn(["form_changes",'observation/' + props.observation.id.toString()])
    const patchData = state.getIn(["form_changes",formKey])
    const form = state.getIn(['forms',formKey])
    return{
      changes: changes,
      patchData: patchData,
      form: form,
      formKey,
      form_errors: state.getIn(['form_errors', formKey]),
    };
  }
}

export default connect(mapStateToProps)(ObservationsBotCallCard);
