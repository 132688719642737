import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';

import Autosuggest from './autosuggest';

import JSONInputField from './json_input_field';
import JSONFormFieldGroup from './json_form_field_group';

class JSONAutosuggestField extends JSONInputField {
  render(){

    const {value,property,className,categories,disabled} = this.props;

    const fieldProps = {
      name: property,
      onChange: this.onChange,
      onBlur: this.onBlur,
      suggestions: categories, 
      value, 
      attribute: property,
      autoComplete: 'new-password',
      disabled,
    };

    return (
      <JSONFormFieldGroup className={className}>
        {this.label()}
        <Autosuggest 
          className={cn("form-control",this.validation())} 
          suggestions={categories}
          {...fieldProps} 
        />
        {this.feedback()}
      </JSONFormFieldGroup>
    );
  }

  onChange = (update) => {
    this.setState({ showValidation: true });
    return this.props.updateForm(update)
  }
}

const mapStateToProps = (state) => ({
  categories: state.get('business_categories'),
});

export default connect(mapStateToProps)(JSONAutosuggestField);