import React from 'react';
import { connect } from 'react-redux';

import Loading from './loading';
import ExplorerOnboarding from './explorer_onboarding';

class OnboardingFormViewsShow extends React.PureComponent {

  render(){
    const { region } = this.props;
    if(region.id){
      return (
        <ExplorerOnboarding region={region}/>
      );
    } else {
      return <Loading />
    } 
  }

}

const mapStateToProps = (state) => ({
  region: state.get('region'),
});

export default connect(mapStateToProps)(OnboardingFormViewsShow);