
export const inlineLocalAddress = (address) => {
  if(address){
    const line1 = address.address || [address.street_number,address.street_name].filter(x => x).join(" ");
    return [line1,address.address2].filter(x => x).join(", ");
  }
}

export const regionAddress = (address) => {
  return [address.neighborhood,address.city,address.state].filter(x => x).join(", ");
}

export const inlineAddress = (address) => {
  if(address){
    return `${inlineLocalAddress(address)}, ${address.city}, ${address.state} ${address.postal_code}`;
  }
}
