import React from 'react';
import { connect } from 'react-redux';

import ExplorersActions from '../actions/explorers_actions';

import IndexView from './index_view';
import ExplorersRow from './explorers_row';

const columns = [
  { col: "id", label: "Id"},
  { col: "region_id", label: "Region" },
  { col: "email", label: "Email"},
  { col: "referral_code", label: "Ref"},
  { col: "first_name", label: "First"},
  { col: "last_name", label: "Last"},
  { col: "city", label: "City"},
  { col: "state", label: "State"},
  { col: "country", label: "Country"},
  { col: "created_at", label: "Signed"},
  { col: "training_completed_at", label: "Training"},
  { col: "quiz_completed_at", label: "Quiz"},
  { col: "visits_count", label: "Places"},
];

class ExplorersIndex extends IndexView {
  
  constructor(props){
    super(props);
    this.state = {};
  }

  render(){

    const { total,page_size } = this.state;
    const { page_number } = this.parsedQuery();

    const rows = this.props.explorers.map(explorer => {
      return <ExplorersRow key={explorer.id} explorer={explorer} />
    })

    return (
      <div id="explorersIndex">
        <div className="row mb-2 justify-content-end">
          <div className="col-md-4">
            {this.searchBar()}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <table className="table table-striped table-bordered table-sm table-hover">
              <thead>
                <tr>
                  {this.generateHeaders(columns)}
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            {this.pagination({
              page_number: (page_number || 1),
              total,
              page_size,
            })}
          </div>
        </div>
      </div>
    )
  }

  fetchData = (query) => {
    return ExplorersActions.index(query).then(response => {
      this.setState({total: response.meta.total, page_size: response.meta.page_size})
    });
  }
}

const mapStateToProps = state => {
  return {
    explorers: state.get('explorers').toIndexedSeq(),
    total: state.getIn(['page','totals','explorers']),
  }
}

export default connect(mapStateToProps)(ExplorersIndex);