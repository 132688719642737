import * as Sentry from '@sentry/browser';

export const setToken = (t) => window.localStorage.setItem('dptok',t);

export const getToken = () => window.localStorage.getItem('dptok');

export const getStoredExplorer = () => JSON.parse(window.localStorage.getItem('dpexplorer') || '{}');
export const getStoredValidator = () => JSON.parse(window.localStorage.getItem('dpvalidator') || '{}');
export const getStoredAdmin = () => JSON.parse(window.localStorage.getItem('dpadmin') || '{}');

export const clearToken = () => {
  window.localStorage.clear();
}

export const setAuth = (type,user) => {
  if(type === 'explorer'){
    Sentry.configureScope((scope) => scope.setUser({id: user.id, email: user.email}) );
  }
  window.localStorage.setItem(`dp${type}`,JSON.stringify(user));
  setToken(`Token token="${user.token}", email="${user.email}"`);
};

export const isLoggedIn = () => !!getToken();

