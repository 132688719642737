import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import PasswordResetActions from '../actions/password_reset_actions';

import Explorer from '../../shared/models/explorer';
import T from '../../shared/components/t';

class PasswordResetsEdit extends React.PureComponent {

  constructor(props){
    super(props);
    this.state = { 
      blurred: false,
      password: '',
      errored: false
    };
  }

  render(){
    const error = Explorer.validate('password',this.state.password);
    const valid = !error;

    let feedback;
    if(this.state.errored){
      feedback = <div className="invalid-feedback d-block"><T k="password_resets.edit.invalid_token" /></div>
    } else if(error && this.state.blurred){
      feedback = <div className="invalid-feedback d-block"><T k={error} /></div>
    } else {
      feedback = <small className="form-text text-muted"><T k="registrations.form.password_help" /></small> ;
    }

    const validation = { 
      "is-valid": (this.state.blurred && valid),
      "is-invalid": (this.state.blurred && !valid),
    };

    let submit;
    if(this.state.errored){
      submit = (
        <Link to="/request_password">
          <button className="btn btn-primary col">
            <T k="password_resets.edit.to_request" />
          </button>
        </Link>
      );
    } else {
      submit = (
        <button className="btn btn-primary col" disabled={!valid}>
          <T k="password_resets.edit.submit_button" />
        </button>
      );
    }

    return (
      <div className="row justify-content-center" >
        <div className="col-sm-6 col">
          <div className="card mt-sm-5">
            <div className="card-body">
              <h5 className="card-title"><T k="password_resets.edit.header" /></h5>
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <input className={cn('form-control',validation)} name="password_reset[password]" type="password" onChange={this.updatePassword} value={this.state.password} id="loginEmail" onBlur={this.onBlur}/>
                  {feedback}
                </div>
                {submit}
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

  updatePassword = e => this.setState({password: e.target.value});
  
  onSubmit = e => {
    e.preventDefault();
    PasswordResetActions.update(this.props.match.params.token,this.state.password).then(() => {
      this.props.history.push('/login');
    }).catch(response => {
      response.json().then(json => {
        this.setState({errored: true});
      });
    });
  }

  onBlur = e => {
    this.setState({ blurred: true });
  }
}

export default PasswordResetsEdit;