import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { Link } from 'react-router-dom';

import SessionsActions from '../actions/sessions_actions';

import T from '../../shared/components/t';

import JForm from '../../shared/components/j_form';

const formKey = "accounts/new";

const schema = Immutable.fromJS({
  required: [
    "email",
    "password"
  ],
  properties: {
    "email": { "type": "string", "format": "email"},
    "password": { "type": "string", "minLength": 8}
  }
});

const elements = Immutable.fromJS([
  { "type": "email_field", "property": "email" },
  { "type": "password_field", "property": "password" },
]);

const data_key = "sessions/new";


class Login extends React.PureComponent {

  constructor(props){
    super(props);
    this.state = {
      submitting: false
    }
  }

  render(){
    return (
      <div>
        <div className="row mb-4 justify-content-center" >
          <div className="col col-sm-6">
            <h4><T k="sessions.login.header" /></h4>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col col-sm-6">
            <JForm
              data_key={data_key}
              i18n_prefix="sessions.login"
              i18n_error_prefix="explorers.errors"
              schema={schema}
              elements={elements}
              on_submit={this.onSubmit}
              on_complete={this.onComplete}
              mode="create"
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col text-center">
            <Link to="/register">
              <T k="sessions.login.register_button" />
            </Link>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-center">      
            <Link to="/request_password">
              <T k="sessions.login.forgot_password" />
            </Link>
          </div>
        </div>
      </div>
    )
  }

  onSubmit = (formData) => {
    return SessionsActions.create(formData);
  }

  onComplete = () => {
    this.props.clearForm();
    this.props.history.push('/');
  }

  updateEmail = e => this.setState({email: e.target.value});
  updatePassword = e => this.setState({password: e.target.value});

}

const mapStateToProps = (state) => ({
  form: state.getIn(['forms',formKey]),
});

const mapDispatchToProps = {
  clearForm: () => ({ type: 'forms.load', data: {[data_key]: {}} }),
};

export default connect(mapStateToProps,mapDispatchToProps)(Login);
