import React from 'react';
import { connect } from 'react-redux';

class ObservationsBotCallStatusDataRow extends React.Component {
  constructor(props)
  {
    super(props);
    // const observation = props.observation

    // let connected = observation.getIn(['response','connected'])
    // let answered  = observation.getIn(['response','answered'])
    // let voicemail = observation.getIn(['response','voicemail'])

    this.state = {

    };

  }

  componentDidMount()
  {
    // const observation = this.props.observation
    // const contexts = observation.getIn(["response", "contexts"])

  }

  onChange = (event) =>
  {
    const observation = this.props.observation
    this.setState({status:event.target.value})
    this.props.dispatch({
      type:"forms.merge", data:{
        [`observation/${observation.id}`]: {
          "status_call": event.target.value,
        }
      }
    });
  }


  render() {

      return (
      <div className= "row justify-content-between p-2">
      <div className="col">Status Call</div>
      <form className="col-7 form mt-2">
        <select className="custom-select mb-3 mr-sm-2" name="bot_env" value= {this.state.status} onChange={this.onChange}>
          <option disabled selected value ={"Select an option"}></option>
          <option value= {"answered"}>{"answered"}</option>
          <option value= {"voicemail"}>{"voicemail"}</option>
          <option value= {"unanswered"}>{"unanswered"}</option>
          <option value ={"wrong_phone"}>{"wrong phone"}</option>
        </select>
      </form>

      </div>
      )
  }
}

const mapStateToProps = (state,props) =>
{
  const form = state.getIn(['forms',`observation/${props.observation.id}`]);
  return{
    form: form
  };
}

export default connect(mapStateToProps)(ObservationsBotCallStatusDataRow);
