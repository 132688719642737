
import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';

import Store from '../reducers/store';

export default {
  show: function(deliveryId,params){
    return authorizedFetch(`/v3/admin/deliveries/${deliveryId}/analysis`,params).then(data => {
      console.log(data)
      Store.dispatch({
        type: 'deliveries.merge',
        data: [{id: deliveryId, attributes: {analysis: data}}],
      })
    }).catch(e => {
      console.log(e)
    });
  },
  create: function(deliveryId, params, actionType = "merge"){
    const opts = { method: 'POST'};
    return authorizedFetch(`/v3/admin/deliveries/${deliveryId}/analysis`,params,opts).then(handleResponse("deliveries", actionType));;
  }
};
