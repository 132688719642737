import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';

import T from '../../shared/components/t';

class PasswordResetButton extends React.PureComponent {
  render(){
    let content;
    if(this.props.sent && this.props.requested){
      content = <T k="accounts.edit.reset_password_success" />
    } else if(this.props.requested){
      content = <FontAwesomeIcon icon={faSpinner} spin />
    } else {
      content = <T k="accounts.edit.reset_password_button" />
    }

    return (
      <button className={cn("btn",this.props.className)} onClick={this.onClick}>
        {content}
      </button>
    );
  }

  onClick = e => {
    this.props.onClick(e);
  }

}

export default PasswordResetButton;