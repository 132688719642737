import React, { useCallback } from 'react';
import Immutable from 'immutable';
import { useHistory } from "react-router-dom";

import JFormView from './j_form_view';

const JFormViewSequence = (props) => {
  const { sequence,path,on_complete } = props;
  const history = useHistory();

  const view_name = props.view_name || sequence.get('start');
  const view = sequence.getIn(['views',view_name]);
  const schema_name = view.get('schema');
  const schema = sequence.getIn(['schemas',schema_name]);



  const navigate_to = useCallback((new_view) => {
    const newPath = path.replace(':view_name',new_view);
    history.push(newPath);
  },[history,path]);

  const on_finish = useCallback(() => {
    on_complete()
  },[on_complete]);

  return (
    <JFormView
      view_name={view_name}
      schema={schema}
      view={view}
      data_key={sequence.get('data_key')}
      i18n_prefix={sequence.get('i18n_prefix')}
      i18n_error_prefix={sequence.get('i18n_error_prefix')}
      navigate_to={navigate_to}
      on_finish={on_finish}
    />
  );
};

JFormViewSequence.defaultProps = {
  view_name: '',
  sequence: Immutable.fromJS({}),
  on_complete: () => {}

}

export default React.memo(JFormViewSequence);