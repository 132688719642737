import React from 'react';
import { Link } from 'react-router-dom';

import MergeEventsActions from '../actions/merge_events_actions';

import TruncatedTooltip from './truncated_tooltip';
import AnalysisDuplicatePlaceCell from './analysis_duplicate_place_cell';


class AnalysisDuplicatePlace extends React.PureComponent {
  constructor(){
    super();
  }


  render(){
    const {place, onExclude, className, hovered, reportHovered, explain} = this.props;

    const badge = <Link to={`/places/${place.id}/edit`} className="badge badge-places">{place.id}</Link>

    const commonCell = { currentlySelected: hovered, reportHovered, explain}

    return (
      <div className={className}>
        <div className="row p-1">
          <div className="col-1">{badge}</div>

          <AnalysisDuplicatePlaceCell className="col-6" attr="name" {...commonCell}>
            <TruncatedTooltip>{place.name}</TruncatedTooltip>
          </AnalysisDuplicatePlaceCell>

          <AnalysisDuplicatePlaceCell className="col-2" attr="phone" {...commonCell}>
            <TruncatedTooltip>{place.phone}</TruncatedTooltip>
          </AnalysisDuplicatePlaceCell>

          <AnalysisDuplicatePlaceCell className="col-2" attr="email" {...commonCell}>
            <TruncatedTooltip>{place.email}</TruncatedTooltip>
          </AnalysisDuplicatePlaceCell>

          <div className="col-1"><button className="btn btn-danger btn-xs px-1 col" >⏏️</button></div>
        </div>

        <div className="row p-1">
          <div className="col-1"><TruncatedTooltip><span className="badge badge-secondary ">{place.business_category_id}</span></TruncatedTooltip></div>
          <AnalysisDuplicatePlaceCell className="col-4" attr="address" {...commonCell}>
            <TruncatedTooltip>{place.address}</TruncatedTooltip>
          </AnalysisDuplicatePlaceCell>

          <AnalysisDuplicatePlaceCell className="col-2" attr="neighborhood" {...commonCell}>
            <TruncatedTooltip>{place.neighborhood}</TruncatedTooltip>
          </AnalysisDuplicatePlaceCell>

          <AnalysisDuplicatePlaceCell className="col-2" attr="city" {...commonCell}>
            <TruncatedTooltip>{place.city}</TruncatedTooltip>
          </AnalysisDuplicatePlaceCell>

          <AnalysisDuplicatePlaceCell className="col-2" attr="state" {...commonCell}>
            <TruncatedTooltip>{place.state}</TruncatedTooltip>
          </AnalysisDuplicatePlaceCell>

          <div className="col-1"><button className="btn btn-danger btn-xs px-1 col" >💣🌐</button></div>
        </div>
      </div>
    );
  }
}


export default AnalysisDuplicatePlace;
