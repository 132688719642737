import React from 'react';
import { connect } from 'react-redux';
import format from 'date-fns/format'

import { inlineAddress } from '../../shared/helpers/address_helpers';

import { statusClassColor } from '../helpers/place';

import PlaceStatusBadge from './place_status_badge';
import VisitsListItemPayablesList from './visits_list_item_payables_list';

class VisitsListItem extends React.PureComponent {

  render(){
    const { id,created_at,place_data } = this.props.visit;
    const validated = this.props.validation ? this.props.validation.validated : null;
    
    let rejectionReason;
    if(validated === false){
      rejectionReason = (
        <div className="card-footer text-muted py-2">
          <small className="text-danger">{this.props.validation.notes}</small>
        </div>
        
      )
    }

    return (
      <div className={`visits-list-item card mb-3 border-${statusClassColor(validated)}`} data-id={id}>
        <div className="card-body py-2">
          <div className="row d-flex justify-content-between">
            <div className="col-8">
              <span className="text-muted" style={{fontSize: '0.8rem'}}>{format(created_at,'DD/MM/YYYY')}</span>
            </div>
            <div className="col-4 text-right">
              <PlaceStatusBadge validated={validated}/>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <h4 className="visits-list-item-name card-title mb-1">{place_data.get('name')}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="card-text">{inlineAddress(place_data.toJSON())}</p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <VisitsListItemPayablesList pricing_items={this.props.pricing_items} payables={this.props.payables} />
            </div>
          </div>
        </div>
        {rejectionReason}
      </div>
    );
  }
}

const mapStateToProps = (state,initialProps) => {
  const { visit } = initialProps;
  return (state) => {
    return {
      validation: state.get('place_validations').filter(pv => pv.visit_id === visit.id ).maxBy(pv => pv.id),
      pricing_items: state.get('pricing_items').filter(pi => pi.pricing_structure_id === visit.pricing_structure_id),
      payables: state.get('payables').filter(p => p.visit_id === visit.id),
    }
  }
}

export default connect(mapStateToProps)(VisitsListItem);