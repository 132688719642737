import React from 'react';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import AccountActions from '../actions/account_actions';

import T from '../../shared/components/t';

import { timezoneParams } from '../helpers/timezone_helpers';
import { geocodeIP } from '../../shared/helpers/ip_helpers';
import { regionIds } from '../../shared/helpers/region_helpers';

import JForm from '../../shared/components/j_form';

const schema = Immutable.fromJS({
  required: [
    "email",
    "password",
    "region_id"
  ],
  properties: {
    "email": { type: "string", "format": "email" },
    "password": { type: "string", minLength: 8 },
    "region_id": { type: "string", enum: regionIds }
  }
});

const elements = Immutable.fromJS([
  { "type": "email_field", "property": "email", "required": true },
  { "type": "password_field", "property": "password", "required": true},
  { "type": "select_field", "property": "region_id", "i18n_prefix": "regions", "required": true },
]);


class RegistrationsNew extends React.PureComponent {

  componentDidMount(){
    geocodeIP().then(response => {
      if(response && response.country){
        const country = response.country.toLowerCase();
        if( regionIds.includes(country) ){
          this.props.updateForm({region_id: country});
        }
      }
    });
  }

  render(){
    return (
      <div>
        <div className="row mb-4 justify-content-center">
          <div className="col col-sm-6">
            <h4><T k="registrations.new.header" /></h4>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col col-sm-6">
            <JForm 
              data_key="registrations/new" 
              i18n_prefix="explorers.form" 
              elements={elements} 
              schema={schema}
              on_submit={this.onSubmit}
              on_complete={this.onComplete}
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col text-center">
            <Link to="/login">
              <T k="registrations.new.link_to_login"/>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  onSubmit = (formData) => {
    const { referral_code, locale } = this.props;
    const params = Object.assign({referral_code, locale},formData,timezoneParams());
    return AccountActions.create(params);
  }

  onComplete = () => {
    this.props.clearForm();
    this.props.history.push('/start');
  }

}

const mapStateToProps = state => ({ 
  referral_code: state.getIn(['page','referral_code']),
  locale: state.getIn(['explorer','locale']),
});

const mapDispatchToProps = {
  updateForm: (data) => ({type: 'forms.merge', data: {'registrations/new': data}}),
  clearForm: () => ({ type: 'forms.load', data: {'registrations/new': {}} }),
};

export default connect(mapStateToProps,mapDispatchToProps)(RegistrationsNew);
