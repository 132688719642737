import React,{ useContext } from 'react';

import JFormTextField from './j_form_text_field';
import JFormEmailField from './j_form_email_field';
import JFormPasswordField from './j_form_password_field';
import JFormDateField from './j_form_date_field';
import JFormSelectField from './j_form_select_field';
import JFormTextareaField from './j_form_textarea_field';
import JFormCheckboxField from './j_form_checkbox_field';
import JFormCheckboxTimestampField from './j_form_checkbox_timestamp_field';
import JFormRadioField from './j_form_radio_field';
import JFormRadioTimestampField from './j_form_radio_timestamp_field';

import JFormPhoneWidget from './j_form_phone_widget';
import JFormDOBWidget from './j_form_dob_widget';
import JFormRadioContentButtonSelectWidget from './j_form_radio_content_button_select_widget';
import JFormContractWidget from './j_form_contract_widget';

import JFormHeader from './j_form_header';
import JFormCopy from './j_form_copy';

import JFormContext from '../components/j_form_context';

const selectElement = (element) => {
  switch(element.get('type')){
    // form elements
    case 'text_field':
      return <JFormTextField element={element} />;
    case 'email_field':
      return <JFormEmailField element={element} />;
    case 'password_field':
      return <JFormPasswordField element={element} />;
    case 'date_field':
      return <JFormDateField element={element} />;
    case 'select_field':
      return <JFormSelectField element={element} />;
    case 'textarea_field':
      return <JFormTextareaField element={element} />
    case 'checkbox_field':
      return <JFormCheckboxField element={element} />;
    case 'checkbox_timestamp_field':
      return <JFormCheckboxTimestampField element={element} />;
    case 'radio_field':
      return <JFormRadioField element={element} />;
    case 'radio_timestamp_field':
      return <JFormRadioTimestampField element={element} />;


    // widgets

    case 'phone_widget':
      return <JFormPhoneWidget element={element} />
    case 'dob_widget':
      return <JFormDOBWidget element={element} />
    case 'radio_content_button_select_widget':
      return <JFormRadioContentButtonSelectWidget element={element} />
    case 'contract_widget':
      return <JFormContractWidget element={element} />

    // content elements

    case 'header':
      return <JFormHeader element={element} />
    case 'copy':
      return <JFormCopy element={element} />

    default:
      return <div className="col border border-danger mb-2"><code>Need to implement: {element.get('type')} {JSON.stringify(element.toJSON())} </code></div>;
  };
}

const JFormElement = (props) => {
  const { element } = props;
  const { should_display } = useContext(JFormContext)

  if( !should_display || should_display(element.get('display_if')) ){
    return (
      <div className="row">
        <div className="col">
          {selectElement(element)}
        </div>
      </div>
    );
  } else {
    return null;
  }
  
};

export default React.memo(JFormElement);