import Store from '../reducers/store';

import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  index: function(params){
    return authorizedFetch('/v1/admin/explorers_quotas').then(json => {
      Store.dispatch({type: 'explorers_quotas.load', ...json})
    })
  },
  // create: function(params){
  //   const opts = {
  //     method: 'POST', 
  //     body: JSON.stringify(params)
  //   };
  //   return authorizedFetch(`explorers_payments`,null,opts).then(json => {
  //     // Store.dispatch({type: 'explorers_payments.merge', ...json})
  //   });
  // },
};