import React from 'react';
import { connect } from 'react-redux';
import uniq from 'lodash/uniq';

class VisitWarnings extends React.PureComponent {

  render(){
    const { visit,images,duplicate_ips,visits_in_last_hour,visits_nearby } = this.props;

    const warnings = [];

    if(visit.client === 'web'){ warnings.push({ k: ``, s: 'warning', c: <span><b>Web Records</b> are more likely to be fraudulent</span>}) }

    if(duplicate_ips > 7){
      warnings.push({ k: `error.duplicate_ips`, s: 'danger', c: <span>This user has a <b>suspicious number of confirmations ({duplicate_ips})</b> from the same IP</span>})
    } else if (duplicate_ips > 4){
      warnings.push({ k: `warning.duplicate_ips`, s: 'warning', c: <span>This user has a <b>high number of confirmations ({duplicate_ips})</b> from the same IP</span>})
    }

    if(visits_in_last_hour > 15){
      warnings.push({ k: `error.visits_in_last_hour`, s: 'danger', c: <span>This user has submitted a <b>suspicious number of records ({visits_in_last_hour})</b> in the last hour</span>})
    } else if(visits_in_last_hour > 10) {
      warnings.push({ k: `warning.visits_in_last_hour`, s: 'warning', c: <span>This user has submitted a <b>high number of records ({visits_in_last_hour})</b> in the last hour</span>})
    }

    if(visits_nearby > 9){
      warnings.push({ k: `error.visits_nearby`, s: 'danger', c: <span>This user has submitted <b> {visits_nearby} records within 50m of this spot in the last month</b></span>})
    } else if(visits_nearby > 4){
      warnings.push({ k: `warning.visits_nearby`, s: 'warning', c: <span>This user has submitted <b> {visits_nearby} records within 50m of this spot in the last month</b></span>})
    }

 
    const megapixels = [];
    images.forEach(i => {
      const mp = i.width * i.height;
      megapixels.push(mp)
      if(i.format === 'png'){ warnings.push({ k: `error.format.${i.role}`, s: 'danger', c: <span>{i.role} photo appears to be a <b>screenshot</b></span>}) }
      if(!i.latitude){ warnings.push({ k: `error.gps.${i.role}`, s: 'danger', c: <span>{i.role} photo has <b>No GPS</b></span>}) }
      if(!i.make && !i.model){ warnings.push({ k: `error.make_model.${i.role}`, s: 'warning', c: <span>{i.role} photo has <b>No Make/Model</b></span>}) }
      if(mp < 3000000){ warnings.push({ k: `error.size.${i.role}`, s: 'danger', c: <span>{i.role} photo is <b>lower resolution</b> than a typical cameraphone</span>}) }
    });

    if(uniq(megapixels).length > 1){
      warnings.push({ k: ``, s: 'danger', c: <span>photos are <b>different sizes</b>!!!</span>})
    }

    const alerts = warnings.map(w => <div key={w.k} className={`alert alert-${w.s}`} role="alert">{w.c}</div> );

    return (
      <div className={warnings.length > 0 ? 'mb-4' : null}>
        {alerts}
      </div> 
    );
  }
}

const mapStateToProps = (state) => {
  return {
    duplicate_ips: state.getIn(['page','duplicate_ips']),
    visits_in_last_hour: state.getIn(['page','visits_in_last_hour']),
    visits_nearby: state.getIn(['page','visits_nearby']),
  };
};


export default connect(mapStateToProps)(VisitWarnings);