import React from 'react';
import { Link } from 'react-router-dom';
import LiveDate from './live_date';

class TasksRow extends React.PureComponent
{

  render()
  {
    const task = this.props.task;

    return (
      <tr>
        <td><Link to={`/tasks/${task.id}/edit`} className="badge badge-tasks">{task.id}</Link></td>
        <td>{this.props.task.type}</td>
        <td>{this.props.task.priority}</td>
        <td><LiveDate date={this.props.task.assigned_at} /></td>
        <td><LiveDate date={this.props.task.created_at} /></td>
      </tr>
    )
  }
}

export default TasksRow;
