import React from 'react';
import { Link } from 'react-router-dom';
import { stringToBool } from '../helpers/observation_helpers'
import LiveDate from '../../shared/components/live_date';
import SkinnyAudioPlayer from '../../shared/components/skinny_audio_player';
import ObservationsBotCallBotResults from './observations_bot_call_bot_result'


class ObservationsBotCallsRow extends React.PureComponent {

  render()
  {
    const { observation } = this.props;
    const contexts = observation.getIn(["response", "contexts"])

    let name_verified
    let address_verified
    if(contexts)
    {
      contexts.forEach(object => {
        if(object.get("attribute") === "name")
        {
          if(object.get("verified"))
            name_verified = object.get("value")
        }
        if(object.get("attribute") === "address")
        {
          if(object.get("verified"))
            address_verified = object.get("value")
        }
      })
    }

    let botResults = []

    botResults.push( <ObservationsBotCallBotResults key="connected" tiny={true} attribute="connected" confirmed={stringToBool(observation.getIn(['response','connected']))}/> )
    botResults.push( <ObservationsBotCallBotResults key="answered" tiny={true} attribute="answered" confirmed={stringToBool(observation.getIn(['response','answered']))}/> )
    if(stringToBool(observation.getIn(['response','voicemail'])))
    {
      botResults.push( <ObservationsBotCallBotResults key="voicemail" tiny={true} attribute="voicemail" confirmed={stringToBool(observation.getIn(['response','voicemail']))}/> )
    }

    let respContexts = observation.getIn(['response','contexts'])
    if(respContexts)
    {
      observation.getIn(['response','contexts']).forEach(context => {
      const attr = context.get('attribute')
      const confirmed = context.get("verified")
      if(attr !== "voicemail")
        botResults.push(<ObservationsBotCallBotResults key={attr} attribute={attr} tiny={true} confirmed={confirmed}/>);
      })
    }
    const statusCall = observation.getIn(["response", "call_status"])


    return (
      <tr>
        <td><Link to={`/observations/${observation.id}/review`} className="badge badge-observations">{observation.id}</Link></td>
        <td className="text-center"><SkinnyAudioPlayer src={observation.response.get("audio_url")} /></td>
        <td>{observation.name}</td>
        <td><div className = "row">{botResults} </div></td>
        <td>{statusCall}</td>
        <td>{name_verified}</td>
        <td>{address_verified}</td>
        <td><LiveDate date={observation.responded_at} /></td>
      </tr>
    )
  }
}

ObservationsBotCallsRow.columns = [
  { col: "id", label: "Id" },
  { label:"Audio"},
  { label: "Name" },
  { label: "Status"},
  { label: "Status call"},
  { label: "Verified name" },
  { label: "Verified address" },
  { col: "responded_at", label: "Responded at"}
];

export default ObservationsBotCallsRow;
