import Immutable from 'immutable';

export const raw = {
  "properties": {
    "permanently_closed": {"type": "boolean"},
    "temporarily_closed": {"type": "boolean"},
    "name": { "type": "string", "minLength": 1 },
    "business_category": { "type": "string"},
    "street_number": { "type": "string", "minLength": 1 },
    "street_name": { "type": "string", "minLength": 1 },
    "address": { "type": "string", "minLength": 1 },
    "address2": { "type": "string", "minLength": 1 },
    "neighborhood": { "type": "string", "minLength": 1 },
    "city": { "type": "string", "minLength": 1 },
    "state": { "type": "string", "minLength": 1 },
    "postal_code": { "type": "string", "minLength": 1 },
    "country_code": { "type": "string", "minLength": 2, "maxLength": 2, "enum": ["vn","bs","cl","pe","th","in","br","aw","mx"] },
    "phone": { "type": "string", "pattern": "\\+\\d+"},
    "mobile": { "type": "string", "pattern": "\\+\\d+"},
    "email": { "type": "string", "format": "email" },
    "credit_card": { "type": "boolean" },
    "website": {"type": "string", "format": "uri" },
    "facebook_url": {"type": "string", "format": "uri"},
    "instagram_url": {"type": "string", "format": "uri"},
    "tripadvisor_url": {"type": "string", "format": "uri"},
    "google_maps_url": {"type": "string", "format": "uri", "pattern": ".+google.com\/maps\/place\/.+"}
  },
  "allOf": [
    { "oneOf":
      [
        { "not": { "properties": { "country_code": { "enum": ["mx"] } } } },
        {
          "properties": {
            "country_code": { "enum": ["mx"] },
            "phone": { "pattern": "\\+52\\d{10}", "minLength": 13, "maxLength": 13 },
            "mobile": { "pattern": "\\+52\\d{10}", "minLength": 13, "maxLength": 13 },
            "postal_code": { "pattern": "\\d{5}", "minLength": 5, "maxLength": 5 }
          }
        }
      ]
    }
  ]
}
export default Immutable.fromJS(raw)
