import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

import T from '../../shared/components/t';


class JSONFormSubmitButton extends React.PureComponent {

  render(){
    const { submitting,i18nKey,mode,disabled } = this.props;
    let buttonText;

    if(submitting){
      buttonText = <FontAwesomeIcon icon={faSpinner} spin className="mx-auto d-block" size="lg"/>
    } else {
      if(mode === 'update'){
        buttonText = <T k={[`${i18nKey}.submit_update`,"forms.general.update"]} />
      } else {
        buttonText = <T k={[`${i18nKey}.submit_create`,"forms.general.create"]} />
      }
    }

    return (
      <div>
        <button className="btn btn-primary col shadow json-form-submit-button" type="submit" onClick={this.onClick} disabled={disabled || submitting}>
          {buttonText}
        </button>
      </div> 
    );
  }

  onClick = (e) => {
    e.preventDefault();
    this.props.onClick(e);
  }
}

export default JSONFormSubmitButton;