import React from 'react';


import DataplorMap from '../../shared/components/dataplor_map';
import { Marker } from 'react-leaflet'


import { calculateBounds } from '../../shared/helpers/google_maps_helpers';

class GooglePlacesMatchMap extends React.PureComponent {

  render(){
    const{ place,observation } = this.props;

    const candidateMarkers = observation.getIn(['response','matches']).toIndexedSeq().map(candidate => {
      return <Marker key={candidate.get('reference')} position={{lat: candidate.latitude, lon: candidate.longitude}} />;
    })

    return (
      <DataplorMap center={[place.latitude,place.longitude]} zoom={14} style={{height: '400px', "zIndex":"1"}} >
        {candidateMarkers}
        <Marker position={{lat: place.latitude, lon: place.longitude }}/>
      </DataplorMap>
    );
  }

  onLoad = (map) => {
    const { place,observation } = this.props;

    const bounds = calculateBounds([
      {lat: place.latitude, lng: place.longitude},
      ...observation.getIn(['response','matches']).toIndexedSeq().map(c => c.getIn(['json','geometry','location'])).toJS()
    ],0.5);

    map.fitBounds(bounds)
  }
}

export default GooglePlacesMatchMap;
