import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { v4 as uuidv4 } from 'uuid';
import { patchFormToPatch, dataErrorToText, dataPatchFormToPatch } from '../helpers/patch_form_helpers';
import {validateFormNoI18N} from '../../shared/helpers/schema_helpers'
import dataSchema from '../../shared/schemas/data_schema'
import patchSchema from '../../shared/schemas/patch_schema';
import isNil from 'lodash/isNil';
import PatchFormRow from './patch_form_row';
import PatchFormAddButton from './patch_form_add_button';
import PatchFormClosedRow from './patch_form_closed_row';

class PatchForm extends React.PureComponent {

  componentDidMount()
  {
    this.props.initForm(this.props.formKey)
    const t = Date.now();
    const fields = this.props.pin.reduce((agg,attr,i) => {
      const id = uuidv4();
      agg[id] = {
        id: id,
        pinned: true,
        order: (t+i+1),
        old_value: this.props.data.get(attr),
        attribute: attr,
      }
      return agg
    },{})

    const businessStatusId = uuidv4()
    fields[businessStatusId] =
    {
      id: businessStatusId,
      pinned:true,
      order:0,
      old_value:this.props.data.get("permanently_closed") === true? "pclosed": (this.props.data.get("temporarily_closed") === true)? "tclosed" : "open",
      attribute: "business_status"
    }

    this.props.seedFields(this.props.formKey, fields)
  }

  updatePatchErrors(patch, errors)
  {
    let errs = {}
    for(let i = 0;i< errors.length;++i)
    {
      let error = errors[i]
      const datapath = error.dataPath.substring(1, error.dataPath.length-1)
      const element = patch[datapath].p.substring(1)
      const form = this.props.form.toJSON()

      Object.keys(form).forEach(property =>
      {
        if(form[property].attribute === element)
        {
          const ajvError = error
          error = dataErrorToText(ajvError)
          errs[property] =  {error:[error]}
          //this.props.updateError(this.props.formKey, property, {error: error})
        }
      })
    }
    return errs
  }

  updateDataErrors(patch, errors)
  {
    let errs = {}
    for(let i = 0;i< errors.length;++i)
    {
      let error = errors[i]
      const datapath = error.dataPath.substring(1, error.dataPath.length)
      const form = this.props.form.toJSON()

      Object.keys(form).forEach(property =>
      {
        if(form[property].attribute === datapath)
        {
          const ajvError = error
          error = dataErrorToText(ajvError)
          errs[property] = {error:[error]}
          //this.props.updateError(this.props.formKey, property, {error: error})
        }
      })
      return errs
    }
  }

  validate = (updatedRow) =>
  {
    //this.props.loadErrors(this.props.formKey, [])
    let errsPatch, errsData
    const rowId = updatedRow.get('id')
    const patch = patchFormToPatch(this.props.form.merge({[rowId]: updatedRow}))
    let  errors = validateFormNoI18N(patchSchema, Immutable.fromJS(patch))
    if(!isNil(errors))
      errsPatch = this.updatePatchErrors(patch, errors)

    const dataPatch = dataPatchFormToPatch(this.props.form.merge({[rowId]: updatedRow}))
    errors = validateFormNoI18N(dataSchema, Immutable.fromJS(dataPatch))
    if(!isNil(errors))
      errsData = this.updateDataErrors(dataPatch, errors)
    const errs = {...errsData, ...errsPatch}

    this.props.loadErrors(this.props.formKey, errs)
  }

  render()
  {
    const { form } = this.props;
    const fieldAtrrs = form.map(r => r.get('attribute')).toList();
    const rows = form.toIndexedSeq().sortBy(row => {
      return row.get('order');
    }).map(row => {
      if(row.get("attribute") === 'business_status')
        return <PatchFormClosedRow key={row.get('id')} row={row} formKey={this.props.formKey} data={this.props.data} fieldAttrs={fieldAtrrs}  validate={this.validate}/>
      else if (row.get('attribute') !== 'permanently_closed' && row.get('attribute') !== 'temporarily_closed')
        return <PatchFormRow key={row.get('id')} row={row} formKey={this.props.formKey} data={this.props.data} fieldAttrs={fieldAtrrs} validate={this.validate}/>
      else return null;
    });
    return (
      <div>
        {rows}
        <PatchFormAddButton formKey={this.props.formKey} />
      </div>
    )
  }
}

const mapStateToProps = (initialState,initialProps) => {
  const formKey = initialProps.formKey;
  return (state) => {
    return ({
      form: state.getIn(['forms',formKey]),
      formKey: formKey
    });
  };
};

const mapDispatchToProps = {
  initForm: (formKey) => ({ type: 'forms.merge', data: { [formKey]: {} } }),
  loadErrors: (formKey, errors) => ({type: 'form_errors.load', data: { [formKey]: errors}}),
  seedFields: (formKey,fields) => ({ type: 'forms.merge', data: { [formKey]: fields}}),
  updateError:(formKey, id, error) => ({type: 'form_errors.merge', data: { [formKey]: { [id]: error } } }),
}

PatchForm.defaultProps = {
  formKey: null,
  form: Immutable.fromJS({}),
  pin: [],
  data: Immutable.fromJS({}),
}


export default connect(mapStateToProps,mapDispatchToProps)(PatchForm);
