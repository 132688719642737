import React from 'react';
import { Link } from 'react-router-dom';

import LiveDate from './live_date';

class LeadsRow extends React.PureComponent {

  render(){
    const { lead } = this.props;

    return (
      <tr>
        <td><Link to={`/leads/${lead.id}/edit`} className="badge badge-leads">{lead.id}</Link></td>
        <td>{lead.number}</td>
        <td>{lead.name}</td>
        <td>{lead.city}</td>
        <td>{lead.country}</td>
        <td><span className="badge badge-projects">{lead.project_id}</span></td>
        <td><LiveDate date={lead.created_at} /></td>
      </tr>
    )
  }
}

export default LeadsRow;
