import React from 'react';
import format from 'date-fns/format';

import LeadActions from '../actions/leads_actions';

class LeadsDisableButton extends React.PureComponent {
  render(){
    if(this.props.lead.disabled_at){
      return (
        <div>
          <button className="btn btn-primary" onClick={this.reenable}>Re-enable Lead</button>
          <span className="ml-2 badge badge-danger">Disabled on {format(this.props.lead.get('disabled_at'),'MMM D YYYY')}</span>
        </div>
      );
    } else {
      return (
        <div>
          <button className="btn btn-danger" onClick={this.disable}>Disable Lead</button>
        </div>
      );
    }
  }

  disable = e => {
    return LeadActions.update(this.props.lead.id,{
      data: {
        type: 'lead', 
        attributes: { disabled_at: (new Date()).toISOString()}
      }
    });
  }

  reenable = e => {
    return LeadActions.update(this.props.lead.id,{
      data: {
        type: 'lead', 
        attributes: { disabled_at: null }
      }
    });
  }
}


export default LeadsDisableButton;