import React from 'react';
import cn from 'classnames';
import InputMask from 'react-input-mask';

import JSONInputField from './json_input_field';

const formatChars = {
  '#': '[0-9]',
  'a': '[A-Za-z]',
  '*': '[A-Za-z0-9]'
};

class JSONMaskedNumberField extends JSONInputField {

  input = () => {
    const {id,value,element,disabled,property} = this.props;
    
    const mask = element.get('mask');

    const v = this.stateToForm(value);
    return (
      <InputMask mask={mask} name={property} onChange={this.onChange} id={id} value={v} onBlur={this.onBlur} formatChars={formatChars} disabled={disabled} type="tel" className={cn('form-control',this.validation())} autoComplete="new-password" />
    );
  }

  onChange = (e) => {
    const {property,updateForm} = this.props;
    const value = this.formToState(e.target.value);
    this.setState({ showValidation: false });
    const v = value === '' ? null : value;
    updateForm({[property]: v});
  }

  formToState = (formValue) => {
    return formValue.replace(/\D/g,'');
  }

  stateToForm = (stateValue) => {
    return stateValue
  }
}

export default JSONMaskedNumberField;