import React from 'react';
import defaultTo from 'lodash/defaultTo';

import { I18nConsumer } from './i18n';
import T from './t';

import JSONOperatingHoursDay from './json_operating_hours_day';


const dayNames = ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'];

class JSONOperatingHoursWidget extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false
    };
  }

  render(){
    const { errors } = this.props;
    
    const displayValidation = defaultTo(this.props.showValidation,this.state.showValidation);

    const hoursErrors = errors.filter((v,k) => v && k.match(/_(open|close)$/) );

    let feedback;

    if(!hoursErrors.isEmpty() && displayValidation){
      const [property,errorKey] = hoursErrors.entrySeq().get(0)
      feedback = (
        <I18nConsumer>
          {
            ({t}) => {
              const propertyText = t(`places.form.${property}`);
              return (
                <div className="invalid-feedback d-block">
                  <T k={errorKey} property={propertyText}/>
                </div>
              );
            }
          }
        </I18nConsumer>
        
      )
    }

    return(
      <div>
        <div className="form-group">
          <label><T k="visits.form.opening_hours" /><span className="text-danger ml-1">*</span></label> 
          <JSONOperatingHoursDay short="m" {...this.propsForDay("monday")} />
          <JSONOperatingHoursDay short="tu" {...this.propsForDay("tuesday")} />
          <JSONOperatingHoursDay short="w" {...this.propsForDay("wednesday")} />
          <JSONOperatingHoursDay short="th" {...this.propsForDay("thursday")} />
          <JSONOperatingHoursDay short="f" {...this.propsForDay("friday")} />
          <JSONOperatingHoursDay short="sa" {...this.propsForDay("saturday")} />
          <JSONOperatingHoursDay short="su" {...this.propsForDay("sunday")} />
        </div>
        {feedback}
      </div>
    );
  }

  propsForDay = (day) => {
    const {form,errors,disabled} = this.props;
    const s = this.state;

    return {
      long: day,
      open: form.get(`${day}_open`),
      close: form.get(`${day}_close`),
      checked: s[day],
      updateWeek: this.updateWeek,
      autoFill: this.autoFill,
      onBlur: this.onBlur,
      openValid: !errors.get(`${day}_open`),
      closeValid: !errors.get(`${day}_close`),
      updateForm: this.updateForm,
      showValidation: defaultTo(this.props.showValidation,this.state.showValidation),
      disabled,
    };
  }

  updateForm = (update) => {
    this.props.updateForm(update);
  }

  updateWeek = (state) => {
    this.setState(state)
  }

  autoFill = (day,open,close) => {
    const update = {};
    dayNames.forEach(day => {
      if(this.state[day]){
        Object.assign(update,{[`${day}_open`]: open, [`${day}_close`]: close,}) 
      }
    })
    this.props.updateForm(update);
  }

  onBlur = () => {
    this.setState({showValidation: true});
    this.props.validateFormProperties(JSONOperatingHoursWidget.fieldNames);
  }
}

JSONOperatingHoursWidget.defaultProps = {
  validator: () => {}
}

JSONOperatingHoursWidget.fieldNames = dayNames.reduce((a,day) => {
  a.push(`${day}_open`); a.push(`${day}_close`); return a;
},[]);

export default JSONOperatingHoursWidget;
