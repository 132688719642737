import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { Switch,Route,Link } from 'react-router-dom';

import ExplorersActions from '../actions/explorers_actions';
import ExplorerNotesCard from './explorer_notes_card';

import ExplorersFormPanel from './explorers_form_panel';
import ExplorersNotesPanel from './explorers_notes_panel';
import ExplorersProjectsPanel from './explorers_projects_panel';
import ExplorersVisitsPanel from './explorers_visits_panel';
import ExplorersActionsPanel from './explorers_actions_panel';
import ExplorersPaymentsPanel from './explorers_payments_panel';
import LiveDate from './live_date';


class ExplorersEdit extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      tab: 'details',
      loaded: false,
    };
  }

  componentDidMount(){
    const explorerId = this.props.match.params.id;
    ExplorersActions.show(explorerId).then(json => {
      this.props.loadForm({[`explorer/${explorerId}`]: json.data.attributes});
      this.setState({loaded: true});
    })
  }

  render(){
    if(this.state.loaded && this.props.explorer){
      const explorer = this.props.explorer;
      const subpath = this.props.location.pathname.match(/explorers\/\d+\/?(\w*)/)[1];

      return (
        <div id="explorersShow" className="mb-5">
          <div className="row mt-3">
            <div className="col-8">
              <h1 className="h2"><span className="badge badge-explorers">{explorer.id}</span> {explorer.first_name} {explorer.last_name}</h1>
              {explorer.ip_address ? <a href={`https://tools.keycdn.com/geo?host=${explorer.ip_address}`} target="_blank" rel="noopener noreferrer"><h4 className="d-inline-block mr-2"><span className="badge badge-secondary">{explorer.ip_address}</span></h4></a> : null}
              {explorer.referral_code ? <Link to={`/explorers?search=${explorer.referral_code}`}><h4 className="d-inline-block"><span className="badge badge-secondary">{explorer.referral_code}</span></h4></Link> : null}
              <p>Added <LiveDate date={explorer.created_at} />, updated <LiveDate date={explorer.updated_at} /></p>

            </div>
            <div className="col-4">
              <ExplorerNotesCard explorer={explorer}/>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'edit')})} to={`/explorers/${explorer.id}/edit`}>Details</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'notes')})} to={`/explorers/${explorer.id}/notes`}>Notes</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'projects')})} to={`/explorers/${explorer.id}/projects`}>Projects</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'visits')})} to={`/explorers/${explorer.id}/visits`}>Visits</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'payments')})} to={`/explorers/${explorer.id}/payments`}>Payments</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'actions')})} to={`/explorers/${explorer.id}/actions`}>Actions</Link>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <Switch>
                    <Route exact path="/explorers/:id/" component={ExplorersFormPanel} />
                    <Route exact path="/explorers/:id/edit" component={ExplorersFormPanel} />
                    <Route exact path="/explorers/:id/notes" component={ExplorersNotesPanel} />
                    <Route exact path="/explorers/:id/projects" component={ExplorersProjectsPanel} />
                    <Route exact path="/explorers/:id/visits" component={ExplorersVisitsPanel} />
                    <Route exact path="/explorers/:id/payments" component={ExplorersPaymentsPanel} />
                    <Route exact path="/explorers/:id/actions" component={ExplorersActionsPanel} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return 'loading...'
    }

  }

  switchTab = (e) => {
    e.preventDefault();
    this.setState({tab: e.target.getAttribute('data-tab')})
  }
}

const mapStateToProps = (state,props) => {
  const id = parseInt(props.match.params.id,10);
  return {
    explorer: state.get('explorers').find(ex => ex.id === id),
  }
};

const mapDispatchToProps = {
  loadForm: (data) => ({ type: 'forms.load', data }),
}

export default connect(mapStateToProps,mapDispatchToProps)(ExplorersEdit);
