import React from 'react';

import JFormLabel from './j_form_label';
import JFormPrepend from './j_form_prepend';
import JFormInput from './j_form_input';
import JFormAppend from './j_form_append';
import JFormFeedback from './j_form_feedback';

import useFieldState from '../hooks/use_field_state';

const JFormBaseField = (props) => {
  const { element, type, on_blur } = props;

  const { id,show_validation,set_field_show_validation } = useFieldState();

  return (
    <div className="form-group mb-4">
      <JFormLabel id={id} element={element} />
      <div className="input-group">
        <JFormPrepend element={element} />
        <JFormInput
          id={id}
          element={element} 
          type={type} 
          show_validation={show_validation}
          set_field_show_validation={set_field_show_validation} 
          on_blur={on_blur}
        />
        <JFormAppend element={element} />
      </div>
      <JFormFeedback element={element} show_validation={show_validation} />
    </div>
  );
};

JFormBaseField.defaultProps = {
  on_blur: () => {},
}

export default React.memo(JFormBaseField);