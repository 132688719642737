import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraAlt,faTrashAlt,faCloudUpload } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';
import defaultTo from 'lodash/defaultTo';

import T from '../../shared/components/t';

import { extension } from '../helpers/file_helpers';

class JSONPhotoUploadButton extends React.PureComponent {

  constructor(props){
    super(props);
    this.input = React.createRef();
    this.state = { };
  }
  render(){
    const { attribute,showValidation,value,required,disabled,editable } = this.props;

    let error = this.state.error || this.props.error;

    const displayValidation = defaultTo(showValidation,this.state.showValidation);
    const valid = !error;

    const cardStyle = { height: "86px" };
    const bodyStyle = {};
    const cardClasses = cn('card',{
      'border-success': (displayValidation && valid),
      'border-danger': (displayValidation && !valid),
      'bg-light': !(value)
    });

    let bar;
    if(this.state.progress){
      bar = (
        <div className="progress position-absolute w-100" style={{ height: '10px', bottom: '0'}}>
          <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: `${this.state.progress}%`}}></div>
        </div>
      );
    }

    let trash;
    if((value || this.state.fileSelected) && !disabled && editable){
      if(value){ bodyStyle['background'] = 'radial-gradient(circle at top right,rgba(0,0,0,0.8),rgba(0,0,0,0) 50%)' }

      trash = (
        <FontAwesomeIcon icon={faTrashAlt} className="d-block position-absolute" size="lg" style={{top: '0.25em', right: '0.25em'}} onClick={this.removePhoto}/>
      );
    }

    let feedback;
    if(error && displayValidation){
      feedback = (
        <div className="invalid-feedback d-block"><T k={error}/></div>
      );
    }


    let card;
    if( value ){
      const imgUrl = this.assetUrl(value);

      Object.assign(cardStyle,{
        backgroundImage: `url("${imgUrl}")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
      });
      card = (
        <div className={cardClasses} style={cardStyle} onLoad={this.onCardLoad}>
          <div className="card-body text-light justify-content-end" style={bodyStyle}>
            { trash }
          </div>
        </div>
      );
    } else if(this.state.fileSelected){
        card = (
          <div className={cardClasses} style={cardStyle}>
            <div className="card-body text-center text-primary px-0 py-3" style={bodyStyle}>
              { trash }
              <FontAwesomeIcon icon={faCloudUpload} className="mx-auto d-block pulsebeat" size="3x" spin={this.state.fileSelected}/>
              { bar }
            </div>
          </div>
        );
    } else {
      card = (
        <div className={cardClasses} onClick={this.selectPhoto} style={cardStyle}>
          <div className="card-body text-center text-primary px-0" style={bodyStyle}>
            <FontAwesomeIcon icon={faCameraAlt} className="mx-auto d-block" size="lg" spin={this.state.fileSelected}/>
            <T k="places.form.add_photo" />
          </div>
        </div>
      );
    }

    const reqSpan = required ? <span className="text-danger ml-1">*</span> : null;

    return (
      <div className="col col-6 col-md-4 photo-upload-button">
        <label><T k={`visits.form.${attribute}`} /> {reqSpan}</label>
        {card}
        {feedback}
        <input type="file" id="customFile" name={attribute} accept="image/*" hidden ref={this.input} onChange={this.fileSelected}/>
      </div>
    );

  }

  selectPhoto = () => {
    this.input.current.click();
  }

  fileSelected = (e) => {
    if(e.target.files && e.target.files[0]){
      this.setState({ fileSelected: true, error: null, showValidation: null, progress: 1 })
      const file = e.target.files[0];
      return this.uploadPhoto(file);
    }
  }

  uploadPhoto = (file) => {
    const ext = extension(file);
    return this.props.onPhotoUploadSelect({ext}).then( json => {
      const { key,upload_url } = json.data.attributes;
      if(upload_url && key){
        return this.uploadToS3(upload_url,file, progress => {
          if(progress && progress.lengthComputable){
            this.setState({ progress: ((progress.loaded * 80) / progress.total) });
          }
        }).then( s3Response => {
          if(!this.state.fileSelected){ Promise.resolve(null); }
          return this.isImageReady(key).then(() => {
            this.updateForm({[this.props.attribute]: key});
            this.setState({ progress: null, showValidation: true, fileSelected: false });
          });
        });
      }else {
        return Promise.resolve()
      }
    });
  }

  uploadToS3 = (url,file,onProgress) => {
    return new Promise((resolve,reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT',url);
      if(onProgress){  xhr.upload.onprogress = onProgress; }
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if(xhr.status === 200){
            resolve(xhr.status);
          } else {
            reject(`S3 Upload Failed: ${xhr.statusText}`);
          }
        }
      }
      xhr.send(file);
    });
  }

  isImageReady = (key) => {
    if(!this.state.fileSelected){
      return Promise.resolve(null);
    } else {
      return fetch(this.assetUrl(key),{ method: 'HEAD' }).then(response => {
        if(response.ok){
          return key;
        } else {
          this.setState((state,props) => ({progress: Math.min(98,state.progress + 2)}));
          return new Promise((resolve,reject) => {
            setTimeout(() => {
              return this.isImageReady(key).then(resolve)
            },1000);
          });
        }
      });
    }
  }

  assetUrl = (key) => {
    const match = key.match(/(.+)\.(\w+)$/);
    const keyBase = match[1];
    return `${process.env.REACT_APP_ASSETS_SERVER}/${keyBase}_800.jpg`;
  }

  removePhoto = (e) => {
    this.setState({ tempfile: null, fileSelected: false, progress: null, error: null, showValidation: null });
    this.input.current.value = "";
    this.updateForm({[this.props.attribute]: null});
  }

  updateForm = (update) => {
    this.props.updateForm(update);
    this.props.validateFormProperties([this.props.attribute]);
  }

}

JSONPhotoUploadButton.defaultProps = {
  editable: true
};


export default JSONPhotoUploadButton;
