import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck,faTimes } from '@fortawesome/free-solid-svg-icons';

import LiveDate from './live_date';

import { isSaleable } from '../../shared/helpers/place_helpers';

class PlacesRow extends React.PureComponent {

  render(){

    let saleable;
    if(isSaleable(this.props.place)){
      saleable = <FontAwesomeIcon icon={faCheck} className="mx-auto d-block" size="lg" color="green"/>
    } else {
      saleable = <FontAwesomeIcon icon={faTimes} className="mx-auto d-block" size="lg" color="red"/>
    }

    return (
      <tr>
        <td><Link to={`/places/${this.props.place.id}/edit`} className="badge badge-places">{this.props.place.id}</Link></td>
        <td>{this.props.place.name}</td>
        <td>{this.props.place.city}</td>
        <td>{this.props.place.state}</td>
        <td>{this.props.place.country}</td>
        <td><LiveDate date={this.props.place.created_at} /></td>
      </tr>
    )
  }
}

PlacesRow.columns = [
 {col: "id", label: "Id"},
 {col: "name", label: "Name"},
 {col: "city", label: "City"},
 {col: "state", label: "State"},
 {col: "country", label: "Country"},
 {col: "created_at", label: "Created"},
];


export default PlacesRow;
