import Store from '../reducers/store';

import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  index: function(params){
    const q = Object.assign({sort: 'payable', sort_dir: 'desc'},params,{page_size: 25});
    return authorizedFetch('/v1/admin/explorers_payments',q).then(json => {
      Store.dispatch({type: 'explorers_payments.load', ...json})
    })
  },
  create: function(params){
    const opts = {
      method: 'POST', 
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v1/admin/explorers_payments`,null,opts).then(json => {
      // Store.dispatch({type: 'explorers_payments.merge', ...json})
    });
  },
  // show: function(id){
  //   return authorizedFetch(`/v1/admin/places/${id}`).then(json => {
  //     Store.dispatch({type: 'places.merge', ...json})
  //   })
  // },
  // update: function(id,params){
  //   const opts = {
  //     method: 'PUT', 
  //     body: JSON.stringify(params)
  //   };
  //   return authorizedFetch(`/v1/admin/places/${id}`,null,opts).then(json => {
  //     Store.dispatch({type: 'places.merge', ...json})
  //   });
  // }
};