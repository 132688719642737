import React from 'react';

import JSONFormElement from './json_form_element';

class JSONFormSection extends React.PureComponent {
  render(){
    const {element,...rest} = this.props;

    const elements = element.get('elements').map(element => {
      return <JSONFormElement key={element.hashCode()} {...{element}} {...rest}/>
    });

    return (
      <div className="card mb-2">
        <div className="card-body">
          {elements}
        </div>
      </div>
    );

  }
}

export default JSONFormSection;