import React,{ useContext } from 'react';
import I18nContext from './i18n';
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt('commonmark');

const T = (props) => {
  const {k,className,inline,style,...params} = props;
  const { t } = useContext(I18nContext);

  const markdown = t(k,params);

  let html;
  if(inline){
    html = md.renderInline(markdown);
  } else {
    html = md.render(markdown);
  }
  
  const elprops = {
    dangerouslySetInnerHTML: {__html: html },
    className: className,
    style,
  };

  if(inline){
    return <span {...elprops} />
  } else {
    return <div {...elprops} />
  }
}

T.defaultProps = {
  inline: true,
}

export default React.memo(T);