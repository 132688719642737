import React from 'react';

import { I18nConsumer } from './i18n';

class SelectOption extends React.PureComponent {
  render(){
    const { textKey,optionValue,disabled } = this.props;

    return (
      <I18nConsumer>
        {
          ({t}) => {
            const text = textKey ? t(textKey) : '';
            return (
              <option key={textKey} value={optionValue||''} disabled={disabled}>
                {text}
              </option>
            );
          }
        }
      </I18nConsumer>
    );
   
  }
}

class JSONOptionsForSelect extends React.PureComponent {
  render(){
    const { defaultNull,nullSelectable,options } = this.props;
    const elements = [];

    if(defaultNull){
      elements.push(<SelectOption key="null" textKey="" optionValue={null} disabled={!nullSelectable}/>)
    }
    for(let key in options){
      const optionValue = options[key];

      elements.push(
        <SelectOption {...{key,optionValue,textKey: key}} />
      )
    }
    return elements;
  }
}

export default JSONOptionsForSelect;