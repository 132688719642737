import React from 'react';

import JSONForm from '../../shared/components/json_form';

class VisitsForm extends React.PureComponent {

  constructor(props){
    super(props);
    this.state = {
      canEdit: false
    };
  }
  
  componentDidMount(){
    this.validationChecker = setInterval(this.checkPendingValidations,4000);
  }

  componentWillUnmount(){
    clearInterval(this.validationChecker)
  }

  render(){
    const {project,formKey,i18nKey} = this.props;
    const { canEdit } = this.state;

    const button = (
      <button className="col btn btn-secondary mb-2" onClick={this.toggleEdit}>{canEdit ? "Lock edits" : "Make edits"}</button>
    )

    return (
      <React.Fragment>
        {button}
        <div className="visits-form mb-2">
          <JSONForm
            i18nKey={i18nKey}
            errori18nKey="visits.errors"
            schema={project.schema}
            form_schema={project.form_schema}
            formKey={formKey}
            sendRemoteValidation={this.sendRemoteValidation}
            onPhotoUploadSelect={this.onPhotoUploadSelect}
            disabled={!this.state.canEdit}
            showSubmitButton={false}
            showValidation={true}
            clearable={true}
          />
        </div>
        {button}
      </React.Fragment>
    );
  }

  onPhotoUploadSelect = (params) => {
    return Promise.resolve();
  }

  toggleEdit = () => {
    this.setState(prevState => ({canEdit: !prevState.canEdit}))
  }

}


export default VisitsForm;

