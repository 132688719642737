import React from 'react';
import { connect } from 'react-redux';

import ProjectsActions from '../actions/projects_actions';

import IndexView from './index_view';
import ProjectsRow from './projects_row';

class ProjectsIndex extends IndexView {
  
  constructor(props){
    super(props);
    this.state = {};
  }

  render(){

    const { total,page_size } = this.state;
    const { page_number } = this.parsedQuery();

    const rows = this.props.projects.map(project => {
      return <ProjectsRow key={project.id} project={project} />;
    });

    return (
      <div id="projectsIndex">
        <div className="row mb-2 justify-content-end">
          <div className="col-md-4">
            {this.searchBar()}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <table className="table table-striped table-bordered table-sm table-hover">
              <thead>
                <tr>
                  {this.generateHeaders(ProjectsRow.columns)}
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            {this.pagination({
              page_number: (page_number || 1),
              total,
              page_size,
            })}
          </div>
        </div>
      </div>
    )
  }

  fetchData = (query) => {
    return ProjectsActions.index(query).then(response => {
      this.setState({total: response.meta.total, page_size: response.meta.page_size})
    });
  }
}

const mapStateToProps = state => {
  return {
    projects: state.get('projects').toIndexedSeq(),
    total: state.getIn(['page','totals','projects']),
  }
}

export default connect(mapStateToProps)(ProjectsIndex);