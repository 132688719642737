import React from 'react';

import T from '../../shared/components/t';
import {I18nConsumer} from '../../shared/components/i18n';

class DashboardsIndex extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = { };
  }


  render(){

    return (
      <div>
        <div className="row mb-2 mt-2">
          <div className="col">
            <h2><T k="dashboards.download_app.title" /></h2>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <p><T k="dashboards.download_app.copy" /></p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <I18nConsumer>
              {
                ({t}) => {
                  return (
                    <a href="https://play.google.com/store/apps/details?id=com.dataplor.www.dataplor">
                      <img src={t("dashboards.download_app.badge")} className="mx-auto d-block w-50" alt="dataplor app"/>
                    </a>
                  );
                }
              }
            </I18nConsumer>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardsIndex;