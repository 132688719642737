import React from 'react';
import cn from 'classnames';

class AnalysisDuplicatePlaceCell extends React.PureComponent {
  render(){
    const {currentlySelected, attr, className, explain} = this.props;

    const ex = explain[attr]
    const score = (ex && ex.score)
    const rounded = (score && (score * 100).toFixed(0))

    const isSelected = (currentlySelected === attr);

    const classes = cn(className, {
      "border-transparent": !isSelected,
      "border-dark": isSelected,
    },"border", `scale-border-${rounded}`,`scale-${rounded}`)

    return (
      <div className={classes} onMouseEnter={this.reportHovered} onMouseLeave={this.reportUnhovered}>
        {this.props.children}
      </div>
    );
  }

  reportHovered = (attr) => {
    this.props.reportHovered(this.props.attr);
  }

  reportUnhovered = (attr) => {
    this.props.reportHovered(null);
  }

}


export default AnalysisDuplicatePlaceCell;
