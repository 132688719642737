
import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';


export default {
  index: function(params){
    const q = Object.assign({sort: 'admins.id', sort_dir: 'desc'},params,{page_size: 25});
    return authorizedFetch('/v3/admin/admins',q).then(handleResponse("admins", "load"));
  },
  show: function(id){
    return authorizedFetch(`/v3/admin/admins/${id}`).then(handleResponse("admins", "merge"));
  },
  update: function(id,params){
    const opts = {
      method: 'PUT',
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v3/admin/admins/${id}`,null,opts).then(handleResponse("admins", "merge"));
  }
};
