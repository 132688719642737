import React, { useContext,useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import Immutable from 'immutable';

import I18nContext from './i18n';
import JFormContext from './j_form_context';
import T from './t';
import JFormContractCard from './j_form_contract_card';
import JFormContractAcceptButton from './j_form_contract_accept_button';
import JFormFeedback from './j_form_feedback';
import Loading from './loading';

import useFieldState from '../hooks/use_field_state';

const emptyMap = Immutable.fromJS({});

const JFormContractWidget = (props) => {
  const { element } = props;
  const { t } = useContext(I18nContext);
  const { data_key } = useContext(JFormContext)

  const { show_validation } = useFieldState();

  const dispatch = useDispatch();
  
  const data = useSelector(state => {
    const contract_key = state.getIn(['region','contract_key']);
    return {
      form: state.getIn(['forms',data_key]) || emptyMap,
      contract_key: contract_key,
      contract: state.getIn(['contracts',contract_key]),
    }
  },Immutable.is);

  const md5 = t(data.contract_key);

  useEffect(() => {
    fetch(`https://assets.dataplor.com/contracts/${md5}.md`).then(response => response.text()).then(str => {
      dispatch({type: 'contracts.merge', data: {[data.contract_key]: str}})
    })
  },[md5,dispatch,data.contract_key]);


  if(data.contract){
    return (
      <div>
        <div className="row">
          <div className="col text-center text-primary">
            <h2><T k="onboarding.form.contract.header" /></h2>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <p><T k="onboarding.form.contract.copy" /></p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <JFormContractCard contract={data.contract} form={data.form} style={{height: '50vh', overflowY: 'scroll'}}/>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <JFormContractAcceptButton element={element} md5={md5}/>
            <JFormFeedback element={element} show_validation={show_validation} />
          </div>
        </div>
      </div>
    );
  } else {
    return <Loading />
  }
};

export default React.memo(JFormContractWidget);
