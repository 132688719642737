
import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  create: (email,locale) => {
    const body = JSON.stringify({ data: { type: 'password_reset', attributes: {resource: 'explorer', email: email, locale: locale}}});
    return authorizedFetch('/v2/request_reset_password',null,{method: 'POST', body});
  },
  update: (token,password) => {
    const body = JSON.stringify({ data: { type: 'password_reset', attributes: {resource: 'explorer', token,password}}});
    return authorizedFetch('/v2/reset_password',null,{method: 'PUT', body})
  }
}