import React from 'react';

import MetadataTag from './metadata_tag';

class DimensionMetadata extends React.PureComponent {
  render(){
    const v = this.props.image[this.props.attr];
    const text = `${this.props.attr}: ${v}`;
    
    let style;

    if(v && v > 720){
      style = 'success';
    } else {
      style = 'danger';
    }

    return (
      <MetadataTag text={text} bstyle={style}/>
    )
  }
}

export default DimensionMetadata;