import React from 'react';
import { connect } from 'react-redux';

import LeadsDisableButton from './leads_disable_button';

import LeadsActions from '../actions/leads_actions';

class LeadsActionsPanel extends React.Component {
  render(){
    return (
      <div>
        <LeadsDisableButton lead={this.props.lead} />
      </div>
    )
  }

  onSubmit = (changes) => {
    const body = { data: { type: 'lead', attributes: changes }}
    return LeadsActions.update(this.props.visit.id,body)
  }
}

const mapStateToProps = (state,props) => {
  return {
    lead: state.getIn(['leads',props.match.params.id]),
  }
};

export default connect(mapStateToProps)(LeadsActionsPanel);