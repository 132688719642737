import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

import ExplorersPaymentsActions from '../actions/explorers_payments_actions';

class PaypalAutopayButton extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {};
  }

  render(){
    let content
    let color = 'primary'

    if(this.state.submitting){
      content = <FontAwesomeIcon icon={faSpinner} spin/>
    } else if(this.state.error){
      content = 'Error';
      color = 'danger';
    } else if(this.state.succeeded){
      color = 'success';
      content = 'Submitted!'
    }else {
      content = 'Autopay'
    }

    return (
      <button 
        className={cn('btn w-100',`btn-${color}`,this.props.className)}
        onClick={this.onClick}
        disabled={!this.props.paypal_email}
      >
        {content}
      </button>
    )
  }

  onClick = (e) => {
    this.setState({submitting: true});
    const { explorer_id, amount } = this.props;
    const params = { type: 'payment', attributes: { explorer_id, amount }};
    ExplorersPaymentsActions.create({data: [params]}).then(action => {
      this.setState({submitting: false, succeeded: true});
    }).catch(e => {
      this.setState({submitting: false, succeeded: false, error: e});
    })
  }
}

export default PaypalAutopayButton;