import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import JSONForm from '../../shared/components/json_form';

import ProjectsActions from '../actions/projects_actions';

const schema = Immutable.fromJS({
  "required": ["name","key","schema_key"],
  "properties": {
    "name": {"type": "string"},
    "key": {"type": "string"},
    "schema_key": {"type": "string"},
    "instructions": {"type": "string"},
    "closed": { "type": "boolean" },
    "accepts_new": { "type": "boolean" },
  }
});

const formSchema = Immutable.fromJS({
  "elements": [
    {"type": "field", "field_type": "text", "property": "name", "required": true},
    {"type": "field", "field_type": "text", "property": "key", "required": true, "disabled": true},
    {"type": "field", "field_type": "text", "property": "schema_key", "required": true},
    {"type": "field", "field_type": "textarea", "property": "instructions"},
    {"type": "field", "field_type": "radio", "property": "closed", "required": true},
    {"type": "field", "field_type": "radio", "property": "accepts_new", "required": true},
  ]
});


class ProjectsFormPanel extends React.Component {

  render(){

    return (
      <div className="visits-form mb-2">
        <JSONForm
          i18nKey="projects.form"
          schema={schema}
          form_schema={formSchema}
          formKey="projects/edit"
          onSubmit={this.onSubmit}
          showValidation={true}
        />
      </div>
    )
  }

  onSubmit = () => {
    const { changes,loadForm } = this.props;
    const body = { data: { type: 'visit', attributes: changes }}
    return ProjectsActions.update(this.props.project.id,body).then(json => {
      loadForm({'projects/edit': json.data.attributes});
    })
  }
}

const mapStateToProps = (initialState,initialProps) => {

  const projectId = initialProps.match.params.id

  return (state) => {
    return {
      project: state.getIn(['projects',projectId]),
      changes: state.getIn(['form_changes','projects/edit'])
    };
  }
};

const mapDispatchToProps = {
  loadForm: (data) => ({ type: 'forms.load', data }),
};

export default connect(mapStateToProps,mapDispatchToProps)(ProjectsFormPanel);