import React from 'react';
import { connect } from 'react-redux';
import PlacesPatchRow from './places_patch_row'
import {raw} from '../../shared/schemas/data_schema';
import LiveDate from './live_date';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import Linkify from 'react-linkify';
import isNil from 'lodash/isNil';
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import PlacesOverviewPatchPanelPatches from './places_overview_patch_panel_patches'

class PlacesOverviewPatchPanelRow extends React.Component
{

  constructor(props)
  {
    super(props)
    this.state  ={expanded:false}
  }

  toggleExpanded = (e) => {
    this.setState(prevState => ({expanded: !prevState.expanded}))
  }

  render() {

    const expanded = this.state.expanded
    const winner = this.props.winner
    const patch = this.props.patch

    const c = winner.['c']
    const o = winner.['o']
    const p = winner.['p']
    const v = winner.['v']
    const d = winner.['d']
    const i = winner.['i']
    const z = winner.['z']
    const t = winner.['t']

    const losers = expanded?<PlacesOverviewPatchPanelPatches patch = {patch[p]}/>:undefined

    const color = {'border-danger':!o, 'border-success':o}
      const rowClass = cn(color, 'col-5 row border-solid border mb-2 rounded p-2 self-center align-middle')

      let confidenceOverTime
      if(isNil(z))
      {
        confidenceOverTime =
          <div className ="col-5 align-middle">
            {(c*100).toFixed(2)}%
          </div>
      }
      else
      {
        confidenceOverTime =
        <div className ="col-5 align-middle">
          {c*100}% -> {((z*100).toFixed(2))}%
        </div>
      }
    return (
      <React.Fragment>
        <tr onClick = {this.toggleExpanded}>
          <td>{p}</td>
          <td>{v}</td>
          <td>{c}</td>
          <td><LiveDate date={d} /></td>
        </tr>
        {losers}
      </React.Fragment>
    )
  }
}


export default connect()(PlacesOverviewPatchPanelRow);
