import React, { useCallback } from 'react';
import pick from 'lodash/pick';

import T from './t';

import useForm from '../hooks/use_form';

const JFormContractAcceptButton = (props) => {
  const { element,md5 } = props;
  const property = element.get('property');

  const { 
    value,
    update_form,
  } = useForm(property,mapFormToProps);

  const onClick = useCallback(() => {
    update_form({
      [property]: new Date().toISOString(),
      agreed_contract_md5: md5,
    });
  },[property,update_form,md5]);


  if(value){
    const date = new Date(value).toLocaleDateString();
    return <button className="btn btn-lg btn-primary col"><T k="contracts.button.agreed" date={date}/></button>
  } else {
    return <button className="btn btn-lg col" onClick={onClick}><T k="contracts.button.click_to_agree" /></button>    
  }
};

const mapFormToProps = (form) => pick(form,[
  'value',
  'update_form',
]);

export default React.memo(JFormContractAcceptButton);
