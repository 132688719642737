import React from 'react';
import cn from 'classnames';
import handlebars from 'handlebars';

import MarkdownIt from 'markdown-it';

import Loading from './loading';

const md = new MarkdownIt('commonmark');

const JFormContractCard = (props) => {
  const { contract, form, className, style } = props;

  
  let content;
  if(contract){
    const data = Object.assign(form.toJSON(),{
      date: new Date().toLocaleDateString(),
    });
    const markdown = handlebars.compile(contract)(data);
    const html = md.render(markdown);
    const dsihtml = {__html: html };
    content = <div className={className} dangerouslySetInnerHTML={dsihtml} />
  } else {
    content = <Loading />
  }

  return (
    <div className={cn(className)} style={style}>
      {content}
    </div>
  );
};

export default React.memo(JFormContractCard);