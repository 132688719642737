import React from 'react';
import Immutable from 'immutable';

import JSONFormElement from './json_form_element';

class JSONAddressWidget extends React.PureComponent {
  render(){
    const {formKey,schema,...rest} = this.props;
    const locale = this.props.locale || 'en';

    const commonProps = {...rest,formKey,schema,i18nKey: 'forms.general.address'};

    const form_schema = {
      street_number: {"type": "field", "field_type": "text", "property": "street_number", "required": true},
      street_name:   {"type": "field", "field_type": "text", "property": "street_name", "required": true},
      address2:      {"type": "field", "field_type": "text", "property": "address2"},
      neighborhood:  {"type": "field", "field_type": "text", "property": "neighborhood", "required": true},
      city:          {"type": "field", "field_type": "text", "property": "city", "required": true},
      state:         {"type": "field", "field_type": "text", "property": "state", "required": true},
      postal_code:   {"type": "field", "field_type": "text", "property": "postal_code", "required": true},
    };

    const fields = Object.keys(form_schema).reduce((o,key) => {
      const fs = Immutable.fromJS(form_schema[key]);
      o[key] = <JSONFormElement element={fs} {...commonProps}/>
      return o;
    },{});

    if(locale.match(/en/)){
      return (
        <div className="address_widget">
          <div className="row">
            <div className="col">
              {fields.street_number}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {fields.street_name}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {fields.address2}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {fields.neighborhood}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {fields.city}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {fields.state}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {fields.postal_code}
            </div>
          </div>
        </div>

      )
    } else {
      return null;
    }

  }
}

export default JSONAddressWidget;