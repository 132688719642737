import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import { isLoggedIn } from '../../shared/helpers/authentication_helpers';


class Notifications extends React.PureComponent {
  render(){
    // const { explorer } = this.props;
    // const pathname = this.props.location.pathname;

    let show = false;
    let content = undefined;

    const classes = cn("fixed-bottom bg-dark text-white p-3",{ show: show });
    return (
      <div className={classes} id="notifications">
        {content}
      </div>
    );
  }

}

const mapStateToProps = state => ({
  explorer: state.get('explorer'),
});

export default withRouter(connect(mapStateToProps)(Notifications));