import React from 'react';
import cn from 'classnames';

import T from './t';


class RadioOption extends React.PureComponent {
  render(){
    const { id,value,textKey,optionValue,validation,disabled,property } = this.props;

    const checked = value === optionValue;
    return (
      <div className="custom-control custom-radio">
        <input
          type="radio"
          className={cn('custom-control-input',validation)}
          name={property}
          id={id}
          value={optionValue}
          checked={checked}
          onChange={this.onChange}
          autoComplete="new-password"
          disabled={disabled}
        />
        <label className="custom-control-label" htmlFor={id}>
          <T k={textKey} />
        </label>
      </div>
    );
  }

  onChange = (e) => {
    const { onChange,optionValue } = this.props;
    onChange(e,optionValue);
  }
}

class JSONOptionsForRadio extends React.PureComponent {
  render(){
    const { id,options,...rest } = this.props;
    const elements = [];
    for(let key in options){
      const elId = `${id}_${key}`;
      const optionValue = options[key];
      elements.push(
        <RadioOption {...rest} {...{id: elId,key,optionValue,textKey: key}} />
      )
    }
    return elements;
  }
}

export default JSONOptionsForRadio;