import React from 'react';
import format from 'date-fns/format';

import ExplorersActions from '../actions/explorers_actions';

class SuspensionButton extends React.PureComponent {
  render(){
    if(this.props.resource.get('suspended_at')){
      return (
        <div>
          <button className="btn btn-primary" onClick={this.reinstate}>Reinstate Account</button>
          <span className="ml-2 badge badge-danger">Suspended on {format(this.props.resource.get('suspended_at'),'MMM D YYYY')}</span>
        </div>
      );
    } else {
      return (
        <div>
          <button className="btn btn-danger" onClick={this.suspend}>Suspend Account</button>
        </div>
      );
    }
  }

  suspend = e => {
    return this.actions().update(this.props.resource.get('id'),{
      [this.props.type]: { suspended_at: new Date().toISOString() }
    });
  }

  reinstate = e => {
    return this.actions().update(this.props.resource.get('id'),{
      [this.props.type]: { suspended_at: null }
    });
  }

  actions = () => {
    switch(this.props.type){
    case 'explorer':
      return ExplorersActions;
    default:
      return null;
    }
  }
}


export default SuspensionButton;