import React from 'react';

import PossibleMatch from './possible_match';

class PossibleMatchesCard extends React.PureComponent {

  render(){
    if(this.props.possible_matches.size > 0){
      const common = {visit: this.props.visit, place_validation_id: this.props.place_validation_id};
      
      const items = this.props.possible_matches.toIndexedSeq().map((pm,i) => {
        return <PossibleMatch key={pm.id} pm={pm} i={i} {...common}/>
      });

      return (
        <div className="card  mb-4">
          <div className="card-header">Possible Matches</div>
          <ul className="list-group list-group-flush">
            {items}
          </ul>
        </div> 
      );
    } else {
      return null;
    }
  }
}

export default PossibleMatchesCard;