import Store from '../reducers/store';

import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  show: function(){
    return authorizedFetch(`/v1/validators/validator`,null,{
      headers: {'Content-Type': 'application/json'},
      method: 'GET'
    }).then(json => {
      return Store.dispatch({type: 'validator.load', data: json.data.attributes });
    });
  }
};