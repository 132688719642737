import React from 'react';

class ImageMetadataTag extends React.PureComponent {
  render(){
    return (
      <h4 className="d-inline-block">
        <span className={`badge badge-${this.props.bstyle} mr-1`}>
          {this.props.text}
        </span>
      </h4>
    )
  }
}

ImageMetadataTag.defaultProps = {
  style: 'primary'
}

export default ImageMetadataTag;