
const flags = {
  'mx':'🇲🇽',
  'br':'🇧🇷',
  'co':'🇨🇴',
  'cl':'🇨🇱',
  'us':'🇺🇸',
  'th':'🇹🇭',
  'in':'🇮🇳',
  'aw':'🇦🇼',
  'bs':'🇧🇸',
  'pe':'🇵🇪',

};

export const regionsToFlags = (region_ids=[]) => {
  return region_ids.map(region_id => regionToFlag(region_id)).join(" ");
}

export const regionToFlag = (region_id) => {
  return (flags[region_id] || '🏳');
}




