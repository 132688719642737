import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';

class GooglePlacesMatchSubmitButon extends React.PureComponent {

  render()
  {
    const{ candidate,place } = this.props;
    let text;
    let c;

    if(candidate){
      text = (
        <span>
          Link <span className="badge badge-places">{place.id} - {place.name}</span> with "{candidate.getIn(['json','name'])}"
        </span>
      );
      c = 'btn-primary';
    } else {
      text = "None of these match";
      c = 'btn-warning';
    }

    return (
      <div className="fixed-bottom p-3" >
        <button className={cn("mx-auto d-block btn",c)} onClick={this.onSubmit}>{text}</button>
      </div>
    );
  }

  onSubmit = () => {
    this.props.clearPageAttributes();
    setTimeout(this.props.onComplete({
      data: {
        attributes: {
          google_place_id: this.props.activePlaceId,
        }
      }
    }),0);
  }
}

const mapStateToProps = (initialState,initialProps) => {
  const {observation} = initialProps;

  return state => {
    const activePlaceId = state.getIn(['page','google_match_task_active']);
    return {
      activePlaceId,
      candidate: observation.getIn(['response','matches',activePlaceId]),
    };
  }
};

const mapDispatchToProps = {
  clearPageAttributes: (props={}) => ({ type: 'page.delete_in', data: [['google_match_task_active'],['google_match_task_hovered']] }),
  removeObservation: (observationId) => ({ type: 'observations.delete_in', data: [[observationId.toString()]]}),
  removePlace: (placeId) => ({type: 'places.delete_in', data: [[placeId.toString()]] })
}

export default connect(mapStateToProps,mapDispatchToProps)(GooglePlacesMatchSubmitButon);
