import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import  { jwScoreAsPercentage, phoneScoreAsPercentage, distanceScoreAsPercentage} from '../../shared/helpers/place_helpers'


import ImageWithMetadata from '../../shared/components/image_with_metadata';

const assetsServer = process.env.REACT_APP_ASSETS_SERVER;

class DuplicatePlaceComparisonPanel extends React.PureComponent
{

  generateRow(value1, value2, score, nameAttr)
  {
    const scaleClass = ((value1 && value2) ? `scale-bg-${score} text-white` : 'text-dark');

    return(
      <tr style={{height:"50px"}} key={nameAttr}>
        <td className="span4 text-center align-middle" style={{width:"45%"}}>{value1}</td>
        <td className={`span1 text-center ${scaleClass}`} style={{width:"10%"}}>{nameAttr} <br/> {score}%</td>
        <td className="span4 text-center align-middle" style={{width:"45%"}}>{value2}</td>
      </tr>
    )

  }

  generatePic(image, place)
  {
    return(
      <div className="col">
        <ImageWithMetadata
          image={image}
          urlKey={image.key}
          assetsServer={assetsServer}
          role={image.role}
          latitude ={place.latitude}
          longitude={place.longitude}
        />
      </div>
    )
  }

  render()
  {
    const { place1, place2, imagesP1, imagesP2} = this.props;

    const place1Phone = place1.getIn(['data','phone'])
    const place2Phone = place2.getIn(['data','phone'])

    const place1Mobile = place1.getIn(['data','mobile'])
    const place2Mobile = place2.getIn(['data','mobile'])

    const place1Email = place1.getIn(['data','email'])
    const place2Email = place2.getIn(['data','email'])

    const nameScore = jwScoreAsPercentage(place1.name, place2.name)
    let addressScore, neighborhoodScore, cityScore, stateScore, zipScore, countryScore, distanceScore, emailScore, phoneScore, mobileScore

    if(place1.address && place2.address)
      addressScore = jwScoreAsPercentage(place1.address, place2.address)
    if(place1.neighborhood && place2.neighborhood)
      neighborhoodScore = jwScoreAsPercentage(place1.neighborhood, place2.neighborhood)
    if(place1.city && place2.city)
      cityScore = jwScoreAsPercentage(place1.city, place2.city)
    if(place1.state && place2.state)
      stateScore= jwScoreAsPercentage(place1.state, place2.state)
    if(place1.postal_code && place2.postal_code)
      zipScore = jwScoreAsPercentage(place1.postal_code, place2.postal_code)
    if(place1.country && place2.country)
      countryScore = jwScoreAsPercentage(place1.country, place2.country)
    distanceScore = distanceScoreAsPercentage(place1, place2)
    if(place1Email && place2Email)
      emailScore = jwScoreAsPercentage(place1Email, place2Email)
    if(place1Phone && place2Phone)
      phoneScore = phoneScoreAsPercentage(place1Phone, place2Phone)
    if(place1Mobile && place2Mobile)
      mobileScore = phoneScoreAsPercentage(place1Mobile, place2Mobile)
    let rowsP1
    if(imagesP1)
      rowsP1 = imagesP1.toIndexedSeq().sortBy(i => i.id * -1 ).map(image => {
      if(image)
      {
        return (
          <div className="col" key = {image.key}>
            <ImageWithMetadata
              image={image}
              urlKey={image.key}
              assetsServer={assetsServer}
              role={image.role}
              latitude ={place1.latitude}
              longitude={place1.longitude}
            />
          </div>
        );
      }
      else
      {
        return null;
      }
    });

    let rowsP2
    if(imagesP2)
      rowsP2 = imagesP2.toIndexedSeq().sortBy(i => i.id * -1 ).map(image => {
      if(image)
      {
        return (
          <div className="col" key = {image.key} style = {{"width":"200px"}}>
            <ImageWithMetadata
              image={image}
              urlKey={image.key}
              assetsServer={assetsServer}
              role={image.role}
              latitude ={place2.latitude}
              longitude={place2.longitude}
            />
          </div>
        );
      }
      else
      {
        return null;
      }
    });



    return (
      <div className={cn("card mb-5")}>
        <div className="card-body">
          <div className="row mb-12">
            <div className="col-sm-5">
              <div className="row container" style={{overflowX: "auto", whiteSpace:"nowrap", overflowY:"hidden"}}>
                {rowsP1}
              </div>
            </div>
            <div className="col-sm-2"/>
            <div className="col-sm-5">
              <div className="row container" style={{overflowX: "auto", whiteSpace:"nowrap", overflowY:"hidden"}}>
                {rowsP2}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <table className="table table-striped">
                <tbody>
                  {this.generateRow(place1.name, place2.name, nameScore, "Name")}
                  {this.generateRow(place1.address, place2.address, addressScore, "Address")}
                  {this.generateRow(place1.neighborhood, place2.neighborhood, neighborhoodScore, "Neighborhood")}
                  {this.generateRow(place1.city, place2.city, cityScore, "City")}
                  {this.generateRow(place1.state, place2.state, stateScore, "State")}
                  {this.generateRow(place1.postal_code, place2.postal_code, zipScore, "Zip Code")}
                  {this.generateRow(place1.country, place2.country, countryScore, "Country")}
                  {this.generateRow(place1.longitude +"," +place1.latitude, place2.longitude +"," +place2.latitude, distanceScore, "Distance")}
                  {this.generateRow(place1.getIn(['data','email']), place2.getIn(['data','email']), emailScore, "Email")}
                  {this.generateRow(place1.getIn(['data','phone']), place2.getIn(['data','phone']), phoneScore, "Phone")}
                  {this.generateRow(place1.getIn(['data','mobile']), place2.getIn(['data','mobile']), mobileScore, "Mobile")}
                  <tr>
                    <td className="span4 text-center"><a href={`/places/${place1.id}/edit`}> Go to place</a></td>
                    <td className="span1 text-center">Links</td>
                    <td className="span4 text-center"><a href={`/places/${place2.id}/edit`}> Go to place</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state,props) =>
{
  const imagesp1 = props.place1.getIn(['relationships','images']);
  const imagesp2 = props.place2.getIn(['relationships','images']);

  let imagesPlace1, imagesPlace2
  if(imagesp1)
    imagesPlace1= state.get('images').filter(i => imagesp1.contains(i.id.toString()) )
  if(imagesp2)
    imagesPlace2= state.get('images').filter(i => imagesp2.contains(i.id.toString()) )
  return{
    imagesP1: imagesPlace1,
    imagesP2: imagesPlace2,
  };
};

export default connect(mapStateToProps)(DuplicatePlaceComparisonPanel);
