import React from 'react';
import parse from 'date-fns/parse';
import isValid from 'date-fns/is_valid';
import format from 'date-fns/format';

import T from './t';

class DateTimeFields extends React.PureComponent {
  constructor(props){
    super(props);
    const t = parse(props.value);
    this.state = {
      date: (isValid(t) ? format(t,'YYYY-MM-DD') : ''),
      time: (isValid(t) ? format(t,'HH:mm') : '')
    };
  }

  render(){
    const { id } = this.props;

    return (
     <React.Fragment>
        <div className="form-group">
          <label htmlFor={`${id}date`}><T k="forms.general.date" /></label>
          <input 
            type="date"
            name={this.props.name}
            className={this.props.className}
            onChange={this.dateChange}
            value={this.state.date}
            onBlur={this.onDateBlur}
          />
        </div>
        <div className="form-group">
          <label htmlFor={`${id}time`}><T k="forms.general.time" /></label>
          <input 
            type="time"
            name={this.props.name}
            className={this.props.className}
            onChange={this.timeChange}
            value={this.state.time}
            onBlur={this.onTimeBlur}
          />
        </div>
      </React.Fragment>
    );
  }

  dateChange = (e) => {
    this.setState({date: e.target.value});
    this.onChange(e.target.value,this.state.time);
  }

  timeChange = (e) => {
    this.setState({time: e.target.value});
    this.onChange(this.state.date,e.target.value);
  }

  onChange = (date,time) => {
    if(date && time){
      const datetime = parse(`${date}T${time}`);
      const dts = datetime ? datetime.toISOString() : '';
      this.props.onChange({[this.props.attribute]: dts});      
    } else {
      this.props.onChange({[this.props.attribute]: null});    
    }
  }

  // blur only when both have been blurred
  onDateBlur = (e) => {
    this.setState(prevState => {
      if( prevState.timeBlurred ){ this.props.onBlur(e) }
      return { dateBlurred: true };
    });
  }

  onTimeBlur = (e) => {
    this.setState(prevState => {
      if( prevState.dateBlurred ){ this.props.onBlur(e) }
      return { timeBlurred: true };
    });
  }


}

DateTimeFields.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
}

export default DateTimeFields;