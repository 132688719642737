import React from 'react';
import cn from 'classnames';

class VisitAttributes extends React.PureComponent {
  render(){
    const { visit } = this.props;
    const clientBadgeClass = cn({
      'badge-success': (visit.client === 'android'),
      'badge-secondary': (visit.client !== 'android'),
    },'badge');


    return (
      <div className="card">
        <div className="card-header">Visit info</div>
        <div className="card-body">
          <table className="table table-sm m-0 table-sm">
            <tbody>
              <tr>
                <td className="border-0">Explorer</td>
                <td className={`border-0`}>
                  <a href={`https://admin.dataplor.com/explorers/${visit.observer_id}/edit`} target="_blank" rel="noopener noreferrer">
                    <span className="badge-primary badge">
                      {visit.observer_id}
                    </span>
                  </a>
                </td>
              </tr>
              <tr>
                <td className="border-0">Visit ID</td>
                <td className={`border-0`}>
                  <a href={`https://admin.dataplor.com/visits/${visit.id}/edit`} target="_blank" rel="noopener noreferrer">
                    <span className="badge-visit badge">
                      {visit.id}
                    </span>
                  </a>
                </td>
              </tr>
              <tr>
                <td className="border-0">Submitted via</td>
                <td className={`border-0`}><span className={clientBadgeClass}>{visit.client}</span></td>
              </tr>
              <tr>
                <td className="border-0">IP Address</td>
                <td className={`border-0`}>
                  <a href={`http://www.infosniper.net/index.php?ip_address=${visit.ip_address}`} target="_blank" rel="noopener noreferrer">
                    <span className={clientBadgeClass}>{visit.ip_address}</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default VisitAttributes;
