import React, {useContext} from 'react';
import pick from 'lodash/pick';

import T from './t';

import { calculateFieldValidationState } from '../helpers/field_helpers';

import useForm from '../hooks/use_form';

import I18nContext from '../components/i18n';

const JFormFeedback = (props) => {
  const { element,show_validation } = props;
  const property = element.get('property');

  const { 
    i18n_prefix,
    error,
    warning,
    prevalidation,
  } = useForm(property,mapFormToProps);

  const { t } = useContext(I18nContext)

  const fieldState = calculateFieldValidationState({error,warning,prevalidation});

  if(show_validation){
    if( fieldState === 'error' && error ){
      return (
        <div className="invalid-feedback d-block">
          <T k={error} property={t(`${i18n_prefix}.${property}`)} />
        </div>
      );
    } else if( fieldState === 'warning' && warning ){
      return (
        <small className="form-text text-warning">
          <T k={warning} property={t(`${i18n_prefix}.${property}`)} />
        </small>
      ); 
    }
  } else if(element.get('help')) {
    return (
      <small className="form-text text-muted">
        <T k={`${i18n_prefix}.${element.get('help')}`} />
      </small>
    ); 
  }

  return null;
};

const mapFormToProps = (form) => pick(form,[
  'i18n_prefix',
  'error',
  'warning',
  'prevalidation',
]);

export default React.memo(JFormFeedback);

