import React from 'react';
import { connect } from 'react-redux';

import PasswordResetActions from '../actions/password_reset_actions';

import { clearToken } from '../../shared/helpers/authentication_helpers';

import T from '../../shared/components/t';
import AccountForm from './account_form';
import PasswordResetButton from './password_reset_button';

class AccountsEdit extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = { passwordResetRequested: false, passwordResetSent: false };
  }

  render(){
    return (
      <div>
        <div className="row mb-2">
          <div className="col-6">
            <h2><T k="accounts.new.header"/></h2>
          </div>
          <div className="col-6">
            <button className="btn btn-warning col logout-button" onClick={this.logOut}>
              <T k="navbar.logout"/>
            </button>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <AccountForm 
              formKey="accounts/edit" 
              i18nKey="explorers.form"
              explorer={this.props.explorer}
              onComplete={this.onComplete}
              mode="update"
            />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col">
            <h4 className="mb-3"><T k="accounts.edit.reset_password_header" /></h4>
            <PasswordResetButton 
              className="col btn-info" 
              onClick={this.resetPassword}
              requested={this.state.passwordResetRequested}
              sent={this.state.passwordResetSent}
            />
          </div>
        </div>
      </div>
    );
  }

  onComplete = (params) => {
    this.props.history.push('/');
  }

  logOut = () => {
    clearToken();
    this.props.resetAll();
    this.props.history.push('/login');
  }

  resetPassword = () => {
    this.setState({passwordResetRequested: true});
    PasswordResetActions.create(this.props.explorer.get('email')).then(() => {
      this.setState({passwordResetSent: true});
    });
  }
}

const mapStateToProps = state => ({
  explorer: state.get('explorer')
});

const mapDispatchToProps = {
  updateForm: (data) => ({ 'type': 'forms.merge', data: {'accounts/edit': data }}),
  resetAll: () => ({'type': 'all.reset' }),
}

export default connect(mapStateToProps,mapDispatchToProps)(AccountsEdit);