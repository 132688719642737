import Immutable from 'immutable';

export default function(state=Immutable.fromJS(null),action){
  switch (action.type) {
  case 'place_validation.load':
    return Immutable.fromJS(action.data);
  case 'all.reset':
    return Immutable.fromJS(null);
  default:
    return state
  }
}