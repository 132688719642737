import React from 'react';
import cn from 'classnames';

import T from './t';

import JSONFormFieldGroup from './json_form_field_group';
import JSONInputField from './json_input_field';

class JSONCheckboxField extends JSONInputField {

  componentDidMount= () => {
    const {property,updateForm,validateFormProperties,value} = this.props;
    if(!value){
      updateForm({[property]: false});
      validateFormProperties([property]);
    } 
  }

  render(){
    const {value,property,i18nKey,className,disabled} = this.props;
    const {id} = this.state;
    const checkValidation = {'is-invalid': this.validation()['is-invalid']};

    return (
      <JSONFormFieldGroup className={className}>
        <div className="custom-control custom-checkbox">
          <input id={id} type="checkbox" className={cn('custom-control-input',checkValidation)} name={property} checked={value} onChange={this.onChange} disabled={disabled}/>
          <label className="custom-control-label" htmlFor={id}>
            <T k={[`${i18nKey}.${property}.label`,`${i18nKey}.${property}`]} />
          </label>
          {this.feedback()}
        </div>
      </JSONFormFieldGroup>
    )
  }

  onChange = (e) => {
    const {property,updateForm,validateFormProperties} = this.props;
    updateForm({[property]: e.target.checked});
    validateFormProperties([property]);
  }
}

export default JSONCheckboxField;