import React from 'react';

import JFormCheckboxField from './j_form_checkbox_field';


const transform_value = (v) => !!v;

const transform_value_on_change = (v) => (v ? new Date().toISOString() : null);

const JFormCheckboxTimestampField = (props) => {
  const { element } = props;

  return (
    <JFormCheckboxField 
      element={element}
      transform_value={transform_value}
      transform_value_on_change={transform_value_on_change}
    />
  );
};

JFormCheckboxTimestampField.defaultProps = {
  
}

export default React.memo(JFormCheckboxTimestampField);