import React from 'react';
import { withRouter,Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import TrainingNavigationButtons from './training_navigation_buttons';

import { I18nConsumer } from '../../shared/components/i18n';
import T from '../../shared/components/t';
import Loading from '../../shared/components/loading';

class TrainingsShow extends React.PureComponent {
  render(){
    const {region} = this.props;

    if(region.id){
      const training = region.training

      if(!training || training.isEmpty() ){ return <Redirect to="/" /> }

      const step = parseInt(this.props.match.params.step,10) || 0;
      const trainingStep = training.getIn(['steps',step]);
      const totalSteps = training.get('steps').size;

      return (
        <I18nConsumer>
          {
            (({t}) => {

              return (
                <div className="mt-5">
                  <React.Fragment>
                    {
                      training.get('steps').map(step => {
                        return (<link key={step.get('image')} rel="preload" href={t(step.get('image'))} as="image"/>)
                      })
                    }
                  </React.Fragment>

                  <div className="row mb-5">
                    <div className="col-md-6 col-sm-12">
                        <img src={t(trainingStep.get('image'))} className="mx-auto d-block" width="70%" alt={trainingStep.get('title')}/>
                    </div>
                    <div className="col-md-6 col-sm-12 d-flex align-items-center mt-2">
                      <div>
                        <h3 className="text-center"><T k={trainingStep.get('title')} /></h3>
                        <div className="text-center">
                          <T k={trainingStep.get('body')} />
                        </div>
                      </div>
                    </div>
                  </div>
                    <TrainingNavigationButtons step={step} totalSteps={totalSteps}/>
                </div>

              );
            })
          }
        </I18nConsumer>
      );


    } else {
      return <Loading />
    }

  }

}

const mapStateToProps = (state) => ({
  explorer: state.get('explorer'),
  region: state.get('region')
});

export default withRouter(connect(mapStateToProps)(TrainingsShow));
