import React from 'react';
import { connect } from 'react-redux';
import GooglePlacesMatchMap from './google_places_match_map'
import GooglePlacesMatchCards from './google_places_match_cards';
import GooglePlacesMatchSubmitButton from './google_places_match_submit_button';

class GooglePlacesMatchTask extends React.PureComponent {

  render(){
    const{ task,place,observation } = this.props;

    if(task && place && observation){
      return (
        <div className="position-relative overflow-hidden" style={{height: '100%'}}>
          <GooglePlacesMatchMap task={task} place={place} observation={observation} />
          <GooglePlacesMatchCards task={task} place={place} observation={observation} />
          <GooglePlacesMatchSubmitButton task={task} observation={observation} place={place} onComplete={this.props.onComplete}/>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  let place;
  let observation;
  const task = state.get('tasks').sortBy(t => t.created_at).first();


  if(task){
    const observationId = task.getIn(['association_ids','search_observation_id']).toString();
    observation = state.getIn(['observations',observationId]);
    place = state.getIn(['places',task.getIn(['association_ids','place_id']).toString()]);
  }

  return {
    task,
    observation,
    place,
  }
};

const mapDispatchToProps = {
  clearTask: (task) => ({type: 'tasks.delete_in', data: [[task.id.toString()]]}),
}

export default connect(mapStateToProps,mapDispatchToProps)(GooglePlacesMatchTask);
