import React, { useContext,useCallback } from 'react';
import cn from 'classnames';

import I18nContext from './i18n';

const JFormRadioContentButton = (props) => {
  const { 
    element,
    i18n_prefix,
    value,
    last,
    on_click,
  } = props;

  const option_value = element.get('value');

  const { t } = useContext(I18nContext); 
  const onClick = useCallback(() => {
    on_click(option_value);
  },[on_click,option_value])
  
  const classes = {
    'btn-outline-primary': (value === option_value),
    'mb-4': (!last),
    'mb-2': (last)
  };

  return (
    <button className={cn(classes,"btn col radio-content-button p-4")} onClick={onClick}>
      {t(`${i18n_prefix}.${element.get('title')}`)}
    </button>
  );
};

export default React.memo(JFormRadioContentButton);