import React from 'react';

import VisitsListItemPayablesListItem from './visits_list_item_payables_list_item';

class VisitsListItemPayablesList extends React.PureComponent {

  render(){

    let currency;

    const items = this.props.pricing_items.toList().map((pi) => {
      if(!currency){ currency = pi.currency; }
      const payable = this.props.payables.find(p => p.pricing_item_id === pi.id);
      if(pi.recurring || payable){
        return (<VisitsListItemPayablesListItem key={pi.id} pricing_item={pi} payable={payable} />);
      } else {
        return null;
      }
      
    }).filter(x => x);

    const list = [];
    items.toList().forEach((el,index) => {
      const operator = index === 0 ? null : <span key={`${index}-operator`} className="mx-1">+</span>
      list.push(operator);
      list.push(el);
    });

    const total = this.props.payables.reduce((sum,p) => { return sum + parseFloat(p.amount) },0).toFixed(2);

    return (
      <div className="d-flex justify-content-end">
        {list}
        <span className="mx-2">=</span>
        <span className="align-middle">
          <strong>$ {total} {currency}</strong>
        </span>
      </div>
    )
  }
}

export default VisitsListItemPayablesList;