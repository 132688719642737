export const dataplorEnv = () => {
  const url = window.location.href;
  if(url.match(/^https:\/\/admin\.dataplor\.com/)){
    return 'production';
  } else if( url.match(/^https:\/\/staging.admin\.dataplor\.com/) ){
    return 'staging';
  } else if( url.match(/^https:\/\/pilot.admin\.dataplor\.com/) ){
    return 'pilot';
  } else {
    return 'development';
  }
};