import React from 'react';
import cn from 'classnames';
import { Tooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

class TruncatedTooltip extends React.PureComponent {
  constructor(){
    super();
    this.id = uuidv4().replace(/[\d\-]/g,'');
    this.ref = React.createRef();
    this.state = { isOpen: false };
  }


  render(){
    const { placement, className} = this.props;
    const { isOpen } = this.state;

    const classes = cn('text-truncate',className)

    return (
      <div className={classes} id={this.id} ref={this.ref} onClick={this.onClick}>
        {this.props.children}
        <Tooltip placement={placement} isOpen={ isOpen && this.isTruncated() } target={this.id} toggle={this.toggle}>
          {this.props.children}
        </Tooltip>
      </div>
    );
  }

  isTruncated = (e) => {
    const el = this.ref.current;
    return (el.offsetWidth < el.scrollWidth);
  }

  toggle = (e) => {
    this.setState({isOpen: !this.state.isOpen})
  }

}

TruncatedTooltip.defaultProps = {
  placement: 'bottom',
}


export default TruncatedTooltip;
