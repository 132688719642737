import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Switch,Route,Link,withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import SamplesActions from '../actions/samples_actions';
import SamplesExportActions from '../actions/samples_export_actions';
import SamplesDataPanel from './samples_data_panel';
import SamplesPlacesPanel from './samples_places_panel';
import SamplesGeneratePanel from './samples_generate_panel';
import SamplesAnalyzePanel from './samples_analyze_panel';

import { triggerDownload } from '../helpers/download_helpers';


import LiveDate from './live_date';

class SamplesShow extends React.PureComponent
{
  constructor(props){
    super(props);
    this.state = {
      place: {},
      tab: 'data',
      loaded: false
    };
  }

  componentDidMount()
  {
    if(!this.subpath()){
      this.props.history.push(`${this.props.location.pathname}/data`)
    }
    SamplesActions.show(this.props.match.params.id).then(json => {
      this.setState({loaded: true});
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot)
  {
    if(this.props.match.params.id !== prevProps.match.params.id)
    {
      SamplesActions.show(this.props.match.params.id).then(json => {
        this.setState({loaded: true});
      })
    }
  }


  render()
  {
    if(this.state.loaded)
    {

      const {sample} = this.props;
      const subpath = this.subpath();

      return (
        <div id="samplesShow" className="mb-5">
          <div className="row mt-3">
            <div className="col">
              <h2><span className="badge badge-samples">{sample.id}</span> {sample.name}</h2>
              <h5><span className="badge badge-samples">{sample.uuid}</span></h5>
              <p>Added <LiveDate date={sample.created_at} />, updated <LiveDate date={sample.updated_at} /></p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <a className="mr-2" href={`https://s3.console.aws.amazon.com/s3/buckets/dataplor-data?region=us-west-2&prefix=exports/samples/${sample.id}/&showversions=false`} target="_blank">
                <button className="btn btn-primary">Download Finals</button>
              </a>
              <button className="btn btn-primary btn-warning" onClick={this.downloadDraft}>Download Draft</button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'data')})} to={`/samples/${sample.id}/data`}>Data</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'places')})} to={`/samples/${sample.id}/places`}>Places</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'generate')})} to={`/samples/${sample.id}/generate`}>Generate</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'analyze')})} to={`/samples/${sample.id}/analyze`}>Analyze</Link>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <Switch>
                    <Route exact path="/samples/:id/data" component={SamplesDataPanel} />
                    <Route exact path="/samples/:id/places" component={SamplesPlacesPanel} />
                    <Route exact path="/samples/:id/generate" component={SamplesGeneratePanel} />
                    <Route exact path="/samples/:id/analyze" component={SamplesAnalyzePanel} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return 'loading...'
    }

  }

  subpath = () => {
    return this.props.location.pathname.match(/samples\/\d+\/?(\w*)/)[1];
  }

  downloadDraft = () => {
    SamplesExportActions.show(this.props.sample.id).then(response => {
      triggerDownload("text/csv",`\uFEFF${response.data}`,response.filename);
    })
  }

  switchTab = (e) => {
    e.preventDefault();
    this.setState({tab: e.target.getAttribute('data-tab')})
  }

  fetchData = (query) =>
  {
    SamplesActions.index(
      Object.assign(
        {},
        query,
        {filter: {root_id: this.props.match.params.id }}
      ), "merge"
    )
  }
}

const mapStateToProps = (state,props) =>
{
  const id = props.match.params.id
  const placeId = parseInt(id,10);
  const sample = state.getIn(['samples',id])
  return{
    sample: sample,
  }
}

const mapDispatchToProps = {
  loadForm: (data) => ({ type: 'forms.load', data }),
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(SamplesShow));
