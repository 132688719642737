import Store from '../reducers/store';
import { includedToActions } from '../../shared/helpers/action_helpers';
import { authorizedFetch,cleanBody } from '../../shared/helpers/request_helpers';

const prepare = (formData) => {
  let { longitude, latitude, ...rest } = formData;
  rest = cleanBody(rest);
  return { 
    coordinates_json: { type:"Point",coordinates:[longitude,latitude] },
    ...rest
  };
}

export default {
  index: function(params={}){
    return authorizedFetch('/v2/explorers/visits',params).then(json => {
      if(json.included){
        includedToActions(json.included).forEach(action => {
          Store.dispatch(action);
        }); 
      }
      if(json && json.meta){
        const update = Object.keys(json.meta).reduce((memo,k) => {
          memo[`visits_${k}`] = json.meta[k];
          return memo;
        },{});
        Store.dispatch({type: 'page.merge', data: update});
      }

      if(json && json.data){
        return Store.dispatch({type: 'visits.load', ...json});  
      }
    });
  },
  create: function(params,relationships){
    const body = { data: { type: 'visit', attributes: prepare(params) }};
    if(relationships){ body.data.relationships = relationships }

    const opts = {
      method: 'POST', 
      body: JSON.stringify(body)
    };
    return authorizedFetch(`/v2/explorers/visits`,null,opts).then(json => {
      if(json && json.data){
        return Store.dispatch({type: 'visits.merge', data: [json.data] });        
      }
    });
  },
  // show: function(id){
  //   return authorizedFetch(`places/${id}`).then(json => {
  //     return Store.dispatch({type: 'places.merge', ...json})
  //   })
  // },
  // update: function(id,params){
  //   const opts = {
  //     method: 'PUT', 
  //     body: JSON.stringify(params)
  //   };
  //   return authorizedFetch(`places/${id}`,null,opts).then(json => {
  //     return Store.dispatch({type: 'places.merge', ...json})
  //   });
  // }
};