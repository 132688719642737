import React from 'react';
import { Link } from 'react-router-dom';
import DataplorMap from '../../shared/components/dataplor_map';
import { Marker } from 'react-leaflet'
import { connect } from 'react-redux';

import Loading from '../../shared/components/loading';

import AnalysisDuplicatePlace from './analysis_duplicate_place';

import ComparisonsActions from '../actions/comparisons_actions';
import MergeEventsActions from '../actions/merge_events_actions';


import { calculateBounds } from '../../shared/helpers/google_maps_helpers';

// TODO
// 1. request data for two places
// 2. show data for both places in table on load
// 3. include a Button to remove either of the places from sample (action pending, api pending)
// 4. split map into own component and import google maps helper to find viewport using calculateBounds
// 5. Create an analysis_duplicate_row component
class AnalysisDuplicate extends React.PureComponent {

  constructor(props)
  {
    super(props);
    const {duplicate} = this.props;
    this.state = {
      open: false,
      loaded:false,
      hovered: null,
    };
    console.log(props)
    ComparisonsActions.show(duplicate.get('id'),duplicate.get('duplicate')).then((json) => {
      this.setState({loaded:true, explain: json.meta.explain})
    });
  }

  onDuplicate = (e) => {
      const {place, dupPlace} = this.props;
      this.setState({query: ""})
      MergeEventsActions.create({data: {attributes: {original_id: place.id, duplicate_id: dupPlace.id, should_merge: true}}})
  }

  onExclude = (e) =>
  {
    this.props.onExclude(this.props.dupPlace.id)
  }

  render(){
    const {duplicate, place, dupPlace, style} = this.props;

    const scoreBase = (this.state.explain && this.state.explain.total) || duplicate.get('score')
    const score = Math.floor((scoreBase * 100));

    if(this.state.loaded){
      const {east,west,south,north} = calculateBounds([
        {lat: place.latitude, lng: place.longitude},
        {lat: dupPlace.latitude, lng: dupPlace.longitude},
      ],0.5);
      const bounds = [[north,east],[south,west]]


      return (
          <div className="row mb-1 border border-secondary" style={Object.assign({height: '150px'},style)}>
            <div className="col text-center">
              <h2><span><Link to={`/places/${duplicate.get('id')}/compare/${duplicate.get('duplicate')}`} className={`scale-${score}`}>{score.toFixed(0)}</Link></span></h2>
            </div>
            <div className="col-2">
              <DataplorMap bounds={bounds} key = "place-map" style={{height: "100%"}} zoomControl={false}>
                <Marker position={[ place.latitude,place.longitude]} />
                <Marker position={[ dupPlace.latitude,dupPlace.longitude]} />
              </DataplorMap>
            </div>
            <div className="col-8">
              <div style={{marginTop: '11px'}}>
                <AnalysisDuplicatePlace place={place} onExclude={this.onExclude} className="mb-2" hovered={this.state.hovered} reportHovered={this.reportHovered} explain={this.state.explain}/>
                <AnalysisDuplicatePlace place={dupPlace} onExclude={this.onExclude} hovered={this.state.hovered} reportHovered={this.reportHovered} explain={this.state.explain}/>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <button className="btn btn-success btn-vertical" onClick={this.onDuplicate} > Merge </button>
            </div>

          </div>
      )
    } else {
      return <Loading />
    }
  }

  reportHovered = (attr) => {
    this.setState({hovered: attr})
  }

}


const mapStateToProps = (state,props) => {
  const id = props.duplicate.get('id')
  let place = state.getIn(['places',id.toString()])

  let idDup = props.duplicate.get('duplicate')
  let dupPlace = state.getIn(['places', idDup.toString()])

  return {
    place,
    dupPlace,
  }
}


export default connect(mapStateToProps)(AnalysisDuplicate);
