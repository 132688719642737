import React from 'react';
import { connect } from 'react-redux';

import PlaceValidationsActions from '../actions/place_validations_actions';
import ValidatorActions from '../actions/validator_actions';

import Loading from './loading';
import PlaceValidationsEdit from './place_validations_edit';

class DashboardsIndex extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = { paused: false };
  }

  componentDidMount(){
    ValidatorActions.show();
    const intervalId = window.setInterval(() => {
      if(!(this.props.place_validation && this.props.place_validation.get('attributes') )){
        this.poll();
      } else {
        console.log("no poll")
      }
    },20000);
    this.poll();
    this.setState({intervalId})
  }

  poll(){
    PlaceValidationsActions.create();
  }

  componentWillUnmount(){
    window.clearInterval(this.state.intervalId);
  }

  render(){
    let content;

    if(this.props.place_validation && this.props.place_validation.get('attributes') ){
      content = <PlaceValidationsEdit place_validation={this.props.place_validation} />
    } else {
      content = (
        <div>
          <div className="row">
            <div className="col">
              <Loading />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        {content}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  place_validation: state.get('place_validation')
});

export default connect(mapStateToProps)(DashboardsIndex);