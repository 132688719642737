import React from 'react';
import { connect } from 'react-redux';

import IndexView from './index_view';
import VisitsRow from './visits_row';

import VisitsActions from '../actions/visits_actions';

class LeadsVisitsPanel extends IndexView {
  constructor(props){
    super(props);
    this.state = {};
  }

  render(){

    const { total,page_size } = this.state;
    const { page_number } = this.parsedQuery();

    const rows = this.props.visits.toIndexedSeq().map(visit => {
      return <VisitsRow visit={visit} key={visit.id}/>
    })

    return (
      <div>
        <div className="row">
          <div className="col">
            <table className="table table-sm table-bordered table-hover table-striped col">
              <thead>
                <tr>
                  {this.generateHeaders(VisitsRow.columns)}
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            {this.pagination({
              page_number: (page_number || 1),
              total,
              page_size,
            })}
          </div>
        </div>

      </div>
    )
  }

  fetchData = (query) => {
    return VisitsActions.index(
      Object.assign(
        {},
        query,
        {filter: {lead_id: this.props.match.params.id}}
      )
    ).then(response => {
      this.setState({total: response.meta.total, page_size: response.meta.page_size})
    });
  }
}

const mapStateToProps = (initialState,initialProps) => {
  const leadId = parseInt(initialProps.match.params.id,10);
  return (state) => ({
    visits: state.get('visits').filter(v => v.lead_id === leadId)
  });
}

export default connect(mapStateToProps)(LeadsVisitsPanel);
