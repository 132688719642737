import React from 'react';
import pick from 'lodash/pick';

import useForm from '../hooks/use_form';

import T from './t';

const JFormHeader = (props) => {
  const { element } = props;

  const { i18n_prefix } = useForm(null,mapFormToProps);

  const prefix = element.get('i18n_prefix') || i18n_prefix

  const k = [prefix,element.get('key')].filter(x => x).join(".");

  return (
    <T k={k} className="mb-4 text-center" inline={false}/>
  );
};

const mapFormToProps = (form => pick('i18n_prefix') );

export default React.memo(JFormHeader);