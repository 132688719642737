
import Immutable from 'immutable';

const defaults = {
  id: null,
  number: null,
  url: null,
  meta: null,
  created_at: null,
  updated_at: null,
  key: null,
  format: null,
  width: null,
  height: null,
  orientation: null,
  latitude: null,
  longitude: null,
  location_accuracy: null,
  altitude: null,
  taken_at: null,
  make: null,
  model: null,
  role: null,
  explorer_id: null,
  observation_id: null,
  metadata: {},
  gps_processing_method: null,
  relationships: Immutable.fromJS({}),
};

class Image extends Immutable.Record(defaults){

}

export default Image;
