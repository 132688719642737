import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers,faLock,faTimes,faCheck } from '@fortawesome/pro-light-svg-icons';

import { regionsToFlags } from '../../shared/helpers/emoji_helpers';

import LiveDate from './live_date';

class ProjectsRow extends React.PureComponent {

  render(){
    const { project } = this.props;

    let closedIcon;
    let closedColor;
    if(project.closed){
      closedIcon = faLock;
      closedColor = "warning";
    } else {
      closedIcon = faUsers;
      closedColor = "success";
    }


    let disabledIcon;
    let disabledColor;
    if(project.disabled){
      disabledIcon = faTimes;
      disabledColor = "danger";
    } else {
      disabledIcon = faCheck;
      disabledColor = "success";
    }

    return (
      <tr>
        <td><Link to={`/projects/${project.id}/edit`} className="badge badge-projects">{project.id}</Link></td>
        <td>{regionsToFlags(project.region_ids)}</td>
        <td>{project.name}</td>
        <td><FontAwesomeIcon icon={closedIcon} className={`mx-auto d-block text-${closedColor}`} size="lg" /></td>
        <td><FontAwesomeIcon icon={disabledIcon} className={`mx-auto d-block text-${disabledColor}`} size="lg" /></td>
        <td><code>{project.key}</code></td>
        <td><code>{project.schema_key}</code></td>
        <td><LiveDate date={project.created_at} /></td>
      </tr>
    )
  }
}

ProjectsRow.columns = [
  { col: "id", label: "Id" },
  { col: "", label: "Regions"},
  { col: "name", label: "Name" },
  { col: "closed", label: "Closed" },
  { col: "disabled", label: "Active" },
  { col: "key", label: "Key" },
  { col: "schema_key", label: "Schema Key" },
  { col: "created_at", label: "Created at" },
];

export default ProjectsRow;
