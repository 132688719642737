import React from 'react';

import JSONFormFieldGroup from './json_form_field_group';
import JSONInputField from './json_input_field';
import JSONOptionsForRadio from './json_options_for_radio';

class JSONRadioField extends JSONInputField {
  render(){
    const { i18nKey,element,property,schema,value,className,disabled } = this.props;
    const { id } = this.state;

    const tKey = element.get('i18n_key') || `${i18nKey}.${property}`;

    let options;
    const enums = schema.getIn(['properties',property,'enum']);

    if(enums){
      options = enums.reduce((obj,e) => { obj[`${tKey}.${e}`] = e; return obj },{});
    } else {
      options = {[`forms.general.yes`]: true, [`forms.general.no`]: false }
    }

    const validation = this.validation();

    return (
      <JSONFormFieldGroup className={className}>
        {this.label()}
        <JSONOptionsForRadio onChange={this.onChange} {...{id,options,value,validation,disabled,property}} />
        {this.feedback()}
      </JSONFormFieldGroup>
    );
  }

  onChange = (e,value) => {
    this.props.validateFormProperties([this.props.property]);
    return this.props.updateForm({[this.props.property]: value});
  }

  onBlur = () => {
    this.props.validateFormProperties([this.props.property]);
  }
}

export default JSONRadioField;