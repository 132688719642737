
import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';


export default {
  show: function(delivery_id,params){
    return authorizedFetch(`/v3/admin/deliveries/${delivery_id}/export`,params);
  },
  create: function(delivery_id, params, actionType = "load"){
    const opts = { method: 'POST'};
    return authorizedFetch(`/v3/admin/deliveries/${delivery_id}/export`,params,opts);
  }
};
