import React from 'react';

import T from '../../shared/components/t';

class QuizChoice extends React.PureComponent {
  render(){
    return (
      <div className="row mb-2">
        <div className="col">
          <button className={`btn btn-outline-primary col quiz-choice-${this.props.i}`} onClick={this.onClick} style={{whiteSpace: 'normal'}}>
            <T k={this.props.k} />
          </button>
        </div>
      </div>
    );
  }

  onClick = () => {
    this.props.onSelect({
      question: this.props.question,
      response: this.props.i
    })
  }

}

export default QuizChoice;