import React from 'react';
import debounce from 'lodash/debounce';

class SearchBar extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
    this.search = debounce((value) => {
      this.props.onChange(value);
    },1000);
  }

  componentDidMount(){
    this.setState({ defaultValue: this.props.query.search })
  }

  render(){
    return (
      <input className="form-control" defaultValue={this.state.defaultValue || ''} onChange={this.onChange} placeholder="search"/>
    );
  }

  onChange = (e) => {
    const value = e.target.value;
    this.search(value);
  }
}

export default SearchBar;