import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore,faMapMarkerAlt,faCreditCard,faPlug,faPhone,faUser,faVoicemail} from '@fortawesome/pro-solid-svg-icons';

const icons = {
  phone: faPhone,
  mobile: faPhone,
  name: faStore,
  address: faMapMarkerAlt,
  credit_card: faCreditCard,
  connected: faPlug,
  answered: faUser,
  voicemail: faVoicemail,
}


class ObservationsBotCallBotResults extends React.Component
{

  render()
  {
    const { attribute, confirmed , tiny} = this.props;
    let colorIcon = "text-secondary"
    if(confirmed === true)
    {
      colorIcon = "text-success"
    }
    else if(confirmed === false)
    {
      colorIcon = "text-danger"
    }

    const classNameIcon = tiny?`fa-1x ${colorIcon}`:`fa-2x ${colorIcon}`

    let textAttribute

    if(tiny === undefined)
    textAttribute =
      <div className = "row my-2 text-white">
        <div className = "col mx-auto">
        {attribute}
        </div>
      </div>

      const paddingMainCol = tiny === undefined?"col-auto text-center mt-3":"col-1"
      const paddingMainRow = tiny === undefined?"row my-2 mx-auto":"row"

    return (
      <div className = {paddingMainCol}>
        <div className = {paddingMainRow}>
          <div className = "col mx-auto">
            <span style={{borderRadius:"100%", width:"64px", height:"64px"}}>
              <FontAwesomeIcon className= {classNameIcon} icon={icons[attribute]} />
            </span>
          </div>
        </div>
        {textAttribute}
      </div>
    )
  }
}


export default ObservationsBotCallBotResults;
