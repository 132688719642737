import React from 'react';
import { connect } from 'react-redux';

class SamplesDataPanel extends React.Component {

  render(){
    const { sample } = this.props;    
    return (
      <div className="samples-data mb-2">
        <pre>{JSON.stringify(sample.toJSON(),null,2)}</pre>
      </div>
    )
  }
}

const mapStateToProps = (state,props) =>
{
  return{
    sample: state.getIn(['samples',props.match.params.id])
  };
};

export default connect(mapStateToProps,null)(SamplesDataPanel);
