import React from 'react';
import { connect } from 'react-redux';

import ImageWithMetadata from '../../shared/components/image_with_metadata';

const assetsServer = process.env.REACT_APP_ASSETS_SERVER;

class ObservationsImagesPanel extends React.Component
{
  render()
  {
    const { observation,images } = this.props;
    const rows = images.toIndexedSeq().sortBy(i => i.id * -1 ).map(image => {
      if(image)
      {
        return (
          <div className="row" key={image.id}>
            <div className="col mb-3 d-flex justify-content-center">
              <ImageWithMetadata
                image={image}
                urlKey={image.key}
                assetsServer={assetsServer}
                role={image.role}
                latitude ={observation.latitude}
                longitude={observation.longitude}
              />
            </div>
          </div>
        );
      }
      else
      {
        return null;
      }
    });

    return (
      <div className="container">
        {rows}
      </div>
    );
  }



}

const mapStateToProps = (state,props) => {
  let images;
  const observation = state.getIn(['observations',props.match.params.id]);
  if(observation)
  {
    images = state.get('images').filter(i => i.observation_id === observation.id);
  }
  return {
    observation: observation,
    images: images
  };
};

export default connect(mapStateToProps)(ObservationsImagesPanel);
