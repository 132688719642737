// #  id                   :bigint(8)        not null, primary key
// #  type                 :text
// #  source               :text
// #  observer_type        :string
// #  observer_id          :bigint(8)
// #  observable_type      :string
// #  observable_id        :bigint(8)
// #  parent_id            :bigint(8)
// #  data                 :jsonb
// #  input                :jsonb
// #  response             :jsonb
// #  coordinates          :geography({:srid point, 4326
// #  requested_at         :datetime
// #  responded_at         :datetime
// #  reviewed_at          :datetime
// #  validated            :boolean
// #  validated_at         :datetime
// #  uuid                 :uuid
// #  pricing_structure_id :bigint(8)
// #  lead_id              :bigint(8)
// #  created_at           :datetime         not null
// #  updated_at           :datetime         not null


import Immutable from 'immutable';
import {defaults as observation_defaults} from './observation';
import { inlineLocalAddress } from '../helpers/address_helpers';


export const defaults = Object.assign({},observation_defaults,{

});

class VisitObservation extends Immutable.Record(defaults){
  name(){
    return this.getIn(['response','data','attributes','name']);
  }

  inlineLocalAddress(){
    return inlineLocalAddress(this.getIn(['response','data','attributes']));
  }


}

export default VisitObservation;