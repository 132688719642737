import React from 'react';
import differenceInSeconds from 'date-fns/difference_in_seconds';
import addSeconds from 'date-fns/add_seconds';
import startOfDay from 'date-fns/start_of_day';
import format from 'date-fns/format';


class LiveTimer extends React.Component {

  componentDidMount(){
    const id = window.setInterval(() => {
      this.forceUpdate();
    },1000);
    this.setState({interval: id})
  }

  componentWillUnmount(){
    window.clearInterval(this.state.interval)
  }

  render()
  {
    //let content;
    if(this.props.start)
    {
      const start = new Date(this.props.start);
      const now = new Date();

      const difference = differenceInSeconds(now,start)
      const timer = addSeconds(startOfDay(new Date(0)),difference);

      return (
        <span className="live-timer" data-time={this.props.start}>
          {format(timer,'HH:mm:ss')}
        </span>
      );
    } else {
      return null;
    }

  }
}

export default LiveTimer;
