
export const statusClassColor = (validated) => {
  if(validated === true){
    return 'success';
  } else if(validated === false){
    return 'danger';
  } else {
    return 'default';
  }
} 

export const statusBadgeClassColor = (validated) => {
  if(validated === true){
    return 'success';
  } else if(validated === false){
    return 'danger';
  } else {
    return 'secondary';
  }
}