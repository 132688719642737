import React from 'react';

import JSONFormElement from './json_form_element';

class JSONElementTree extends React.PureComponent {

  render(){
    const {form_schema,...rest} = this.props;

    const elements = form_schema.get('elements').map(element => {
      return <JSONFormElement key={element.hashCode()} element={element} {...rest}/>
    });

    return (
      <React.Fragment>
        { elements }
      </React.Fragment> 
    );
  }
}

export default JSONElementTree;