
import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {

  create: function(observation_id,params){
    const opts = {
      method: 'POST',
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v3/admin/observations/${observation_id}/bot_call_audit_observations`,null,opts).then(handleResponse("observations", "merge"));
  }
};
