import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PlaceValidationsActions from '../actions/place_validations_actions';

import { validateForm } from '../../shared/helpers/schema_helpers';
import { isCompleted } from '../../shared/helpers/visit_observation_helpers';

import T from '../../shared/components/t';
import PlaceValidationsEditMediaColumn from './place_validations_edit_media_column';
import PlaceValidationsEditImagesColumn from './place_validations_edit_images_column';

import SnoozeCard from './snooze_card';
import ProjectCard from './project_card';
import LeadCard from './lead_card';
import TrackRecord from './track_record';
import PossibleMatchesCard from './possible_matches_card';
import VisitAttributes from './visit_attributes';
import VisitWarnings from './visit_warnings';
import SnoozeControls from './snooze_controls';

import VisitsForm from './visits_form';
import NotFoundVisitsForm from './not_found_visits_form';

class PlaceValidationsEdit extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      notes: '',
      snoozeControlOpen: false,
    };
  }

  componentDidMount(){
    const { visit } = this.props;
    const form_data = visit.getIn(['response','data','attributes']).merge({
      completed: visit.completed,
      not_completed_reason: visit.not_completed_reason
    });

    this.props.loadForm({'visits/edit': form_data});
    this.validateForm(form_data);
  }

  render(){
    const { project,xp,lead,visit,place_validation } = this.props;

    let snoozeCard;
    if(place_validation.getIn(['response','meta','snoozed_until'])){
      snoozeCard = (
        <div className="row mb-4">
          <div className="col">
            <SnoozeCard place_validation={place_validation}/>
          </div>
        </div>
      );
    }



    let leadCard;
    if(lead){
      leadCard = (
        <div className="row mb-4">
          <div className="col">
            <LeadCard lead={lead} visit={visit}/>
          </div>
        </div>
      );
    }


    let possibleMatchesCard;
    if(!lead){
      possibleMatchesCard = (
        <div className="row">
          <div className="col">
            <PossibleMatchesCard 
              visit={visit} 
              possible_matches={this.props.possible_matches} 
              place_validation_id={this.props.place_validation.get('id')}
            />
          </div>
        </div>
      )
    }

    let formEl;
    if(isCompleted(visit)){
      formEl = (
        <VisitsForm 
          visit={visit} 
          project={project}
          formKey="visits/edit"
          i18nKey="visits.form"
        />
      );
    } else {
      formEl = (
        <NotFoundVisitsForm 
          visit={visit} 
          project={project}
          formKey="visits/edit"
          i18nKey="visits.form"
        />
      );
    }


    let snoozeControl
    if(this.state.snoozeControlOpen){
      snoozeControl = <SnoozeControls place_validation={this.props.place_validation} nextTask={this.nextTask} validator={this.props.validator}/>
    }

    return (
      <div>
        <div className="row" >
          <div className="col-4" style={{height: 'calc(100vh - 80px)', overflow: 'scroll'}}>
            <PlaceValidationsEditMediaColumn visit={visit} images={this.props.images} lead={lead} possible_matches={this.props.possible_matches}/>
          </div>
          <div className="col-4 pb-5" style={{height: 'calc(100vh - 80px)', overflow: 'scroll'}}>
            <div className="row">
              <div className="col">
                <VisitWarnings visit={visit} images={this.props.images}/>
              </div>
            </div>
            {snoozeCard}
            <div className="row mb-4">
              <div className="col">
                <TrackRecord />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <ProjectCard project={this.props.project} />
              </div>
            </div>
            {leadCard}
            <div className="row mb-4">
              <div className="col">
                <VisitAttributes visit={visit} xp={xp}/>
              </div>
            </div>
            {possibleMatchesCard}
            <div className="row">
              <div className="col">
                {formEl}
              </div>
            </div>
            <div className="row mt-5">
              <div className="col">
                <label><T k="place_validations.edit.notes"/></label>
                <textarea className="form-control" onChange={this.notesChange}/>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col p-4">
                <button className="btn btn-danger col" onClick={this.rejectClick}>Reject</button>
              </div>
              <div className="col p-4">
                <button className="btn btn-primary col" onClick={this.toggleSnooze}>Snooze</button>
              </div>
              <div className="col p-4">
                <button className="btn btn-success col" onClick={this.acceptClick}>Validate</button>
              </div>
            </div>
            {snoozeControl}
          </div>
          <div className="col-4" style={{height: 'calc(100vh - 80px)', overflow: 'scroll'}}>
            <PlaceValidationsEditImagesColumn visit={visit} images={this.props.images}/>
          </div>
        </div>
      </div>
    )
  }

  notesChange = e => this.setState({notes: e.target.value})

  rejectClick = e => this.submit(false)
  acceptClick = e => this.submit(true)

  submit = (validated) => {
    PlaceValidationsActions.update(this.props.place_validation.get('id'),{
      data: { type: 'validator_validation', attributes: this.props.changes.toJSON()},
      meta: {validated: validated}
    }).then( () => { this.nextTask(); })
  }

  validateForm = (data) => {
    const {form,project,loadErrors} = this.props;
    const errors = validateForm(project.schema,(data||form),"visits.errors");
    loadErrors(errors);
  }

  toggleSnooze = () => {
    this.setState(state => ({ snoozeControlOpen: !state.snoozeControlOpen }))
  }

  nextTask = () => {
    this.props.clearForm();
    this.props.clearAll();
    return PlaceValidationsActions.create();
  }
}

const mapStateToProps = (state) => {
  let visit;
  let images;
  let project;
  let lead;
  let possible_matches;
  let project_visit;
  const visitId = state.getIn(['place_validation','relationships','parent','data','id']);
  if(visitId){ visit = state.getIn(['visits',visitId]) }
  if(visit){
    project_visit = state.get('project_visits').filter(i => i.observation_id === visit.id ).sortBy(i => i.created_at).first();
    images = state.get('images').filter(i => i.observation_id === visit.id );
    if(visit.lead_id){ lead = state.getIn(['leads',visit.lead_id.toString()]); }
    if(project_visit){ project = state.getIn(['projects',project_visit.project_id.toString()]); }
    possible_matches = state.get('duplicate_visits').filter(v => v.id !== visit.id )
  }
  
  return {
    place_validation: state.getIn(['place_validation','attributes']),
    images: images,
    changes: state.getIn(['form_changes','visits/edit']),
    form: state.getIn(['forms','visits/edit']),
    visit: visit,
    project: project,
    lead: lead,
    possible_matches: possible_matches,
    validator: state.get('validator'),
  };
};

const mapDispatchToProps = {
  loadForm: (data) => ({ type: 'forms.load', data: data }),
  loadErrors: (errors) => ({type: 'form_errors.load', data: {'visits/edit': errors}}),
  clearForm: () => ({type: 'forms.reset', form: 'visits/edit'}),
  clearAll: () => ({type: 'all.reset' }),
};


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(PlaceValidationsEdit));