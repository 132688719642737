import React, { useCallback } from 'react';

import JFormLabel from './j_form_label';
import JFormInput from './j_form_input';
import JFormFeedback from './j_form_feedback';

import useFieldState from '../hooks/use_field_state';

import { unmaskValue } from '../helpers/field_helpers';

const JFormPhoneWidget = (props) => {
  const { element } = props;

  const { id,show_validation,set_field_show_validation } = useFieldState();

  const country_code = element.get('country_code');
  const mask = element.get('mask');

  const transform_value_on_change = useCallback(value => { 
    if(country_code){
      return `+${country_code}${unmaskValue(value,mask)}` 
    } else {
      return unmaskValue(value,mask);
    }
    
  },[country_code,mask]);

  return (
    <div className="form-group mb-4">
      <JFormLabel id={id} element={element} />
      <div className="input-group">
        <JFormInput
          id={id}
          element={element} 
          type="tel" 
          show_validation={show_validation}
          set_field_show_validation={set_field_show_validation}
          transform_value_on_change={transform_value_on_change}
        />
      </div>
      <JFormFeedback element={element} show_validation={show_validation} />
    </div>
  );
};

export default React.memo(JFormPhoneWidget);