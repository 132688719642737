import React, { useCallback,useContext } from 'react';
import { useDispatch } from 'react-redux';
import addYears from 'date-fns/add_years';

import JFormContext from '../components/j_form_context';

import JFormDateField from './j_form_date_field';


const JFormDOBWidget = (props) => {
  const { element } = props;
  const dispatch = useDispatch();

  const {data_key} = useContext(JFormContext);

  const on_blur = useCallback(date => {
    const min_age = element.get('minimum_age');
    const cutoff = addYears(new Date(date),min_age);
    if(cutoff && cutoff > new Date()){
      dispatch({"type": "form_errors.merge", data: { [data_key]: {dob: "explorers.errors.dob.age" } }})
    }
  },[data_key,dispatch,element]);

  return (
    <JFormDateField element={element} on_blur={on_blur} />
  );
};

export default React.memo(JFormDOBWidget);