

import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  index: function(params){
    const q = Object.assign({sort: 'id', sort_dir: 'desc'},params,{page_size: 25});
    return authorizedFetch('/v1/admin/explorers',q).then(handleResponse("explorers", "load"));
  },
  // create: function(params){
  //   const opts = {
  //     method: 'POST',
  //     body: JSON.stringify(params)
  //   };
  //   return authorizedFetch(`/v1/admin/explorers`,null,opts).then(json => {
  //     Store.dispatch({type: 'explorers.merge', ...json})
  //   });
  // },
  show: function(id){
    return authorizedFetch(`/v1/admin/explorers/${id}`).then(handleResponse("explorers", "merge"));
  },
  update: function(id,params){
    const opts = {
      method: 'PUT',
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v1/admin/explorers/${id}`,null,opts).then(handleResponse("explorers", "merge"));
  }
};
