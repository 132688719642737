import React from 'react';
import { connect } from 'react-redux';
import qs from 'qs';

import ExplorersPaymentsActions from '../actions/explorers_payments_actions';

import ExplorersPaymentsRow from './explorers_payments_row';
import Pagination from './pagination';
import SearchBar from './search_bar';
import CSVButton from './csv_button';

import { cleanParams, parseQuery } from '../../shared/helpers/request_helpers';

// const columns = [
//   { col: "id", label: "Id" },
//   { col: "referral_code", label: "Ref" },
//   { col: "email", label: "Email" },
//   { col: "paypal_email", label: "Paypal Email" },
//   { col: "first_name", label: "First" },
//   { col: "last_name", label: "Last" },
//   { col: "payable", className: "text-right", label: "Payable"},
//   { label: "" },
// ];

class ExplorersPaymentsIndex extends React.PureComponent {

  componentDidMount(){
    ExplorersPaymentsActions.index(this.parsedQuery());
  }

  render(){
    /* eslint-disable jsx-a11y/anchor-is-valid */
    return (
      <div id="explorersIndex">
        <div className="row mt-3">
          <div className="col-md-9 col">
            <CSVButton resource="explorer_payments" total={this.props.total}/>
          </div>
          <div className="col-md-3 col">
            <SearchBar onChange={this.handleSearch} query={this.parsedQuery()}/>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <table className="table table-striped table-bordered table-sm table-hover">
              <thead>
                <tr>
                  <th><a href="" onClick={this.toggleSort} data-col="id">Id</a></th>
                  <th><a href="" onClick={this.toggleSort} data-col="referral_code">Ref</a></th>
                  <th><a href="" onClick={this.toggleSort} data-col="email">Email</a></th>
                  <th><a href="" onClick={this.toggleSort} data-col="paypal_email">Paypal Email</a></th>
                  <th><a href="" onClick={this.toggleSort} data-col="first_name">First</a></th>
                  <th><a href="" onClick={this.toggleSort} data-col="last_name">Last</a></th>
                  <th><a href="" onClick={this.toggleSort} data-col="region_id">Region</a></th>
                  <th className="text-right"><a href="" onClick={this.toggleSort} data-col="payable">Payable</a></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  this.props.explorers_payments.toIndexedSeq().map(explorer => {
                    return <ExplorersPaymentsRow key={explorer.id} explorer={explorer} />
                  })
                }
              </tbody>
            </table>
            <Pagination resource="explorers_payments" mergeQuery={this.mergeQuery} query={this.parsedQuery()}/>
          </div>
        </div>
      </div>
    )
    /* eslint-enable jsx-a11y/anchor-is-valid */
  }

  parsedQuery = () => {
    return parseQuery(this.props.location.search);
  }

  toggleSort = e => {
    e.preventDefault()
    const pq = this.parsedQuery()
    const col = e.target.getAttribute('data-col');
    const dir = (pq.sort === col && pq.sort_dir === 'asc') ? 'desc' : 'asc'
    this.mergeQuery({sort: col, sort_dir: dir, 'page_number': 1})
  }

  mergeQuery = (update) => {
    const newQuery = cleanParams(this.parsedQuery(),update);
    ExplorersPaymentsActions.index(newQuery)
    this.props.history.push(`${this.props.location.pathname}?${qs.stringify(newQuery)}`);
  }

  handleSearch = (value) => {
    this.mergeQuery({search: value});
  }
}

const mapStateToProps = state => {
  return {
    explorers_payments: state.get('explorers_payments'),
    total: state.getIn(['page','totals','explorers_payments']),
  }
}

export default connect(mapStateToProps)(ExplorersPaymentsIndex);