import React from 'react';

import JSONInputField from './json_input_field';
import JSONTextareaField from './json_textarea_field';
import JSONRadioField from './json_radio_field';
import JSONSelectField from './json_select_field';
import JSONPhoneField from './json_phone_field';
import JSONCheckboxField from './json_checkbox_field';
import JSONAutosuggestField from './json_autosuggest_field';
import JSONMaskedNumberField from './json_masked_number_field';

class JSONFormField extends React.PureComponent {
  render(){
    const type = this.props.element.get('field_type')

    const fieldProps = { ...this.props, updateForm: this.updateForm };


    if(type === 'textarea'){
      return <JSONTextareaField {...fieldProps} />
    } else if(type === 'radio'){
      return <JSONRadioField {...fieldProps} />
    } else if(type === 'checkbox'){
      return <JSONCheckboxField {...fieldProps} />
    } else if(type === 'select'){
      return <JSONSelectField {...fieldProps} />
    } else if(type === 'phone'){
      return <JSONPhoneField {...fieldProps} />
    } else if(type === 'autosuggest'){
      return <JSONAutosuggestField {...fieldProps} />
    } else if(type === 'masked_number'){
      return <JSONMaskedNumberField {...fieldProps} />
    } else {
      return <JSONInputField {...fieldProps} />
    }
  }

  updateForm = (update) => {
    this.props.updateForm(update);
  }

}

export default JSONFormField;