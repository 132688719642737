import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Switch,Route,Link } from 'react-router-dom';

import LeadsActions from '../actions/leads_actions';

import LeadsFormPanel from './leads_form_panel';
import LeadsMapPanel from './leads_map_panel';
import LeadsVisitsPanel from './leads_visits_panel';
import LeadsActionsPanel from './leads_actions_panel';


class LeadsEdit extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = { 
    };
  }

  componentDidMount(){
    LeadsActions.show(this.props.match.params.id).then(json => {
      this.props.loadForm({'leads/edit': json.data.attributes});
      this.setState({loaded: true});
    })
  }

  render(){
    if(this.state.loaded){
      const { lead,project } = this.props;

      const subpath = this.props.location.pathname.match(/leads\/\d+\/(\w+)/)[1] || 'edit';

      const projectLink = (
        <React.Fragment>Project <span className="badge badge-danger">{lead.project_id} - {project.name}</span></React.Fragment>
      )

      let placeLink;
      if(lead.place_id){
        placeLink = (
          <React.Fragment>Place <Link to={`/places/${lead.place_id}/edit`}><span className="badge badge-places">{lead.place_id}</span></Link></React.Fragment>
        )
      }

      return (
        <div id="leadsShow" className="mb-5">
            <div className="row mt-3">
              <div className="col">
                <h2><span className="badge badge-info">{lead.id}</span> {lead.name}</h2>
                <p>{projectLink} { placeLink }</p>
              </div>
            </div>
          <div className="row mt-3">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'edit')})} to={`/leads/${lead.id}/edit`}>Edit</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'map')})} to={`/leads/${lead.id}/map`}>Map</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'visits')})} to={`/leads/${lead.id}/visits`}>Visits</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'actions')})} to={`/leads/${lead.id}/actions`}>Actions</Link>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <Switch>
                    <Route exact path="/leads/:id" component={LeadsFormPanel} />
                    <Route exact path="/leads/:id/edit" component={LeadsFormPanel} />
                    <Route exact path="/leads/:id/map" component={LeadsMapPanel} />
                    <Route exact path="/leads/:id/visits" component={LeadsVisitsPanel} />
                    <Route exact path="/leads/:id/actions" component={LeadsActionsPanel} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return 'loading...'
    }
    
  }
}

const mapStateToProps = (state,props) => {

  let visit;
  let project;

  const lead = state.getIn(['leads',props.match.params.id]);
  if(lead){
    
    if(lead.visit_id){ visit = state.getIn(['visits',lead.visit_id.toString()]); }
    if(lead.project_id){ project = state.getIn(['projects',lead.project_id.toString()]); }
    

  }
  return {
    lead,
    visit,
    project,
  };
};

const mapDispatchToProps = {
  loadForm: (data) => ({ type: 'forms.load', data }),
};

export default connect(mapStateToProps,mapDispatchToProps)(LeadsEdit);
