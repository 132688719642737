  
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Loading from './loading';


class OnboardingsStart extends React.PureComponent {

  render(){
    const { explorer,region } = this.props;
    if(region.id){
      if( !explorer.is_done_with_forms ){
        return <Redirect to={`/onboarding_form`}/>;
      } else if(!explorer.training_completed_at){
        return <Redirect to={`/training`} />;
      } else if (!explorer.quiz_completed_at){
        return <redirect to={`/quiz`} />;
      } else {
        return <Redirect to="/" />
      }
    } else {
      return <Loading />
    }
    
  }
}

const mapStateToProps = state => ({
  explorer: state.get('explorer'),
  region: state.get('region'),
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps,mapDispatchToProps)(OnboardingsStart);