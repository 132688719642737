
import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  index: function(params, actionType = "load")
  {
    const q = Object.assign({sort: 'tasks.id', sort_dir: 'desc'},params,{page_size: 25});
    return authorizedFetch('/v3/admin/tasks',q).then(handleResponse("tasks", actionType));
  },
  show: function(id)
  {
   return authorizedFetch(`/v3/admin/tasks/${id}`).then(handleResponse("tasks", "merge"))
  },
  update: function(id,params)
  {
    const opts =
    {
      method: 'PUT',
      body: JSON.stringify(params),
    };
    return authorizedFetch(`/v3/admin/tasks/${id}`,null,opts).then(handleResponse("tasks", "merge"));
  }
};
