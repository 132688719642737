import Immutable from 'immutable';

const defaults = {
  id: null,
  number: null,
  validator_id: null,
  visit_id: null,
  validated: null,
  notes: null,
  validated_at: null,
  created_at: null,
  updated_at: null,
  overridden_at: null,
  relationships: Immutable.fromJS({}),
};

class PlaceValidation extends Immutable.Record(defaults){

}

export default PlaceValidation;
