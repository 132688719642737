import React from 'react';
import { connect } from 'react-redux';

import IndexView from './index_view';
import ExplorersPaymentsPanelRow from './explorers_payments_panel_row';

import PaymentsActions from '../actions/payments_actions';

class ExplorersPaymentsPanel extends IndexView {
  constructor(props){
    super(props);
    this.state = {};
  }

  render(){

    const { total,page_size } = this.state;
    const { page_number } = this.parsedQuery();

    const rows = this.props.payments.toIndexedSeq().map(payment => {
      return <ExplorersPaymentsPanelRow key={payment.id} payment={payment} />
    });

    return (
      <div>
        <table className="table table-sm table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>Id</th>
              <th>Date</th>
              <th>Address</th>
              <th>Amount</th>
              <th>Curr</th>
              <th>Vendor ID</th>
              <th>Vendor Batch ID</th>
              <th>Notes</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
        {this.pagination({
          page_number: (page_number || 1),
          total,
          page_size,
        })}
      </div>
    )
  }

  fetchData = (query) => {
    return PaymentsActions.index(
      Object.assign(
        {},
        query,
        {filter: {'explorer_id': this.props.match.params.id}}
      )
    ).then(response => {
      this.setState({total: response.meta.total, page_size: response.meta.page_size})
    });
  }
}

const mapStateToProps = (initialState,initialProps) => {
  const explorer = initialState.getIn(['explorers',initialProps.match.params.id]);
  return (state) => {
    return {
      payments: state.get('payments').filter(p => p.explorer_id === explorer.id ),
    };
  };
}

export default connect(mapStateToProps)(ExplorersPaymentsPanel);
