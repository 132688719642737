import { authorizedFetch } from '../../shared/helpers/request_helpers';
import { handleResponse } from '../../shared/helpers/action_helpers';

export default
{
  index: function(params={})
  {
    const q = Object.assign(params,{page_size: 25});
    return authorizedFetch('/v1/admin/validator_observations',q).then(handleResponse("place_validations", "load"));
  },
  update: function(id,params)
  {
    const opts = {
      method: 'PUT',
      body: JSON.stringify(params)
    };

    return authorizedFetch(`/v1/admin/validator_observations/${id}`,null,opts).then(handleResponse("observations", "merge"));
  }
};
