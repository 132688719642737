import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie, faRobot } from '@fortawesome/free-solid-svg-icons';
import format from 'date-fns/format';
import differenceInMilliseconds from 'date-fns/difference_in_milliseconds'

const parseVoxImplantMessage = (message) => {
  const speaker = message.get('bot') ? 'bot' : 'user';
  const parsed = message.get(speaker).match(/(\d{2}\/\d{2}\/\d{4}, \d{2}:\d{2}:\d{2})(\((0\.\d+)\))?([\s\S]*)/i);
  let timestamp;
  let confidence;
  let text;
  if(parsed){
    timestamp = new Date(`${parsed[1]}Z`);
    confidence = parseFloat(parsed[3],10);
    text = parsed[4];
  }

  return {
    speaker: speaker,
    timestamp: timestamp,
    confidence: confidence,
    text: text,
  }
}

const parseNexmoMessage = (message) =>
{
  const speaker = message.get('user')
  const timestamp = new Date(message.get('start') || message.get('timestamp'));
  const confidence = message.get('confidence')
  const text = message.get('text')
  const intent = message.get('intent')

  return(
  {
    speaker: speaker,
    timestamp: timestamp,
    confidence:confidence,
    text:text,
    intent: intent,
    expression: message.get('expression')
  });

}

class BotDialogPanel extends React.Component
{
  render()
  {
    const  transcript = this.props.observation.response.get("transcript")
    let messages;

    if(transcript) //&& transcript.get && transcript.get(0).get )
    {
      const beginning = this.props.observation.getIn(['response','transcript_start']) || transcript.getIn([0,'start']) || transcript.getIn([0,'timestamp']);

      messages = transcript.map( (message,i) =>
      {
        const telephonyService = this.props.observation.response.get("telephony_service")
        let msg
        switch(telephonyService)
        {
          case "Voximplant":
            msg = parseVoxImplantMessage(message);
          break
          default:
            msg = parseNexmoMessage(message);
          break
        }

        let confidenceJSX
        let intentJSX
        let alignment = msg.speaker === "bot"? "left":"right";

      //const timestamp = format(msg.timestamp,'MM/DD/YY HH:mm:ss');
        const callstamp = format(new Date(differenceInMilliseconds(msg.timestamp,beginning)),'mm:ss.SSS');
        if(msg.intent){
          intentJSX = <span className="text-monospace mr-2">{msg.intent}</span>
        }

        if(msg.expression){
          intentJSX = <span className="text-monospace mr-2">{msg.expression}</span>
        }

        if(msg.confidence)
        {
          const confidence = (msg.confidence * 100).toFixed()
          confidenceJSX = <span className= {`small float-${alignment} ml-auto mt-1`}><span className="mr-2">{callstamp}</span>{intentJSX}<span className={`scale-${confidence}`}>{msg.confidence.toFixed(3)}</span></span>
        }
        else
        {
          confidenceJSX = <span className= {`small float-${alignment} ml-auto mt-1`}><span className="mr-2">{callstamp}</span>{intentJSX}</span>
        }

        if(msg.speaker === 'bot'){
          return (
            <div key={message} className="row justify-content-start mb-4">
              <div className="col-5">
                <div className="media" key={i}>
                  <div className="media-body p-2 text-light rounded" style={{backgroundColor: '#f9f9f9'}}>
                    <p className="mb-0">{msg.text}</p>
                  </div>
                  <div className="align-self-center ml-3" >
                    <FontAwesomeIcon icon={faRobot} size="lg"/>
                  </div>
                </div>
                {confidenceJSX}
              </div>
            </div>
          )
        }
        else
        {
          return (
            <div key={message} className="row justify-content-end mb-4">
              <div className="col-5">
                <div className="media" key={i}>
                  <div className="align-self-center mr-3" >
                    <FontAwesomeIcon icon={faUserTie} size="lg"/>
                  </div>
                  <div className="media-body text-right p-2 text-light rounded" style={{backgroundColor: '#f9f9f9'}}>
                    <p className="mb-0">{msg.text}</p>
                  </div>
                </div>
                {confidenceJSX}
              </div>
            </div>
          );
        }
      });
    }

    return (
      <div>
        {messages}
      </div>
    );
  }


}


export default connect()(BotDialogPanel);
