export const geocodeIP = () => {
  return fetch("https://ipapi.co/json/",{referrer: ""}).then(response => {
    return response.json();
  })
};

export const countryCodeToCountry = (code) => {
  switch(code.toLowerCase()){
    case 'br':
      return 'brazil';
    case 'mx':
      return 'mexico';
    case 'co':
      return 'colombia';
    case 'ng':
      return 'nigeria';
    default:
      return 'other';
  }
};