import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

class IntercomFAButton extends React.PureComponent {

  render(){
    return (
      <button className="position-fixed btn btn-primary intercom-trigger shadow" style={{height: '48px', width: '48px', borderRadius: '24px', bottom: '1rem', left: '1rem'}}>
        <FontAwesomeIcon icon={faQuestion} transform="grow-6" />
      </button>
    );
  }
}

const mapStateToProps = state => ({ 
  explorer: state.get('explorer')
});




export default withRouter(connect(mapStateToProps)(IntercomFAButton));