import React from 'react';
import { BrowserRouter,Switch } from 'react-router-dom'
import { Provider } from 'react-redux';

import AuthenticatedRoute from '../router/authenticated_route';
import UnauthenticatedRoute from '../router/unauthenticated_route';

import BusinessCategoryActions from '../actions/business_category_actions';

import Navbar from './navbar';
import DashboardsIndex from './dashboards_index';
import Login from './login';
import AppI18nProvider from '../../shared/components/app_i18n_provider';

import Store from '../reducers/store';


class App extends React.Component {
  componentDidMount(){
    BusinessCategoryActions.index('en');
  }

  render() {
    return (
      <Provider store={Store}>
        <AppI18nProvider locale="en">
          <BrowserRouter>
            <div id="validatorApp" className="app">
              <Navbar />
              <div className="container-fluid mt-4" >
                <Switch>
                  <AuthenticatedRoute exact path="/" component={DashboardsIndex} />
                  <UnauthenticatedRoute exact path="/login" component={Login} />
                </Switch>
              </div>
            </div>
          </BrowserRouter>
        </AppI18nProvider>
      </Provider>
    );
  }
}

export default App;
