import React from 'react';
import isNil from 'lodash/isNil';
import {getCountryNameFromCode} from '../helpers/observation_helpers'
const assetsServer = process.env.REACT_APP_ASSETS_SERVER;

class ManualReviewTaskTable extends React.PureComponent {

  render(){
    const{ place } = this.props;

    const attrsToRender = ['name', 'phone','mobile','street_name','street_number', 'number', 'neighborhood', 'city', 'state', 'postal_code', 'interior_photo', 'exterior_photo', 'business_category_id']

    if(place)
    {
      const data = place.data;
      const rows = data.entrySeq().filter((e) => attrsToRender.includes(e[0]) ).sortBy((e) => attrsToRender.indexOf(e[0]) ).map(e => {
        const key = e[0];
        const value = e[1];
        let content;

        if(key.match(/photo$/) && value && value.replace)
        {
          content = (
            <a href={`${assetsServer}/${value.replace(/\.\w+$/,'')}_800.jpg`} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
            );
        }
        else if(value && value.keys)
        {
          content = JSON.stringify(value);
        }
        else
        {
          content = value;
        }

        return (
          <tr key={key}>
            <td><small className="text-monospace">{key}</small></td>
            <td>{content}</td>
          </tr>
        );
      });

      const latitude = place.get("latitude")
      const longitude = place.get("longitude")

      const name = data.get("name")
      const city = data.get("city")
      const state = data.get('state')
      const zipCode = data.get("postal_code")
      const address = data.get("address")
      const street_name = data.get("street_name")
      const street_number=data.get("street_number")
      let country_code = place.get("country_code")

      let streetView, addresssmaps, search
      if(!isNil(latitude) && !isNil(longitude))
      {
        const content = <a href= {"http://maps.google.com?cbll="+latitude+","+longitude+"&cbp=12,20.09,,0,5&layer=c"} target="_blank" rel="noopener noreferrer">{latitude}/{longitude}</a>
        streetView =
        <tr>
          <td><small className="text-monospace">Street View by Lat/Lon</small></td>
          <td>{content}</td>
        </tr>
      }
      if(!isNil(address))
      {
        const addressQuery = [address,zipCode].filter(x => x).join(", ")
        const content = <a href= {"https://www.google.com/maps/search/"+encodeURIComponent(addressQuery)} target="_blank" rel="noopener noreferrer">{addressQuery}</a>
        addresssmaps =
        <tr>
          <td><small className="text-monospace">Google maps by address</small></td>
          <td>{content}</td>
        </tr>
      }

      if(!isNil(name) || !isNil(street_name) || !isNil(street_number))
      {
        if(isNil(country_code))
          country_code = ""
        const query = [name, street_number, street_name, city, state, getCountryNameFromCode(country_code)].filter(x => x).join(", ")
        const content = <a href= {`https://www.google.com/search?q=${encodeURIComponent(query)}`} target="_blank" rel="noopener noreferrer">{query}</a>
        search =
        <tr >
          <td><small className="text-monospace">Google search</small></td>
          <td>{content}</td>
        </tr>
      }

      return (
        <table className="table table-sm table-striped position-relative" >
          <tbody>
            {search}
            {streetView}
            {addresssmaps}
            {rows}
          </tbody>
        </table>
      );
    } else {
      return null;
    }
  }
}


export default ManualReviewTaskTable;
