import React from 'react';
import { connect } from 'react-redux';
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt('commonmark');

class ExplorersNotesCard extends React.PureComponent {

  render(){
    const {form_notes,explorer} = this.props;

    const notes = (form_notes || explorer.notes || '');

    if(notes){
      const elprops = {
        dangerouslySetInnerHTML: {__html: md.render(notes) },
      };

      return (
        <div className="card border border-warning">
          <div className="card-header bg-warning p-1">Notes</div>
          <div className="card-body p-2">
            <div {...elprops} />
          </div>
        </div>
      );
    } else {
      return null;
    }

    
  }

}

const mapStateToProps = (initialState,initialProps) => {
  const explorerId = initialProps.explorer.id;
  const data_key = `explorer/${explorerId}`;
  return (state) => {
    return ({
      form_notes: state.getIn(['forms',data_key,'notes']),
    });
  };
};


export default connect(mapStateToProps)(ExplorersNotesCard);
