import React, { useEffect,useCallback,useState } from 'react';
import Immutable from 'immutable';
import { useSelector,useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import defaultTo from 'lodash/defaultTo';
import Ajv from 'ajv';

import { JFormProvider } from './j_form_context';

import JFormElements from './j_form_elements';
import JFormSubmitButton from './j_form_submit_button';

import { validateForm } from '../helpers/schema_helpers';

import { abstractAuthorizedRequest } from '../actions/abstract_actions';

import { generateResourceResponse,transformPrevalidations,errorResponseToFormErrors } from '../../shared/helpers/action_helpers';

import useInterval from '../hooks/use_interval';

const emptyMap = Immutable.fromJS({});
const ajv = new Ajv({unknownFormats: ['phone']});

const JForm = (props) => {
  const { 
    data_key,
    elements,
    schema,
    i18n_prefix,
    i18n_error_prefix,
    disabled,
    validation,
    mode,
    submit_type,
    on_submit: prop_on_submit,
    on_complete: prop_on_complete,
  } = props;
  const dispatch = useDispatch();

  // state
  const [submitting,set_submitting] = useState(false);
  const [form_show_validation,set_form_show_validation] = useState(null);

  // get the form data from the redux store
  const data = useSelector((state) => {
    const form = state.getIn(['forms',data_key]) || emptyMap;
    const form_changes = state.getIn(['form_changes',data_key]) || emptyMap;
    const errors = state.getIn(['form_errors',data_key]) || emptyMap;
    const async_errors = state.getIn(['form_async_errors',data_key]) || emptyMap;
    const prevalidations = state.get('prevalidations').filter(pv => pv.data_key === data_key);
    const is_async_valid = ( prevalidations.isEmpty() && async_errors.isEmpty() );
    const is_valid = ( is_async_valid && errors.isEmpty() );
    return ({
      form,
      form_changes,
      is_valid,
      is_async_valid,
      prevalidations,
    });
  },Immutable.is);

  // poll for checking the status of pending prevalidations
  const pendingValidations = data.prevalidations.filter(pv => pv.validated === null);
  const interval = pendingValidations.isEmpty() ? null : 1000; // null means no run
  useInterval(() => {
    const ids = pendingValidations.keySeq().join(',');
    return abstractAuthorizedRequest(validation,{ ids },{}).then( json => {
      return generateResourceResponse(json,transformPrevalidations(data_key)).map(dispatch);
    })
  },interval)


  // should we show validation?
  const shouldShowValidation = defaultTo(props.show_validation,form_show_validation);


  // method 1 of 3: determine if a 

  const should_display = useCallback((test_schema) => {
    if(!test_schema){ 
      return true; 
    } else {
      const validator = ajv.compile(test_schema.toJSON());
      return validator(data.form.toJSON());
    }
  },[data.form])

  // method 2 of 3: validate the schema for errors and send those errors into the store
  const validate_form = useCallback((elements=[],actions=[]) => {

    const nextForm = actions.reduce((f,a) => {
      return (a.type === 'forms.merge' ? f.mergeDeep(a.data[data_key]) : f); 
    },data.form);

    const errors = validateForm(schema,nextForm,i18n_error_prefix);
    const errorActions = dispatch({type: 'form_errors.load', data: {[data_key]: errors}});

    const properties = elements.map(e => e.get('property')).filter(x => x);
    const noLocalErrors = !(properties.some(p => errors[p]));
    if(noLocalErrors){
      const needsRemoteValidation = properties.some(p => schema.getIn(['properties',p,'remote']));
      if(needsRemoteValidation){
        const noPrevals = !data.prevalidations.find( pv => properties.includes(pv.attr) );
        if(noPrevals){
          const attributes = properties.reduce((o,p) => { o[p] = data.form.get(p); return o; },{});
          if(Object.entries(attributes).length > 0){
            return abstractAuthorizedRequest(validation,{},{
              method: 'POST',
              body: JSON.stringify({ data: { attributes: attributes, relationships: { project: { data: { id: 6 }} } } })
            }).then(json => {
              return generateResourceResponse(json,transformPrevalidations(data_key)).map(dispatch)
            }).catch(e => {
              if(e.errors){
                dispatch({type: 'form_async_errors.merge', data: {[data_key]: e.errors}});
              }
              if(e.warnings){
                dispatch({type: 'form_async_warnings.merge', data: {[data_key]: e.warnings}});
              }              
            });
          }
        }
      }
    }

    return Promise.resolve(errorActions);
  },[data,dispatch,schema,data_key,i18n_error_prefix,validation]);

  // method 3 of 3 submit the form
  const on_submit = useCallback(() => {
    if(!submitting){
      validate_form().then(actions => {
        set_form_show_validation(true);
        const sync_errors = isEmpty(actions.data[data_key]);
        const is_valid = sync_errors && data.is_async_valid;
        if(is_valid){
          set_submitting(true);
          const body = data.form_changes.toJSON()
          return prop_on_submit(body).then( () => {
            set_submitting(false);
            prop_on_complete(body);
          }).catch( response => {
            console.log("error!",response);
            set_submitting(false);
            if(response && response.errors){
              const errors = errorResponseToFormErrors(response);
              const actionData = { [data_key]: errors };
              dispatch({type: 'form_async_errors.merge', data: actionData });
            } else {
              throw response;
            }
          });
        }
      });
    }
  },[data.is_async_valid,data.form_changes,data_key,validate_form,set_submitting,prop_on_submit,prop_on_complete,dispatch,submitting]);

  // just a handler to submit if someone presses enter
  const on_key_down = useCallback((e) => {
    if(e.which === 13){ on_submit() }
  },[on_submit]);

  // build the context object to export via the provider to the fields
  const contextValue = {
    data_key,
    is_valid: data.is_valid,
    schema,
    i18n_prefix,
    disabled,
    show_validation: shouldShowValidation,
    submitting,
    mode,
    validate_form,
    should_display,
  };



  // validate the form once on initial render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { validate_form(); },[schema])

  return (
    <JFormProvider value={contextValue}>
      <div onKeyDown={on_key_down}>
        <div style={{paddingBottom: "52px"}}>
          <JFormElements elements={elements}/>
        </div>
        <JFormSubmitButton {...{i18n_prefix,disabled,submit_type,on_submit,submitting,set_submitting}} is_valid={data.is_valid}/>
      </div>
    </JFormProvider>
  );
};

JForm.defaultProps = {
  data_key: '',
  elements: Immutable.fromJS([]),
  schema: Immutable.fromJS({}),
  i18n_prefix: '',
  disabled: false,
  submit_type: 'arrow', // button | arrow
  mode: 'create',
  on_submit: (form) => (Promise.resolve()),
  on_complete: (form) => (Promise.resolve()),

};


export default React.memo(JForm);
