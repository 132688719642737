import React from 'react';

import AnalysisPlacesMissingCardRow from './analysis_places_missing_card_row';

class AnalysisPlacesMissingCard extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      open: false,
    };
  }

  render(){
    const {ids,attribute} = this.props;

    let body;

    if(this.state.open){
      const rows = ids.map((id) => {
        return <AnalysisPlacesMissingCardRow id={id} attribute={attribute} />
      });


      // TODO window using react-window so it doesnt render possibly 100k rows at once
      body = (
        <ul className="list-group list-group-flush">
          {rows}
        </ul>
      )
    } 

    return (
      <div className="card border-danger mb-2">
        <div className="card-header text-danger cursor-pointer" onClick={this.toggle}>
          Missing {attribute} [{ids && ids.size || 0}]
        </div>
        {body}
      </div>
    )
  }

  toggle = () => {
    this.setState({open: !this.state.open})
  }
}


export default AnalysisPlacesMissingCard;
