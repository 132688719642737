import React from 'react';

import T from '../../shared/components/t';

class HoldingTank extends React.PureComponent {

  render(){
    return (
      <div>
        <p><T k="holding_tank.coming_soon" /></p>
      </div>
    );
  }

}


export default HoldingTank;