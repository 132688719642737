import React from 'react';

import JFormLabel from './j_form_label';
import JFormOptionsForRadio from './j_form_options_for_radio';
import JFormFeedback from './j_form_feedback';

import useFieldState from '../hooks/use_field_state';

const should_check = (value,option_value) => {
  if(value === null && option_value === false){ return true; }
  if(!!value && option_value === true ){ return true }; 
}
const transform_value_on_change = (option_value) => option_value ? (new Date().toISOString()) : null;

const JFormRadioTimestampField = (props) => {
  const { element } = props;

  const { id,show_validation,set_field_show_validation } = useFieldState();

  return (
    <div className="form-group mb-4">
      <JFormLabel id={id} element={element} className="mb-3"/>
      <JFormOptionsForRadio  
        id={id}
        element={element}
        show_validation={show_validation}
        set_field_show_validation={set_field_show_validation}
        should_check={should_check}
        transform_value_on_change={transform_value_on_change}
      />
      <JFormFeedback element={element} show_validation={show_validation} />
    </div>
  );
};

export default React.memo(JFormRadioTimestampField);