import React from 'react';

class PlaceValidationsEditMediaColumn extends React.PureComponent {
  render(){

    return (
      <div className="h-100">
        <div className="h-50" ref={this.streetView}></div>
        <div className="h-50" ref={this.map}></div>
      </div>
    )
  }
}

export default PlaceValidationsEditMediaColumn;