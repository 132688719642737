import Immutable from 'immutable';

const defaults = {
  id: null,
  project_id: null,
  observation_id: null,
  created_at: null,
  updated_at: null,
  relationships: Immutable.fromJS({}),
};

class ProjectVisit extends Immutable.Record(defaults){

}

export default ProjectVisit;
