
import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  index: function(params){
    const q = Object.assign({sort: 'observations.id', sort_dir: 'desc'},params,{page_size: 25});
    return authorizedFetch('/v3/admin/observations',q).then(handleResponse("observations", "load"));
  },
  // create: function(params){
  //   const opts = {
  //     method: 'POST',
  //     body: JSON.stringify(params)
  //   };
  //   return authorizedFetch(`visits`,null,opts).then(json => {
  //     Store.dispatch({type: 'visits.merge', ...json})
  //   });
  // },
  show: function(id){
    return authorizedFetch(`/v1/admin/observations/${id}`).then(handleResponse("observations", "merge"))
  },
  update: function(id,params){
    const opts = {
      method: 'PUT',
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v1/admin/observations/${id}`,null,opts).then(handleResponse("observations", "merge"));
  }
};
