import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import isNaN from 'lodash/isNaN';

import Loading from './loading';
import JFormElements from '../../shared/components/j_form_elements';
import QuizChoice from './quiz_choice';

const quizId = "0";

class QuizzesShow extends React.PureComponent {

  componentDidMount(){
    this.props.initQuiz()
  }

  render(){
    const {region,quiz_answers} = this.props;

    if(region.id){
      const quiz = region.get('quiz');
      if(!quiz || quiz.isEmpty() ){ return <Redirect to="/" /> }

      if(quiz_answers){
        const lastAnswered = parseInt(this.props.quiz_answers.keySeq().max(),10);
        if( this.props.finishedQuiz ){ return <Redirect to="/" /> }

        const question = isNaN(lastAnswered) ? 0 : (lastAnswered + 1);

        const choices = quiz.getIn(['questions',question,'choices']).map((choice,i) => {
          return <QuizChoice key={choice} k={choice} i={i} question={question} onSelect={this.onSelect} />
        });

        return (
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="mb-2">
                <JFormElements elements={quiz.getIn(['questions',question,'elements'])} />
              </div>
              <div className="mb-2">
                {choices}
              </div>
            </div>
          </div>
        );
      }
    }
    
    return <Loading />
  }

  onSelect = ({question,response}) => {

    const quiz = this.props.region.get('quiz');


    const grade = (response === quiz.getIn(['questions',question,'answer']));
    this.props.updateQuiz({[quizId]: {[question]: grade }});

    const totalQuestions = quiz.get('questions').size;
    if( (question + 1) === totalQuestions ){
      this.props.history.push('/quiz/complete');
    }
  }

}

const mapStateToProps = state => ({
  explorer: state.get('explorer'),
  region: state.get('region'),
  quiz_answers: state.getIn(['quiz_answers',quizId]),
  finishedQuiz: !!state.getIn(['explorer','quiz_completed_at'])
});

const mapDispatchToProps = {
  initQuiz: () => ({ type: 'quiz_answers.load', data: {[quizId]: {}}}),
  updateQuiz: (update) => ({ type: 'quiz_answers.merge', data: update })
}

export default connect(mapStateToProps,mapDispatchToProps)(QuizzesShow);
