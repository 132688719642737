import React from 'react';
import { Link } from 'react-router-dom';
import subDays from 'date-fns/sub_days'
import format from 'date-fns/format'

class ExplorersQuotaRow extends React.PureComponent {

  render(){
    const days = [];
    let totalVisits = 0;
    for(let i=0;i<14;i++){
      const date = format(subDays(new Date(),i),'YYYY-MM-DD');
      const visits = this.props.explorer.visits_by_date.get(date) || 0;
      if(i < 7){ totalVisits = totalVisits + visits }
      days.unshift(<td key={i} className="text-right">{visits}</td>)
    }

    const payment = Math.floor(totalVisits / 19) * 42

    return (
      <tr>
        <td><Link to={`/explorers/${this.props.explorer.id}/edit`} className="badge badge-explorers">{this.props.explorer.id}</Link></td>
        <td>{this.props.explorer.paypal_email}</td>
        <td>{this.props.explorer.first_name}</td>
        <td>{this.props.explorer.last_name}</td>
        {days}
        <td className="text-right">{payment}</td>
      </tr>
    )
  }
}

export default ExplorersQuotaRow;