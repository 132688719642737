import React from 'react';
import { connect } from 'react-redux';
import ObservationsPatchRow from './observations_patch_row'
import ManualReviewTaskTable from './manual_review_task_table'
import {raw} from '../../shared/schemas/data_schema';
import ImageWithMetadata from '../../shared/components/image_with_metadata';
import PlacesActions from '../actions/places_actions';

const assetsServer = process.env.REACT_APP_ASSETS_SERVER;

class ObservationsPatchPanel extends React.Component
{

  componentDidMount()
  {
    PlacesActions.show(this.props.place.id)
  }

  render(){
    const { observation, place, images } = this.props;

    const patch = observation.patch
    const attrOrder = Object.keys(raw.properties)
    let rows
    if(patch)
    {
      // rows = patch.sortBy((v,k) => ((attrOrder.indexOf(v.get("p").substring(1))+1) || 999 )).map(row => {
      //  return <ObservationsPatchRow patch = {row}/>
      //});

      rows = attrOrder.map(x => {
        //if(x === 'business_status')
        let value = patch.find(attribute => attribute.get("p").substring(1) === x.toString())        
        if(value)
          return <ObservationsPatchRow patch = {value}/>
        else
          return <ObservationsPatchRow attribute = {x}/>

      })
    }


    let rImages
    if(images)
      rImages = images.toIndexedSeq().sortBy(i => i.id * -1 ).map(image => {
      if(image)
      {
        return (
          <div className="row" key={image.id}>
            <div className="col mb-3 d-flex justify-content-center">
              <ImageWithMetadata
                image={image}
                urlKey={image.key}
                assetsServer={assetsServer}
                role={image.role}
                latitude ={place.latitude}
                longitude={place.longitude}
              />
            </div>
          </div>
        );
      }
      else
      {
        return null;
      }
    });




    // const sortedData = place.data.toKeyedSeq().sortBy((v,k) => ((attrOrder.indexOf(k)+1) || 999 ))

    const mrtt = <ManualReviewTaskTable place = {place} />


    return (
      <div>
        <div className="row">
          <div className="col-6">
            <div className="card">
              <div className="card-header h5">
                Current place data:
              </div>
              <div className="card-body">
                <pre>
                  {mrtt}
                </pre>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card">
              <div className="card-header h5">
                Patch:
              </div>
              <div className="card-body">
                {rows}
              </div>
            </div>
          </div>
        </div>
        <div className= "row">
          <div className = "col">
            <div className="card">
              <div className="card-header h5">
                Images:
              </div>
              <div className="card-body">
                {rImages}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state,props) =>
{
  const observation = state.getIn(['observations',props.match.params.id]);
  const place = state.getIn(['places', observation.relationships.get('place')]);
  const imagesIds = place.get('relationships').has('images')?place.get('relationships').get('images'):undefined
  let images
  if(imagesIds)
    images = state.get('images').filter(i => imagesIds.contains(i.id.toString()));
  return{
    observation: observation,
    place: place,
    images: images
  };
};

export default connect(mapStateToProps)(ObservationsPatchPanel);
