import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

class Loading extends React.PureComponent {
  render(){
    return (
      <div className="row mt-5">
        <div className="col">
          <FontAwesomeIcon icon={faSpinner} spin className="mx-auto d-block" size="lg"/>
        </div>
      </div>
    )
  }
}

export default Loading;