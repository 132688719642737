import React from 'react';

import SessionsActions from '../actions/sessions_actions';

import T from '../../shared/components/t';

class Login extends React.PureComponent {

  constructor(props){
    super(props);
    this.state = {
      email: '',
      password: ''
    }
  }

  render(){
    let errors;
    if(this.state.errors){
      errors = <div className="invalid-feedback" style={{display: 'block'}}>{this.state.errors}</div>
    }

    return (
      <div className="row justify-content-center" >
        <div className="col-sm-6 col">
          <div className="card mt-sm-5">
            <div className="card-body">
              <h5 className="card-title"><T k="sessions.login.header" d="Login" /></h5>
              <div className="form-group">
                {errors}
              </div>
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label htmlFor="loginEmail"><T k="sessions.login.email" /></label>
                  <input className="form-control" type="email" onChange={this.updateEmail} value={this.state.email} id="loginEmail"/>
                </div>
                <div className="form-group">
                  <label htmlFor="loginPassword"><T k="sessions.login.password" /></label>
                  <input className="form-control" type="password" onChange={this.updatePassword} value={this.state.password} id="loginPassword"/>
                </div>

                <button type="submit" className="btn btn-primary"><T k="sessions.login.button" d="Login" /></button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onSubmit = (e) => {
    e.preventDefault();
    const params = {
      email: this.state.email,
      password: this.state.password
    };
    return SessionsActions.create(params).then(() => {
      this.props.history.push('/');
    }).catch(resp => {
      return resp.json().then(json => {
        if(json.errors && json.errors[0]){
          this.setState({errors: json.errors[0].detail});
        }
      })
    })
  }

  updateEmail = e => this.setState({email: e.target.value});
  updatePassword = e => this.setState({password: e.target.value});

}

export default Login;