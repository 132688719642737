import React from 'react';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import isPast from 'date-fns/is_past';
import format from 'date-fns/format';

class LiveDate extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      relative: false,
    };
  }

  componentDidMount(){
    const id = window.setInterval(() => {
      this.forceUpdate();
    },60000);
    this.setState({interval: id})
  }

  componentWillUnmount(){
    window.clearInterval(this.state.interval)
  }

  render(){
    let content;
    if(this.props.date){
      if(this.state.relative){
        const suffix = isPast(this.props.date) ? 'ago' : 'from now';
        content = `${distanceInWordsToNow(this.props.date)} ${suffix}`
      } else {
        content = format(new Date(this.props.date),'MM/DD/YY hh:mma');
      }

      return (
        <span className="live-date" data-time={this.props.date} onClick={this.toggleRelative}>
          {content}
        </span>
      );
    } else {
      return null;
    }
    
  }

  toggleRelative = (e) => {
    this.setState(prevState => ({relative: !prevState.relative}))
  }
}

export default LiveDate;