import Store from '../reducers/store';
import { baseUrl,cleanBody,requestHeaders } from '../../shared/helpers/request_helpers';

export default {
  create: function(params={}){
    const attrs = Object.assign({},cleanBody(params),{resource: 'explorer'});
    return fetch(`${baseUrl}/v2/authenticate`,{
      headers: requestHeaders(),
      method: 'POST',
      body: JSON.stringify({data: { attributes: attrs, type: 'session'}}),
    }).then(response => {
      if(response.status < 400){ return response.json()} 
      else { return response.json().then(json => {
        throw json;
      })}
    }).then(json => {
      if(json && json.data){
        return Store.dispatch({type: 'explorer.merge', data: json.data.attributes });
      }
    });
  },
};