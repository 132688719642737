import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';


class Pagination extends React.PureComponent {
  render(){
    
    if(this.props.size && this.props.total){
      const number = parseInt(this.props.query['page_number'] || 1,10)
      const pageSize = this.props.query['page_size'] || 25;
      const pages = Math.ceil(this.props.total/pageSize);
      const pageLinks = [];
      for(let i=0; i < pages; i++){
        
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        const link = <a className="page-link" href="#" onClick={this.update} data-num={i+1}>{i+1}</a>
        
        pageLinks.push(
          <li key={i} className={cx('page-item',{active: (number === (i+1))})}>
            {link}
          </li>)
      }
      return (
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            {pageLinks}
          </ul>
        </nav>
      )
    } else {
      return null;
    }
  }

  update = e => {
    e.preventDefault();
    this.props.mergeQuery({'page_number': e.target.getAttribute('data-num')})
  }
}

const mapStateToProps = (state,props) => {
  return {
    size: props.size || state.get(props.resource).size || 0,
    total: props.total || state.getIn(['page','totals',props.resource])
  }
}

export default connect(mapStateToProps)(Pagination);