import React from 'react';
import { connect } from 'react-redux';

import LeadsActions from '../actions/leads_actions';

import IndexView from './index_view';
import LeadsRow from './leads_row';

const columns = [
  {col: "id", label: "Id"},
  {col: "number", label: "Number"},
  {col: "name", label: "Name"},
  {col: "city", label: "City"},
  {col: "country", label: "Country"},
  {col: "project_id", label: "Project"},
  {col: "created_at", label: "Created"},
];

class LeadsIndex extends IndexView {

  constructor(props){
    super(props);
    this.state = {};
  }

  render(){
    const { total,page_size } = this.state;
    const { page_number } = this.parsedQuery();

    const rows = this.props.leads.toIndexedSeq().map(lead => {
      return <LeadsRow key={lead.id} lead={lead} />
    });

    return (
      <div id="leads_index">
        <div className="row mb-2 justify-content-end">
          <div className="col-md-4">
            {this.searchBar()}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <table className="table table-striped table-bordered table-sm table-hover">
              <thead>
                <tr>
                  {this.generateHeaders(columns)}
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            {this.pagination({
              page_number: (page_number || 1),
              total,
              page_size,
            })}
          </div>
        </div>
      </div>
    )
  }

  fetchData = (query) => {
    return LeadsActions.index(query).then(response => {
      this.setState({total: response.meta.total, page_size: response.meta.page_size})
    });
  }
}

const mapStateToProps = state => {
  return {
    leads: state.get('leads'),
    total: state.getIn(['page','totals','leads']),
  }
}

export default connect(mapStateToProps)(LeadsIndex);