
const assetsServer = process.env.REACT_APP_ASSETS_SERVER;

export const getPhoto = (model,role,version="800@2x",index=0) => {
  const data = model.getIn(['response','data','attributes',`${role}_photo`]);
  if(data){
    if(data.forEach){
      return versionKey(data.getIn([index,'key']),version)
    } else {
      return versionKey(data,version);
    }
  }
  return null;
};


export const versionKey = (key,version) => {
  const extensionless_key = key.replace(/\.\w+$/,'');
  return `${assetsServer}/${extensionless_key}_${version}.jpg`;
}