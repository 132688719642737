import React, { useCallback} from 'react';
import cn from 'classnames';
import pick from 'lodash/pick';

import { validationClasses,unmaskValue } from '../helpers/field_helpers';

import useForm from '../hooks/use_form';

import T from './t';

const JFormRadioOption = (props) => {
  const {
    element,
    id,
    property,
    show_validation,
    set_field_show_validation,
    option_key,
    option_value,
    should_check,
    transform_value_on_change,
  } = props;

  const { 
    disabled,
    value,
    raw_value,
    error,
    warning,
    prevalidation,
    update_form,
    validate_form,
  } = useForm(property,mapFormToProps);

  const vc = show_validation ? validationClasses({error,warning,prevalidation,value}) : {};

  const onChange = useCallback((e) => {
    const action = update_form({[property]: transform_value_on_change(option_value)});
    set_field_show_validation(true);
    validate_form([element],[action]);
  },[property,update_form,set_field_show_validation,transform_value_on_change,option_value,validate_form,element]);

  return (
    <div className="custom-control custom-radio">
      <input
        type="radio"
        className={cn('custom-control-input',vc)}
        name={property}
        id={`${id}.${option_key}`}
        value={option_value}
        checked={should_check(raw_value,option_value)|| ''}
        onChange={onChange}
        autoComplete={element.get('ac') || 'new-password'}
        disabled={element.get('disabled') || disabled}
      />
      <label className="custom-control-label" htmlFor={`${id}.${option_key}`}>
        <T k={`${option_key}`} />
      </label>
    </div>
  )
};

const mapFormToProps = (form) => pick(form,[
  'disabled',
  'raw_value',
  'value',
  'error',
  'warning',
  'prevalidation',
  'update_form',
  'validate_form',
]);

const no_transform = (v) => v;

JFormRadioOption.defaultProps = {
  should_check: no_transform,
  transform_value_on_change: unmaskValue,
}

export default React.memo(JFormRadioOption);