import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLevelDownAlt } from '@fortawesome/pro-light-svg-icons';
import defaultTo from 'lodash/defaultTo';

import T from '../../shared/components/t';

class JSONOperatingHoursDay extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {}
  }

  render(){
    const { short, long, checked, open, close, showValidation, openValid, closeValid,disabled } = this.props;
    const id = Math.random().toString().slice(2,12);

    const displayValidation = defaultTo(showValidation,(this.state.openBlur && this.state.closeBlur));

    const openValidation = { 
      "is-valid": (displayValidation && openValid && open),
      "is-invalid": (displayValidation && !openValid),
    };

    const closeValidation = { 
      "is-valid": (displayValidation && closeValid && close),
      "is-invalid": (displayValidation && !closeValid),
    };


    let autoFill;
    if( open && close ){ autoFill = <FontAwesomeIcon icon={faLevelDownAlt} onClick={this.autoFill}/> }

    return(
      <div className="form-row mb-1 form-inline">
        <div className="col-3 col-sm-3">
          <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" checked={checked} id={`${id}${long}`} onChange={this.onChange}/>
            <label className="custom-control-label" htmlFor={`${id}${long}`}>
              <span className="d-inline d-sm-none"><T k={`forms.general.weekdays.${short}`}/></span>
              <span className="d-none d-sm-inline"><T k={`forms.general.weekdays.${long}`}/></span>
            </label>
          </div>
        </div>
        <div className="col-4 col-sm-4" >
          <input type="time" step="300" name={`${long}_open`} className={cn('form-control col px-1',openValidation)} onChange={this.openChange} value={open||''} onBlur={this.openBlur} autoComplete="new-password" disabled={disabled}/>
        </div>
        <div className="col-4 col-sm-4" >
          <input type="time" step="300" name={`${long}_close`} className={cn('form-control col px-1',closeValidation)} onChange={this.closeChange} value={close||''} onBlur={this.closeBlur}  autoComplete="new-password" disabled={disabled}/>
        </div>
        <div className="col-1 col-sm-1">
           {autoFill}
        </div>
      </div>  
    );
  }

  onChange = () => {
    this.props.updateWeek({[this.props.long]: !this.props.checked});
  }

  openChange = (e) => {
    this.props.updateWeek({[this.props.long]: !!e.target.value});
    const value = e.target.value === '' ? null : e.target.value;
    this.props.updateForm({[`${this.props.long}_open`]: value});
  }

  closeChange = (e) => {
    this.props.updateWeek({[this.props.long]: !!e.target.value});
    const value = e.target.value === '' ? null : e.target.value;
    this.props.updateForm({[`${this.props.long}_close`]: value});
  }

  openBlur = (e) => {
    this.setState({openBlur: true});
    if(this.state.closeBlur){ this.props.onBlur(e) }
  }

  closeBlur = (e) => {
    this.setState({closeBlur: true});
    if(this.state.openBlur){ this.props.onBlur(e) }
  }

  autoFill = () => {
    this.props.autoFill(this.props.long,this.props.open,this.props.close);
  }
}

JSONOperatingHoursDay.defaultProps = {
  onFocus: () => {},
  onBlur: () => {},
}

export default JSONOperatingHoursDay;
