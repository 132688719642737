import React from 'react';

import JFormLabel from './j_form_label';
import JFormInput from './j_form_input';
import JFormFeedback from './j_form_feedback';

import useFieldState from '../hooks/use_field_state';

const event_to_value = (e) => e.target.checked;

const JFormCheckboxField = (props) => {
  const { element, transform_value, transform_value_on_change } = props;

  const { id,show_validation,set_field_show_validation } = useFieldState();

  return (
    <div className="custom-control custom-checkbox mb-4">
      <JFormInput
        id={id}
        element={element} 
        type="checkbox" 
        className="custom-control-input"
        show_validation={show_validation}
        set_field_show_validation={set_field_show_validation}
        transform_value={transform_value}
        transform_value_on_change={transform_value_on_change}
        event_to_value={event_to_value}
      />
      <JFormLabel id={id} element={element} className="custom-control-label"/>
      <JFormFeedback element={element} show_validation={show_validation} />
    </div>
  );
};

JFormCheckboxField.defaultProps = {
  transform_value: (v) => v,
  transform_value_on_change: (v) => v,
}

export default React.memo(JFormCheckboxField);