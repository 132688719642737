import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle,faCheck,faPhone,faBuilding,faStar,faFilePlus,faUserTie,faEnvelopeOpen } from '@fortawesome/pro-solid-svg-icons';

const icons = {
  'validated': faBuilding,
  'qualified': faPhone,
  'converted': faCheck,
  'activated': faStar,
  'enhanced': faFilePlus,
  'manager': faUserTie,
  'virket_consent_given': faCheck,
  'virket_consent_confirmed': faEnvelopeOpen,
}


class VisitsListItemPayablesListItem extends React.PureComponent {
  render(){
    const { key } = this.props.pricing_item;
    const earned = !!this.props.payable
    const paid = this.props.payable ? !!this.props.payable.payment_id : false;
    
    const classes = cn('payable-item fa-layers fa-fw fa-lg',{ 
      'text-primary': (earned && !paid),
      'text-success': (earned && paid),
      'text-black-50': !earned,
    });

    return (
      <span className={classes} data-payable={key}>
        <FontAwesomeIcon icon={faCircle}/>
        <FontAwesomeIcon icon={icons[key]} inverse transform="shrink-7" />
      </span>
    );
  }
}

export default VisitsListItemPayablesListItem;