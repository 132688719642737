
import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';


export default {
  index: function(sampleId, params, actionType = "load")
  {
    const q = Object.assign({sort: 'places.id', sort_dir: 'desc'},params,{page_size: 10000});
    return authorizedFetch(`/v3/admin/samples/${sampleId}/places`,q).then(response => {
      response.data.forEach(resource => {
        resource.attributes.sample_id = sampleId;
      });

      return response;
    }).then(handleResponse("places", actionType));

  }
};
