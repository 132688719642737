import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Switch,Route,Link } from 'react-router-dom';


import AdminsActions from '../actions/admins_actions';

import AdminsDataPanel from './admins_data_panel';
import AdminsTasksPanel from './admins_tasks_panel';




class AdminsShow extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      tab: 'details',
    };
  }

  componentDidMount()
  {
    AdminsActions.show(this.props.match.params.id).then(json =>
      {
        this.setState({loaded:true});
      }
    )
  }

  render()
  {
    if(this.state.loaded)
    {
      const { admin} = this.props;
      const subpathMatch = this.props.location.pathname.match(/admin\/\d+\/(\w+)/);
      const subpath = subpathMatch ? subpathMatch[1] : '';

      let adminLink;


      return (
        <div id="adminShow" className="mb-5">
          <div className="row mt-3">
            <div className="col">
              <h2>
                <span className="badge badge-admin">{admin.id}</span>
                <span className="badge badge-dark ml-2">{admin.first_name}</span>
              </h2>
              <div className="clearfix mb-2"></div>
              { adminLink }
              <div className="clearfix mb-2"></div>

            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === '' || subpath === 'data')})} to={`/admins/${admin.id}/data`}>Data</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'tasks')})} to={`/admins/${admin.id}/tasks`}>Tasks</Link>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <Switch>
                    <Route exact path="/admins/:id" component={AdminsDataPanel} />
                    <Route exact path="/admins/:id/data" component={AdminsDataPanel} />
                    <Route exact path="/admins/:id/tasks" component={AdminsTasksPanel} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return 'loading...'
    }

  }

  switchTab = (e) => {
    e.preventDefault();
    this.setState({tab: e.target.getAttribute('data-tab')})
  }

}

const mapStateToProps = (state,props) =>
{
  const admin = state.getIn(['admins',props.match.params.id]);
  return{
    admin

  }
}

export default connect(mapStateToProps)(AdminsShow);
