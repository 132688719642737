import React from 'react';

import JSONMaskedNumberField from './json_masked_number_field';


class JSONPhoneField extends JSONMaskedNumberField {
  
  prepend = () => {
    const countryCode = this.props.element.get('country_code');

    return (
      <div className="input-group-prepend">
        <span className="input-group-text">+{countryCode}</span>
      </div>
    )
  }

  formToState = (formValue) => {
    const countryCode = this.props.element.get('country_code');
    const localValue = formValue.replace(/\D/g,'');
    return (localValue ? `+${countryCode}${localValue}` : null);
  }

  stateToForm = (stateValue) => {
    const countryCode = this.props.element.get('country_code');
    return stateValue ? stateValue.replace(`+${countryCode}`,'') : ''
  }
}

export default JSONPhoneField;