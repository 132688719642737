import React, { useCallback,useEffect,useMemo } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import Immutable from 'immutable';

import { useParams } from "react-router";
import { useHistory,Redirect } from "react-router-dom";

import {simulateSequence} from '../../shared/helpers/schema_helpers'; 

import JFormViewSequence from '../../shared/components/j_form_view_sequence';
import IntercomFAButton from './intercom_fa_button';

const ExplorerOnboarding = (props) => {
  const { region } = props;
  const { view_name } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const obf = region.onboarding_form;

  // this might be useful later when we have more than just explorer data objects
  // const data_keys = uniq([
  //   obf.get('data_key'),
  //   ...obf.get('views').map(v => v.get('data_key')).toArray()
  // ]).filter(x => x);

  const data = useSelector(state => {
    return {
      explorer: state.get('explorer'),
    }
  },Immutable.is);

  const viewToGoTo = useMemo(() => {
    // eslint-disable-next-line no-unused-vars
    const [finished,pathToGoTo,history] = simulateSequence(obf.toJSON(),data.explorer.toJSON());
    const possibleViews = history.map(s => s.viewName).filter(x => x);

    let viewToGoTo;
    if( finished || possibleViews.includes(view_name) ){
      viewToGoTo = view_name;
    } else {
      viewToGoTo = possibleViews[possibleViews.length - 1];
    }

    return viewToGoTo;
  },[view_name,data.explorer,obf]);


  useEffect(() => {
    dispatch({type: 'forms.load', data })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const onComplete = useCallback(() => {
    history.push('/training');
  },[history]);

  if( view_name !== viewToGoTo ){
    return <Redirect to={`/onboarding_form/${viewToGoTo}`} />
  } else {
    return (
      <div className="row justify-content-center">
        <div className="col-md-8">
          <React.Fragment>
            <JFormViewSequence
              view_name={viewToGoTo}
              sequence={region.onboarding_form}
              on_complete={onComplete}
              path="/onboarding_form/:view_name"
            />
            <IntercomFAButton />
          </React.Fragment>
        </div>
      </div>
    );
  }

};

export default React.memo(ExplorerOnboarding);