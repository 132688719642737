import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA_ID);
ReactGA.set({appVersion: process.env.REACT_APP_RELEASE, appName: 'explorer'})

class GoogleAnalytics extends React.Component {

  render(){
    ReactGA.set({ userId: this.props.explorer_id });
    ReactGA.pageview(`${this.props.location.pathname}${this.props.location.search}`);
    return null;
  }
}

const mapStateToProps = state => ({ 
  explorer_id: state.getIn(['explorer','id'])
});


export default withRouter(connect(mapStateToProps)(GoogleAnalytics));