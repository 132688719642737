import React from 'react';
import { connect } from 'react-redux';
import geodist from 'geodist';
import RetinaImage from 'react-retina-image';


import PlaceValidationsActions from '../actions/place_validations_actions';

import { getPhoto } from '../../shared/helpers/image_helpers';
import { inlineLocalAddress } from '../../shared/helpers/address_helpers';

class PossibleMatch extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = { open: false};
  }

  render(){
    const { visit,pm } = this.props;

    const visitCoords = {lat: visit.latitude, lon: visit.longitude};
    const pmCoords = {lat: pm.latitude, lon: pm.longitude};
    const dist = geodist(visitCoords,pmCoords,{unit: 'meters'});

    const address = inlineLocalAddress(pm.getIn(['response','data','attributes']));

    let photo;
    if( getPhoto(pm,'exterior') ){
      photo = (
        <a href={getPhoto(pm,'exterior','800@2x',0)} target="_blank" rel="noopener noreferrer" >
          <RetinaImage 
            className="col pr-0"
            width="64"
            src={getPhoto(pm,'exterior','64',0)} 
            alt={pm.name()}
            checkIfRetinaImgExists={false}
          />
        </a>
      )
    }

    return (
      <li className="list-group-item">
        <div className="row">
          <div className="col-2 p-0">
            {photo}
          </div>
          <div className="col-7">
            <h5 className="card-title mb-0">{this.props.i+1}. {pm.name()}</h5>
            <p className="card-text small mb-0">{address}</p>
            <p className="card-text small">{dist}m away</p>
          </div>
          <div className="col-3 d-flex align-items-center">
            <button className="btn btn-danger btn-sm col" onClick={this.onDupe}>Dupe!</button>
          </div>
        </div>
      </li>
    );
  }

  onDupe = (e) => {
    if(window.confirm(`Are you sure this is a duplicate of ${this.props.pm.name()}?`)){
      PlaceValidationsActions.update(this.props.place_validation_id,{
        data: { 
          type: 'place_validation', attributes: { validated: false, notes: 'Place is already in the system' },
          relationships: { place: {data: {id: this.props.pm.id, type: 'place'}} },
        },
      }).then( () => {
        this.props.dispatch({type: 'forms.reset', form: 'places/edit'});
        this.props.dispatch({type: 'all.reset' });
        return PlaceValidationsActions.create();
      });
    }
  }
}

export default connect()(PossibleMatch);

