import React from 'react';
import geodist from 'geodist';

class LeadCard extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = { open: false};
  }

  render(){
    const { lead } = this.props;

    const visitCoords = {lat: this.props.visit.latitude, lon: this.props.visit.longitude};
    const leadCoords = {lat: parseFloat(lead.latitude), lng: parseFloat(lead.longitude)};
    const dist = geodist(visitCoords,leadCoords,{unit: 'meters'});

    let distClass;
    if(dist < 30){
      distClass = 'text-success';
    } else if(dist < 60){
      distClass = 'text-warning';
    } else {
      distClass = 'text-danger';
    }

    return (
      <div className="card">
        <div className="card-header">Lead info</div>
        <div className="card-body">
          <table className="table table-sm m-0">
            <tbody>
              <tr>
                <td className="border-0">Distance from visit</td>
                <td className={`border-0 ${distClass}`}><strong>{dist} m</strong></td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{lead.name}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>{lead.address}</td>
              </tr>
              <tr>
                <td>Neighborhood</td>
                <td>{lead.neighborhood}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{lead.city}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{lead.state}</td>
              </tr>
              <tr>
                <td>Country</td>
                <td>{lead.country}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> 
    );
  }
}

export default LeadCard;