import Immutable from 'immutable';

const defaults = {
  id: null,
  type: null,
  priority: null,
  admin_id: null,
  data: Immutable.fromJS({}),
  association_ids: Immutable.fromJS({}),
  assigned_at: null,
  created_at: null,
  updated_at: null,
  relationships: Immutable.fromJS({}),
  response: Immutable.fromJS({}),
};

class Task extends Immutable.Record(defaults){

}

export default Task;
