import React from 'react';
import { connect } from 'react-redux';
import { Marker } from 'react-leaflet'
import { v4 as uuidv4 } from 'uuid';
import DataplorMap from '../../shared/components/dataplor_map';
import PatchForm from './patch_form';
import ManualReviewTaskTable from './manual_review_task_table';
import {raw as dataSchema} from '../../shared/schemas/data_schema'
import {patchFormToPatch} from '../helpers/patch_form_helpers';
import PlacesActions from '../actions/places_actions';


const properties = Object.keys(dataSchema.properties)

class ManualReviewTask extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = {error:undefined}
  }

  render()
  {
    const { task, place, formKey } = this.props;
    if(task && place){

      return (
        <div className="position-relative overflow-hidden" style={{height: '100%'}}>
          <div className="row" style={{height: '100%'}}>
            <div className="col-5" style={{height: '100%'}}>
              <div className="row position-relative" style={{height: '50%'}}>
                <div className="col overflow-auto" style={{height: '100%', width: '100%'}}>
                  <ManualReviewTaskTable place={place} />
                </div>
              </div>
              <div className="row" style={{height: '50%'}}>
                <div className="col" style={{height: '100%'}}>
                  <DataplorMap center={[place.latitude, place.longitude]} zoom={15} key="place-map" style={{height: '100%'}}>
                    <Marker position={[place.latitude, place.longitude]} />
                  </DataplorMap>
                </div>
              </div>
            </div>
            <div className="col-7 overflow-auto pb-5" style={{height: '100%'}}>
              <PatchForm formKey={formKey} pin={properties} data={place.data} />
            </div>
          </div>
          <div className="fixed-bottom bg-light border border-top border-solid border-dark">
            <div className="row my-3">
              <div className="col-3 mx-auto">
                <button className="col btn btn-info" onClick={this.onSubmit}>Save</button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null
  }

  onSubmit = () => {
    const body = {
      data: {
        attributes: {
          patch: patchFormToPatch(this.props.form),
        }
      }
    }

    if(this.props.form_errors.size === 0)
    {
      this.props.onComplete(body);
    }
  }

}

const mapStateToProps = (initialState,initialProps) => {
  const formKey = uuidv4();

  return (state) => {
    let place;
    //const task = state.get('tasks').sortBy(t => t.assigned_at).first();
    const task = initialProps.task
    if(task)
    {
      let id = task.getIn(['association_ids','place_id'])
      place = state.getIn(['places',id.toString()])
      if(id && !place)
        PlacesActions.show(id.toString())

    }
    return {
      task,
      place,
      form: state.getIn(['forms',formKey]),
      form_errors: state.getIn(['form_errors', formKey]),
      formKey,
    }
  };
};

const mapDispatchToProps = {
  clearTask: (task) => ({type: 'tasks.delete_in', data: [[task.id.toString()]]}),
}

export default connect(mapStateToProps,mapDispatchToProps)(ManualReviewTask);
