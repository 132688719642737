import { cleanParams,authorizedFetch } from '../../shared/helpers/request_helpers';
import Papa from 'papaparse';

const jsonToArray = (response,columns=[]) => {
  return response.data.map(line => {
    return line.attributes
  })
}

export const generateCSV = (resource,params) => {

  const p = cleanParams({},params,{'page[size]': null});
  return authorizedFetch(resource,p).then(json => {
    const transformed = jsonToArray(json);
    return Papa.unparse(transformed);
  });
};