import React from 'react';
import { connect } from 'react-redux';

class ObservationsDataPanel extends React.Component
{

  render(){
    const { observation } = this.props;

    return (
      <div className="observations-data mb-2">
        <pre>{JSON.stringify(observation.toJSON(),null,2)}</pre>
      </div>
    )
  }
}

const mapStateToProps = (state,props) =>
{
  const observation = state.getIn(['observations',props.match.params.id]);

  return{
    observation: observation,
  };
};

export default connect(mapStateToProps)(ObservationsDataPanel);
