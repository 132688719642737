import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import isFriday from 'date-fns/is_friday';
import addDays from 'date-fns/add_days'

import T from '../../shared/components/t';

const nextFriday = () => {
  const today = new Date();
  for(let i=0;i<7;i++){
    if(isFriday( addDays(today,i) )){ 
      return addDays(today,i)
    }
  }
}

class VisitsOwedBox extends React.PureComponent {
  render(){
    const owed = parseFloat(this.props.owed);

    const classes = cn({
      'text-muted': (owed < 4),
      'text-success': (owed > 5)
    },'mb-0')

    return (
      <div className="border rounded card">
        <div className="card-body">
          <div className="row text-center">
            <div className="col border-right">
              <h5 className={classes}>$ {owed.toFixed(2)} {this.props.currency}</h5>
              <small><T k="visits.index.owed" /></small>
            </div>
             <div className="col">
              <h5 className="mb-0">{nextFriday().toLocaleDateString()}</h5>
              <small><T k="visits.index.next_payment_date" /></small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currency: state.getIn(['page','visits_currency']),
  owed: state.getIn(['page','visits_owed'])
});


export default connect(mapStateToProps)(VisitsOwedBox);