import React from 'react';
import { connect } from 'react-redux';
import { Link,withRouter } from 'react-router-dom';
import cn from 'classnames';

import PlaceValidationsActions from '../actions/place_validations_actions';

import { isLoggedIn,clearToken } from '../../shared/helpers/authentication_helpers';
import { regionsToFlags } from '../../shared/helpers/emoji_helpers';
import T from '../../shared/components/t';

class Navbar extends React.PureComponent {

  constructor(props){
    super(props);
    this.state = {show: false};
  }

  render(){
    let loggedIn = isLoggedIn();
    
    let info;
    if(loggedIn){



      info = (
        <li className="nav-item active ml-3">
          <span className="navbar-text mx-auto ">
            {this.props.validator.email}</span>
        </li>
      );
    }

    let signout;
    if(loggedIn){
      const link = (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="#" className="nav-link ml-auto" onClick={this.signOut}>
          <T k="sessions.logout.button" />
        </a>
      )

      signout = (
        <li className="nav-item ml-3">
          {link}
        </li>
      );
    }

    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark mb-4">
        <Link className="navbar-brand" to="/">
          Validator
          <span className="text-white ml-2">{regionsToFlags(this.props.validator.regions)}</span>
        </Link>

        <ul className="navbar-nav ml-auto">
          {info}
          {signout}
        </ul>
      </nav>
    )
  }

  signOut = (e) => {
    e.preventDefault();
    if(this.props.place_validation){
      PlaceValidationsActions.destroy(this.props.place_validation.get('id')).then(() => {
        clearToken();
        this.props.history.push('/login');
      })
    } else {
      clearToken();
      this.props.history.push('/login')
    }
  }

  linkKlass = (path) => cn("nav-link",{active: (this.props.location.pathname === path)})
}

const mapStateToProps = (state) => {
  return {
    validator: state.get('validator'),
    place_validation: state.getIn(['place_validation','attributes']),
  };
};

export default withRouter(connect(mapStateToProps)(Navbar));