import React from 'react';
import { connect } from 'react-redux';
import PlacesPatchRow from './places_patch_row'
import {raw} from '../../shared/schemas/data_schema';
import LiveDate from './live_date';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import Linkify from 'react-linkify';
import isNil from 'lodash/isNil';
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import PlacesOverviewPatchPanelPatchesRow from './places_overview_patch_panel_patches_row'

class PlacesOverviewPatchPanelPatches extends React.Component
{
  render()
  {
    const loser = this.props.patch
    const losers = loser.map( element => {

     return <PlacesOverviewPatchPanelPatchesRow patch = {element}/>
    })

    return (
      <React.Fragment>
        {losers}
      </React.Fragment>

    )
  }
}


export default connect()(PlacesOverviewPatchPanelPatches);
