import React from 'react';
import { connect } from 'react-redux';
import TasksIndex from './tasks_index'

class AdminsTasksPanel extends React.Component
{

  render()
  {
    const adminId = this.props.match.params.id;
    const history = this.props.history;
    let location = {}
    location.search = ""//{admin_id: adminId}
    const filter = {admin_id: adminId}
    const fFilter =   Object.assign(
        {},
        {},
        {filter}
      )

    const tasks = <TasksIndex location ={location} filter= {fFilter} history ={history}/>

    return (
      <div className="observations-data mb-2">
        {tasks}
      </div>
    )
  }
}


export default connect()(AdminsTasksPanel);
