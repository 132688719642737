import React from 'react';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faArrowCircleRight } from '@fortawesome/pro-light-svg-icons';

import T from '../../shared/components/t';

const JFormSubmitButton = (props) => {
  const { submit_type,submitting,mode,i18n_prefix,disabled,on_submit,is_valid } = props;
  
  let el;
  if(submit_type === 'button'){
    let buttonText;

    if(submitting){
      buttonText = <FontAwesomeIcon icon={faSpinner} spin className="mx-auto d-block" size="lg"/>
    } else {
      if(mode === 'update'){
        buttonText = <T k={[`${i18n_prefix}.submit_update`,"forms.general.update"]} />
      } else {
        buttonText = <T k={[`${i18n_prefix}.submit_create`,"forms.general.create"]} />
      }
    }

    el = (
      <div className="row">
        <div className="col">
          <button className="btn btn-primary col shadow json-form-submit-button" type="submit" onClick={on_submit} disabled={disabled || submitting}>
            {buttonText}
          </button>
        </div>
      </div>
    );
  } else if(submit_type === 'arrow'){

    const arrowClasses = cn({ pulsebeat: is_valid },'mx-auto d-block');

    el = (
      <div className="position-relative">
        <div className="position-absolute" style={{bottom: '0px', right: '0px'}}>
          <button className="btn btn-link json-form-submit-button p-0" type="submit" onClick={on_submit} disabled={disabled || submitting}>
            <FontAwesomeIcon icon={faArrowCircleRight} className={arrowClasses} size="4x"/>
          </button>
        </div>
      </div>
    );
  }
  

  return el;
}

JFormSubmitButton.defaultProps = {
  is_valid: false
}

export default React.memo(JFormSubmitButton);