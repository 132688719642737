import React from 'react';
import { connect } from 'react-redux';
import ImageWithMetadata from '../../shared/components/image_with_metadata';

const assetsServer = process.env.REACT_APP_ASSETS_SERVER;

class PlacesImagesPanel extends React.PureComponent
{
  render()
  {
    const {place, images} = this.props;
    let rows
    if(images)
      rows = images.toIndexedSeq().sortBy(i => i.id * -1 ).map(image => {
      if(image)
      {
        return (
          <div className="row" key={image.id}>
            <div className="col mb-3 d-flex justify-content-center">
              <ImageWithMetadata
                image={image}
                urlKey={image.key}
                assetsServer={assetsServer}
                role={image.role}
                latitude ={place.latitude}
                longitude={place.longitude}
              />
            </div>
          </div>
        );
      }
      else
      {
        return null;
      }


  });
    return (
      <div className="container">
        {rows}
      </div>
    );
  }
}

const mapStateToProps = (state,props) =>
{
  const place = state.getIn(['places',props.match.params.id]);
  const imagesIds = place.get('relationships').has('images')?place.get('relationships').get('images'):undefined
  let images
  if(imagesIds)
    images = state.get('images').filter(i => imagesIds.contains(i.id.toString()));
  return{
    place: place,
    images: images
  };
};

export default connect(mapStateToProps)(PlacesImagesPanel);
