import React from 'react';

class ProjectCard extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = { open: false};
  }

  render(){

    let body;

    if(this.state.open){
      body = (
        <div className="card-body">
          <p>{this.props.project.instructions}</p>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-header" onClick={this.onClick}>{this.props.project.name}</div>
        {body}
      </div> 
    );
  }

  onClick = (e) => {
    this.setState(prevState => this.setState({open: !prevState.open}))
  }

  pv = e => e.preventDefault();
}

export default ProjectCard;