import React from 'react';

import AnalysisDuplicates from './analysis_duplicates';
import AnalysisPlacesMissingCard from './analysis_places_missing_card';
import isNil from 'lodash/isNil';

class AnalysisResults extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {};
  }


  // TODOS
  // 1. Rename everything with prefix Analysis instead of SampleAnalysis
  // 2. render statistics part of analysis

  render(){
    const {analysis} = this.props;
    if(isNil(analysis))
      return (
        <div>
          Missing analysis
        </div>
      )
    return (
      <div>
        <h4>Problems</h4>
        <AnalysisDuplicates duplicates={analysis.getIn(['problems','duplicates'])} onExclude={this.props.onExclude} />
        <AnalysisPlacesMissingCard attribute={'name'} ids={analysis.getIn(['problems','missing_name'])} />
        <AnalysisPlacesMissingCard attribute={'address'} ids={analysis.getIn(['problems','missing_address'])} />
        <AnalysisPlacesMissingCard attribute={'business_category_id'} ids={analysis.getIn(['problems','missing_business_category_id'])} />
        <AnalysisPlacesMissingCard attribute={'coordinates'} ids={analysis.getIn(['problems','missing_coordinates'])} />
        <AnalysisPlacesMissingCard attribute={'city'} ids={analysis.getIn(['problems','missing_city'])} />
        <AnalysisPlacesMissingCard attribute={'state'} ids={analysis.getIn(['problems','missing_state'])} />
        <AnalysisPlacesMissingCard attribute={'postal_code'} ids={analysis.getIn(['problems','missing_postal_code'])} />
        <AnalysisPlacesMissingCard attribute={'country_code'} ids={analysis.getIn(['problems','missing_country_code'])} />
        <h4>Statistics</h4>
      </div>
    )
  }
}


export default AnalysisResults;
