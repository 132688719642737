import React from 'react';
import cn from 'classnames';
import pick from 'lodash/pick';

import useForm from '../hooks/use_form';

import T from './t';

const JFormLabel = (props) => {
  const { id,element,className } = props;
  const property = element.get('property');
  const { i18n_prefix } = useForm(property,mapFormToProps);

  const prefix = element.get('i18n_prefix') || i18n_prefix;

  const reqSpan = element.get('required') ? <span className="text-danger ml-1">*</span> : null;

  return (
    <label htmlFor={id} className={cn(className,"font-mono text-uppercase text-muted")}>
      <T k={[`${prefix}.${property}.label`,`${prefix}.${property}`]} />{reqSpan}
    </label>
  );
};

const mapFormToProps = (form => pick(form,'i18n_prefix'));

export default React.memo(JFormLabel);