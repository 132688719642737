import React from 'react';
import { Link } from 'react-router-dom';

class AdminsRow extends React.PureComponent
{

  render()
  {
    const admin = this.props.admin;
    return (
      <tr>
        <td><Link to={`/admins/${admin.id}`} className="badge badge-admins">{admin.id}</Link></td>
        <td>{this.props.admin.first_name}</td>
        <td>{this.props.admin.last_name}</td>
        <td>{this.props.admin.email}</td>
        <td>{this.props.admin.suspended?"Suspended":"Active"}</td>
      </tr>
    )
  }
}

export default AdminsRow;
