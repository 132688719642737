import React from 'react';
import { connect } from 'react-redux';

import IndexView from './index_view';
import PlacesRow from './places_row';

import SamplesPlacesActions from '../actions/samples_places_actions';

class SamplesPlacesPanel extends IndexView
{
  constructor(props)
  {
    super(props);
    this.state = {};
  }

  render()
  {
    const { total,page_size } = this.state;
    const { page_number } = this.parsedQuery();

    const rows = this.props.places.toIndexedSeq().map(place => {
      return <PlacesRow key={place.id} place={place}/>
    })

    return (
      <div>
        <div className="row">
          <div className="col">
            <table className="table table-sm table-bordered table-hover table-striped col">
              <thead>
                <tr>
                  {this.generateHeaders(PlacesRow.columns)}
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            {this.pagination({
              page_number: (page_number || 1),
              total,
              page_size,
            })}
          </div>
        </div>

      </div>
    )
  }

  fetchData = (query) => {
    return SamplesPlacesActions.index(this.props.match.params.id, query).then(response => {
      this.setState({total: response.meta.total, page_size: response.meta.page_size})
    });
  }
}

const mapStateToProps = (initialState,initialProps) => {

  return (state) => ({
    places: state.get('places').filter(p => p.sample_id === initialProps.match.params.id),
  });
}

export default connect(mapStateToProps)(SamplesPlacesPanel);
