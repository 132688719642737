import React from 'react';
import Immutable from 'immutable';

import JSONPhotoUploadButton from './json_photo_upload_button';

class JSONPhotoWidget extends React.PureComponent {

  render(){
    const { i18nKey, updateForm, validateFormProperties, element, form, onPhotoUploadSelect, disabled, errors, showValidation } = this.props;
    const commonProps = { i18nKey, updateForm, validateFormProperties, onPhotoUploadSelect, disabled, showValidation, required: true };

    const fields = element.get('properties').map(property => {
      const error = errors.get(property);
      let values = form.get(property);

      if (!Immutable.List.isList(values)) { values = Immutable.fromJS([{key: values}]); }

      return values.map(value => {
        return (
          <JSONPhotoUploadButton key={property} attribute={property} value={value.get('key')} error={error} editable={!element.get('multiple')} {...commonProps} />
        )
      })
    }).flatten()

    return(
      <div className="row mb-4">
        {fields}
      </div>
    );
  }

}

export default JSONPhotoWidget;
