
import React from 'react';
import LiveDate from './live_date';
import ValidatorObservationsActions from '../actions/validator_observations_actions';

class ValidatorObservationCardBody extends React.PureComponent
{

  render()
  {
    const {observation} = this.props;

    let validated = observation.get("validated") === true? "Unvalidate": "Validate"

  return (
      <div>
        <div className="row mb-2">
          <div className="col">
            <h6>Validator</h6>
            <code className="mb-2">{JSON.stringify(observation.observer_type)} - {JSON.stringify(observation.observer_id)}</code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Validated</h6>
            <code className="mb-2">{JSON.stringify(observation.validated)} - <LiveDate date={observation.validated_at} /></code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Input</h6>
            <code className="mb-2">{JSON.stringify(observation.input)}</code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Response</h6>
            <code className="mb-2">{JSON.stringify(observation.response)}</code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Data</h6>
            <code className="mb-2">{JSON.stringify(observation.data)}</code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Validation notes</h6>
            <textarea id="validation_notes" rows="4" cols="50">
              {observation.getIn(["response","meta","validated_notes"])}
            </textarea>
            <br></br>
            <button type="button" className= {observation.get("validated")?"btn btn-danger":"btn btn-success"}  onClick= {this.onClickValidateButton }>{validated} </button>
          </div>
        </div>
      </div>
    );
  }


  onClickValidateButton = ()=>
  {
    const {observation} = this.props;
    const toValidate = observation.get("validated") ? false:true
    ValidatorObservationsActions.update(observation.get("id"), {meta: {validated: toValidate, validated_notes: document.getElementById("validation_notes").value}})
  }

}
export default ValidatorObservationCardBody;
