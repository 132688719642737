import React from 'react';
import { connect } from 'react-redux';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import isPast from 'date-fns/is_past';
import { Link } from 'react-router-dom';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import DateTimeFields from '../../shared/components/date_time_fields';

import {dataplorEnv} from '../../shared/helpers/env_helpers';

class BotCallObservationForm extends React.Component{

  constructor(props){
    super(props);
    this.state = {};
  }

  componentDidMount(){
    this.props.initForm();
  }

  render(){
    const { form } = this.props;

    if(form){
      let callAtLabel;

      if( form.get('call_now') ){
        callAtLabel = "Now";
      } else if(form.get('autoschedule')) {
        callAtLabel = "in the next block";
      } else if( form.get('call_at') ){
        const suffix = isPast(form.get('call_at')) ? 'ago' : 'from now';
        callAtLabel = `${distanceInWordsToNow(form.get('call_at'))} ${suffix}`;
      } else {
        callAtLabel = 'Invalid Time';
      }

      let autoscheduleField;

      if( !form.get('call_now') ){
        autoscheduleField = (
          <div class="custom-control custom-checkbox my-1 mr-sm-2">
            <input type="checkbox" name="autoschedule" class="custom-control-input" id="autoschedule" checked={form.get('autoschedule')} onChange={this.onAutoscheduleChange}/>
            <label class="custom-control-label" htmlFor="autoschedule">Autoschedule</label>
          </div>
        )
      }

      let callAtFields;

      if( !form.get('call_now') && !form.get('autoschedule') ){
        callAtFields = (
          <div className="mt-3">
            <label className="mr-2" >Call at:</label>
            <DateTimeFields attribute="call_at" value={form.get('call_at')} onChange={this.onCallAtChange} className="form-control"/>
          </div>
        );
      }

      let buttonContent = 'Schedule';
      if( this.state.responded ){
        buttonContent = 'Scheduled';
      } else if( this.state.submitted ){
        buttonContent = 'Scheduling...'
      }

      let linkContent;
      if( this.state.bot_call_observation_id ){
        linkContent = (
          <Link to={`/observations/${this.state.bot_call_observation_id}`} className="ml-2 btn btn-warning">
            Go to bot call observation ({this.state.bot_call_observation_id})
          </Link>
        )
      }


      return (
        <form className="form" onSubmit={this.onSubmit}>
          <label className="my-1 mr-3">Call using:</label>
          <select className="custom-select mb-3 mr-sm-2" name="bot_env" value={form.get('bot_env')} onChange={this.onEnvChange}>
            <option value="production">production</option>
            <option value="pilot">pilot</option>
            <option value="development">development</option>
            <option value="bryan">dev-bryan</option>
            <option value="ryan">dev-ryan</option>
          </select>

         <input className="form-control py-1 col" type="text" defaultValue="master" value={form.get('ref')} onChange={this.onRefChange} style={{height:"28px"}}/>

          <div className="custom-control custom-checkbox my-1 mr-sm-2">
            <input type="checkbox" name="call_now" className="custom-control-input" id="callNow" checked={form.get('call_now')} onChange={this.onCallNowChange}/>
            <label className="custom-control-label" htmlFor="callNow">Call Now</label>
          </div>

          {autoscheduleField}

          {callAtFields}

          <div className="card my-3 bg-light">
            <div className="card-body py-1">
              <pre className="my-3">
                Will call: <span className="text-warning">{callAtLabel}</span>
              </pre>
            </div>
          </div>

          <button type="submit" className="btn btn-primary" disabled={this.state.bot_call_observation_id}>{buttonContent}</button>

          {linkContent}
        </form>
      );
    } else {
      return null;
    }
  }

  onEnvChange = (e) => {
    this.props.updateForm({bot_env: e.target.value})
  }

  onRefChange = (e) => {
    this.props.updateForm({ref: e.target.value})
  }

  onCallNowChange = (e) => {
    this.props.updateForm({call_now: e.target.checked})
  }

  onAutoscheduleChange = (e) => {
    this.props.updateForm({autoschedule: e.target.checked})
  }

  onCallAtChange = (update) => {
    this.props.updateForm({call_at: new Date(update.call_at).toISOString()})
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({submitted: true});
    const { form } = this.props;
    const data = { bot_env: form.get('bot_env'), ref: form.get('ref') || 'master'};
    if( form.get('call_now') ){
      data.call_now = true
    } else if(form.get('autoschedule')) {
      data.call_now = false
    } else if( form.get('call_at') ){
      data.call_at = new Date(form.get('call_at')).toISOString();
    } else {
      throw new Error('Invalid time!')
    }

    const submitData = omitBy(data,(v,k) => isNil(v) );

    this.props.onSubmit(submitData).then((json) => {
      this.setState({responded: true, bot_call_observation_id: json.data.attributes.id})
    });
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.getIn(['forms','bot_call_new'])
  }
};

const mapDispatchToProps = {
  initForm: () => ({
    type: 'forms.load',
    data: {
      bot_call_new: {
        bot_env: (dataplorEnv() === 'production' ? 'production' : 'pilot'),
        call_now: true,
        autoschedule: true,
        call_at: (new Date(new Date().valueOf() + 300000)).toISOString()
      }
    }
  }),
  updateForm: (change) => ({type: 'forms.merge', data: { bot_call_new: change }})
}

export default connect(mapStateToProps,mapDispatchToProps)(BotCallObservationForm);
