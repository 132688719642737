import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Intercom from 'react-intercom';

class IntercomMessenger extends React.PureComponent {

  render(){
    const customAtts = this.props.explorer.toJSON()
    return <Intercom 
      appID={process.env.REACT_APP_INTERCOM_ID}
      language_override={this.props.explorer.get('locale') || 'es'}
      user_id={this.props.explorer.id}
      user_number={this.props.explorer.number}
      custom_launcher_selector=".intercom-trigger"
      {...customAtts}
    />
  }
}

const mapStateToProps = state => ({ 
  explorer: state.get('explorer')
});


export default withRouter(connect(mapStateToProps)(IntercomMessenger));