import React from 'react';
import { connect } from 'react-redux';
import ObservationsBotCallCard from './observations_bot_call_card';
import { generateObservationChangesJson } from '../helpers/observation_helpers';

class BotCallAuditTask extends React.PureComponent {

  render()
  {
    const{ task,botCall} = this.props;
    return <ObservationsBotCallCard task={task} observation = {botCall} id = {botCall.id} onMerge={this.onMerge} onComplete = {this.onComplete}/>
  }

    onComplete = (changes, form ) => {
    const json = generateObservationChangesJson(changes, form)
    return this.props.onComplete(json);
  }
}

const mapStateToProps = state => {
  let botCall
  const task = state.get('tasks').sortBy(t => t.created_at).first();

  if(task)
  {
    const idobs = task.getIn(['association_ids','bot_call_id']).toString()
    botCall = state.getIn(['observations',idobs]);
  }

  return {
    task,
    botCall
  }
};

const mapDispatchToProps = {
  clearTask: (task) => ({type: 'tasks.delete_in', data: [[task.id.toString()]]}),
}

export default connect(mapStateToProps,mapDispatchToProps)(BotCallAuditTask);
