import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Switch,Route,Link,withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import PlacesActions from '../actions/places_actions';
import PlacesObservationsPanel from './places_observations_panel'
import PlacesDataPanel from './places_data_panel';
import PlacesPatchPanel from './places_patch_panel'
import PlacesManualObservation from './places_manual_observation'
import PlacesDeduplicatorPanel from './places_deduplicator_panel';
import PlacesImagesPanel from './places_images_panel';
import PlacesMapPanel from './places_map_panel';
import PlacesChildsPanel from './places_childs_panel';
import PlacesOverviewPanel from './places_overview_panel'

import LiveDate from './live_date';

class PlacesShow extends React.PureComponent
{
  constructor(props){
    super(props);
    this.state = {
      place: {},
      tab: 'details',
      loaded: false
    };
  }

  componentDidMount()
  {
    PlacesActions.show(this.props.match.params.id).then(json => {
      if(json.data.id !== this.props.match.params.id){
        this.props.history.push(`/places/${json.data.id}`)
      } else {
        this.setState({loaded: true});
      }
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot)
  {
    if(this.props.match.params.id !== prevProps.match.params.id)
    {
      this.setState({loaded: false});
      PlacesActions.show(this.props.match.params.id).then(json => {
        this.setState({loaded: true});

      })
    }
  }


  render()
  {
    if(this.state.loaded)
    {

      const place = this.props.place;
      const subpath = this.props.location.pathname.match(/places\/\d+\/?(\w*)/)[1];
      const parent= place.parent_id

      let rootJSX, parentJSX, placeNameColored

      if(parent)
        parentJSX=<p>Parent: <Link to={`/places/${parent}`} className="badge badge-places">{parent}</Link> </p>
      if(this.props.childs.size > 0)
        placeNameColored = <span className = "badge badge-warning">{place.name}</span>
      else
        placeNameColored = <span className = "badge badge-places">{place.name}</span>
      return (
        <div id="placesShow" className="mb-5">
          <div className="row mt-3">
            <div className="col">
              <h2><span className="badge badge-places">{this.props.place.id}</span> {placeNameColored}</h2>
                <p>Added <LiveDate date={this.props.place.created_at} />, updated <LiveDate date={this.props.place.updated_at} /></p>
                {rootJSX}
                {parentJSX}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'data')})} to={`/places/${place.id}/data`}>Data</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'patch')})} to={`/places/${place.id}/patch`}>Patch</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'images')})} to={`/places/${place.id}/images`}>Images</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'observations')})} to={`/places/${place.id}/observations`}>Observations</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'map')})} to={`/places/${place.id}/map`}>Map</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'compare')})} to={`/places/${place.id}/compare`}>Compare</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'tree')})} to={`/places/${place.id}/tree`}>Tree</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'manual')})} to={`/places/${place.id}/manual`}>Manual Observation</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'overview')})} to={`/places/${place.id}/overview`}>Overview</Link>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <Switch>
                    <Route exact path="/places/:id/" component={PlacesDataPanel} />
                    <Route exact path="/places/:id/data" component={PlacesDataPanel} />
                    <Route exact path="/places/:id/patch" component={PlacesPatchPanel} />
                    <Route exact path="/places/:id/edit" component={PlacesDataPanel} />
                    <Route exact path="/places/:id/observations" component={PlacesObservationsPanel} />
                    <Route exact path="/places/:id/images" component={PlacesImagesPanel} />
                    <Route path="/places/:id/compare/:candidate_id?" component={PlacesDeduplicatorPanel} />
                    <Route exact path="/places/:id/map" component={PlacesMapPanel} />
                    <Route exact path="/places/:id/tree" component={PlacesChildsPanel} />
                    <Route exact path="/places/:id/manual" component={PlacesManualObservation} />
                    <Route exact path="/places/:id/overview" component={PlacesOverviewPanel} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return 'loading...'
    }

  }

  onSubmit = (update) => {

  }


  switchTab = (e) => {
    e.preventDefault();
    this.setState({tab: e.target.getAttribute('data-tab')})
  }

  fetchData = (query) =>
  {
    PlacesActions.index(
      Object.assign(
        {},
        query,
        {filter: {root_id: this.props.match.params.id }}
      ), "merge"
    )
  }
}

const mapStateToProps = (state,props) =>
{
  const id = props.match.params.id
  const placeId = parseInt(id,10);
  const childs = state.get('places').filter(i => placeId === i.root_id && i.id !== placeId);
  const place = state.getIn(['places',id])
  return{
    place: place,
    childs: childs,
    business_categories_loaded: !isEmpty(state.get('business_categories'))
  }
}

const mapDispatchToProps = {
  loadForm: (data) => ({ type: 'forms.load', data }),
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(PlacesShow));
