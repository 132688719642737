import Immutable from 'immutable';

const errorTexts = {
  "must be accepted": "acceptance",
  "can't be blank": "presence",
  "is reserved": "exclusion",
  "is not included in the list": "inclusion",
  "is not a number": "numericality",
  "must exist": "requirement",
  "has already been taken": "uniqueness",
  "is incorrect": "accuracy",
  "has been suspended": "suspension",
}


export const errorToKey = (errorText) => {
  return errorTexts[errorText];
};

export const getFormErrorsFromState = (state,formKey) => {
  const schemaErrors = state.getIn(['form_errors',formKey]) || Immutable.Map();
  const asyncErrors = state.getIn(['form_async_errors',formKey]) || Immutable.Map();;

  return schemaErrors.mergeDeep(asyncErrors);
}

export default {
  errorToKey,
  getFormErrorsFromState,
}