import React from 'react';
import { connect } from 'react-redux';
import { withRouter,Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight,faArrowLeft,faCircle } from '@fortawesome/free-solid-svg-icons';

import AccountActions from '../actions/account_actions';

class TrainingNavigationButtons extends React.PureComponent {
  render(){
    const {step,totalSteps} = this.props;

    let back;
    if(step !== 0){
      const link = (step === 1) ? `/training` : `/training/${step - 1}`
      back = (
        <Link to={link}>
          <FontAwesomeIcon icon={faArrowLeft} className="mx-auto" size="lg" color="gray"/>
        </Link>
      );
    }

    const isLastStep = (step === ( totalSteps-1 ));

    let next;
    if ( isLastStep ){
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      next = ( <a onClick={this.finishTraining}>
         <FontAwesomeIcon icon={faArrowRight} className="mx-auto" size="lg" color="gray"/>
       </a>
      );
    }else{
       next = (
        <Link to={`/training/${step + 1}`}>
          <FontAwesomeIcon icon={faArrowRight} className="mx-auto" size="lg" color="gray"/>
        </Link>
      );
    }


    const dots = [];

    for(var i=0; i<totalSteps; i++){
      const isActive = i===step;
      const color = isActive ? "#4152F3" : "#C2C2C2";

      dots.push(
        (<FontAwesomeIcon key={i} icon={faCircle} className="mx-1" color={color} style={{fontSize:"5px"}}/>)
      )
    }




    return (
      <div className={`row mb-2 mt-2`}>
        <div className="col">
          {back}
        </div>
        <div className="col-8 text-center">
          {dots}
        </div>
        <div className="col text-right">
          {next}
        </div>
      </div>
    );
  }


  finishTraining = () => {
    if( this.props.explorer.get('training_completed_at') ){
      this.props.history.push('/quiz');
    } else {
      const body = { training_completed_at: (new Date().toISOString()) };
      return AccountActions.update(body).then(() => this.props.history.push('/quiz') );
    }
  }

}

const mapStateToProps = state => ({
  explorer: state.get('explorer')
});

export default connect(mapStateToProps)(withRouter(TrainingNavigationButtons));
