import React from 'react';
import { connect } from 'react-redux';
import isNil from 'lodash/isNil';

import T from './t';

import JSONInputField from './json_input_field';
import JSONCheckboxField from './json_checkbox_field';
import JSONFormFieldGroup from './json_form_field_group';

class JSONMultiCheckboxWidget extends JSONInputField {
  render(){

    const {element,form,errors,warnings,i18nKey,validateFormProperties,disabled} = this.props;
    const updateForm = this.updateForm;
    const key = element.get('key');

    const fieldProps = {
      i18nKey,
      disabled,
    };

    const checkboxes = element.get('properties').map(p => {
      const property = `${key}_${p}`;
      const v = form.get(property);
      const value = isNil(v) ? '' : v;
      const error = errors.get(property);
      const warning = warnings.get(property);


      return <JSONCheckboxField key={p} {...{property,value,error,warning,updateForm,validateFormProperties,id: property,element}} {...fieldProps} />
    });

    return (
      <JSONFormFieldGroup>
        {this.label()}
        {checkboxes}
        {this.feedback()}
      </JSONFormFieldGroup>
    );
  }

  updateForm = (update) => {
    this.setState({ showValidation: true });
    return this.props.updateForm(update)
  }

  label = () => {
    const {i18nKey,element} = this.props;
    const required = element.get('required');
    const key = element.get('key');
    const reqSpan = required ? <span className="text-danger ml-1">*</span> : null;
    return (
      <React.Fragment>
        <label><T k={[`${i18nKey}.${key}.label`,`${i18nKey}.${key}`]} />{reqSpan}</label>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  categories: state.get('business_categories'),
});

export default connect(mapStateToProps)(JSONMultiCheckboxWidget);