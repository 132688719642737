import Ajv from 'ajv';

const ajv = new Ajv({allErrors: true, unknownFormats: ['phone']});
const ajvTf = new Ajv({unknownFormats: ['phone']});

export const validateForm = (schema,form,i18nKey) => {
  const validator = ajv.compile(schema.toJSON());
  validator(form.toJSON()); // must validate to set validator.errors on this validator instance
  return mapAjvErrors(validator.errors,schema,i18nKey);
}

export const validateFormNoI18N = (schema,form) => {  
  const validator = ajv.compile(schema.toJSON());
  validator(form.toJSON()); // must validate to set validator.errors on this validator instance
  return validator.errors
}


// build up an object in the format {property: [errors]}
const mapAjvErrors = (errors,schema,i18nKey) => {
  return (errors || []).reduce((o,error) => {
    return Object.assign(o,ajvToKey(error,schema,i18nKey));
  },{});
};



const ajvToKey = (ajvError,schema,i18nKey) => {
  let property = findProperty(ajvError);

  // if the error is specific enough that we can tell which property
  // is erroring
  if(property){
    let errorKey;

    // crawl the schema path to see if there are any custom errors in the path,
    // and use the deepest one if you find one
    const customError = findCustomError(ajvError,schema,i18nKey);
    if(customError){
      return {[property]: customError};
    }
    switch(ajvError.keyword){
      // if type is wrong, usually that means that there's a null instead of some
      // other type, so that should get the required error. If there really is a
      // different type, we need to reconfigure the form schema
      case 'required':
      case 'type':
        errorKey = [i18nKey,`${property}.blank`].filter(x => x).join(".");
        return {[property]: [errorKey,'forms.error_templates.required']};

      // If it's a pattern or format error, they should get the invalid error.
      // invalid is a pretty decent layman way of saying this is wrong.
      case 'pattern':
      case 'format':
        errorKey = [i18nKey,`${property}.invalid`].filter(x => x).join(".");
        return {[property]: [errorKey,'forms.error_templates.invalid']};

      // If we get a const or enum error, again either we have our form misconfigured
      // and we should fix that in dev, (ie a disallowed value in a select), in which case
      // we give an invalid error, or its a false where we need a true (like consent),
      // so in that case probably better to say that "Consent is required"
      case 'const':
        if(ajvError.params.allowedValue === true){
          errorKey = [i18nKey,`${property}.blank`].filter(x => x).join(".");
          return {[property]: [errorKey,'forms.error_templates.required']};
        } else {
          errorKey = [i18nKey,`${property}.invalid`].filter(x => x).join(".");
          return {[property]: [errorKey,'forms.error_templates.invalid']};
        }
      case 'enum':
        if(ajvError.params.allowedValues[0] === true){
          errorKey = [i18nKey,`${property}.blank`].filter(x => x).join(".");
          return {[property]: [errorKey,'forms.error_templates.required']};
        } else {
          errorKey = [i18nKey,`${property}.invalid`].filter(x => x).join(".");
          return {[property]: [errorKey,'forms.error_templates.invalid']};
        }
      case 'minLength':
        errorKey = [i18nKey,`${property}.too_short`].filter(x => x).join(".");
        return {[property]: [errorKey,'forms.error_templates.too_short']};
      case 'maxLength':
        errorKey = [i18nKey,`${property}.too_long`].filter(x => x).join(".");
        return {[property]: [errorKey,'forms.error_templates.too_long']};
      // Don't blow up if we get something else
      default:
        console.log(ajvError)
        return null;
    }
  }
};

const findCustomError = (ajvError,schema,i18nKey) => {
  // schemaPath like "#/allOf/3/anyOf/4/properties/saturday_open/type"
  const path = ajvError.schemaPath.split("/");

  let s = schema; // whole schema
  let error = null; // assume no error

  // slice 1 to get rid of the #, dig into the path
  path.slice(1,path.length).forEach(pathElement => {
    // set s to the new sub-object
    s = s.get(pathElement);
    // if it exists and it has an error, set error
    if(s.get && s.get('error')){
      error = [i18nKey,s.get('error')].join('.')
    }
  });

  return error;
};

const findProperty = (ajvError) => {
  if(ajvError.params && ajvError.params.missingProperty){
    return ajvError.params.missingProperty;
  } else if(ajvError.dataPath){
    return ajvError.dataPath.replace(".","");
  }
}

// returns [finished,pathToGoTo,history]
export const simulateSequence = (viewSequence,data={}) => {
  const start = viewSequence.start;
  const history = stepSatisfied(start,viewSequence,data);

  const finished = history[history.length - 1].finished;
  if(finished){
    return [true,null,history];
  } else {
    let candidate;

    for(let i = (history.length - 1);i>=0; i--){
      const h = history[i];
      if(candidate && h.passed && h.test){
        // if there is a candidate and we go back and find the previous step
        // was tested and had succeeded, then the next step (the candidate)
        // must be the step we want to go back to.
        return [false,candidate,history];
      } else {
        candidate = h.viewName;
      }
    }

    return [false,history[0].viewName,history];
  }
}

// returns history[{viewName,test,passed,finished}]
export const stepSatisfied = (viewName,viewSequence,data,history=[]) => {
  const view = viewSequence.views[viewName];
  if(!view.schema){
    history.push({viewName, test: false, passed: true});
  } else {
    const schema = viewSequence.schemas[view.schema];
    const validator = ajvTf.compile(schema);
    const passed = validator(data);
    history.push({viewName, test: true, passed });
    if( !passed ){ return history; }
  }

  const [finished,nextView] = whereToNext(view.on_next,data);

  if(finished){
    history.push({finished: true});
    return history;
  } else {
    return stepSatisfied(nextView,viewSequence,data,history);
  }
};

// returns [finished,path]
export const whereToNext = (on_next=[],data={}) => {
  for(let i=0; i < on_next.length; i++){
    const path = on_next[i];
    if(path.finish){
      return [true,null];
    } else {
      const onValidator = ajvTf.compile(path.test);
      const shouldWeNaviagateHere = onValidator(data);
      if(shouldWeNaviagateHere){
        return [false,path.view];
      }
    }
  }
}
