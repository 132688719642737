import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import ProjectsActions from '../actions/projects_actions';
import BusinessCategoryActions from '../actions/business_category_actions';

import { isLoggedIn } from '../../shared/helpers/authentication_helpers';

class AuthenticatedRoute extends React.Component {
  componentDidMount(){
    if(isLoggedIn() && !this.props.projects_loading){ ProjectsActions.index(); }
    if(isLoggedIn() && isEmpty(this.props.business_categories) ){ BusinessCategoryActions.index(this.props.explorer.get('locale')) }
  }

  render(){
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          // const pathname = props.location.pathname;
          
          // if users are not logged in , send them to the register page
          if( !isLoggedIn() ){ return <Redirect to={{ pathname: '/register', state: { from: props.location } }} /> }

          // if( pathname === '/start' ){ return <Component {...props} /> }
          // if( !this.props.explorer || !this.props.explorer.isRegistered ){ return <Redirect to={{ pathname: '/start', state: { from: props.location } }} /> }
          
          // // if users are logged in but have not registered, make them do that
          // const registered = this.props.isRegistered;
          // if( !registered && pathname !== '/introduce' ){ return <Redirect to={{ pathname: '/introduce', state: { from: props.location } }} /> }
          // if( registered && pathname === '/introduce'){ return <Redirect to={{ pathname: '/', state: { from: props.location } }} /> }

          // // unless they are from mexico, send them to the holding tank
          // if( pathname === '/coming_soon' ){ return <Component {...props} /> }
          // if( registered && !this.props.country.match(/mexico|brazil/i) ){ return <Redirect to={{ pathname: '/coming_soon', state: { from: props.location } }} /> }

          // // if the user has not completed the training, they should be redirected to the training
          // if( pathname.match(/^\/training/) ){ return <Component {...props} /> }
          // const doneTraining = this.props.explorer.get('training_completed_at');
          // if( registered && !doneTraining && !pathname.match(/training/) ){ return <Redirect to={{ pathname: '/training', state: { from: props.location } }} /> }

          // // if the user has not completed the quiz, they should be redirected to the quiz
          // if( pathname.match(/^\/quiz/) ){ return <Component {...props} /> }
          // const doneQuiz = this.props.explorer.get('quiz_completed_at');
          // if( registered && !doneQuiz && !pathname.match(/quiz/) ){ return <Redirect to={{ pathname: '/quiz', state: { from: props.location } }} /> }

          // they have completed everything. give them what they want
          return <Component {...props} /> 
        
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  projects_loading: state.getIn(['page','projects_loading']),
  business_categories: state.get('business_categories'),
  explorer: state.get('explorer'),
  isRegistered: state.getIn(['explorer','isRegistered']),
  country: state.getIn(['explorer','country']),
})

export default connect(mapStateToProps)(AuthenticatedRoute);