import Immutable from 'immutable';
import defaultTo from 'lodash/defaultTo';

import Explorer from '../../shared/models/explorer';

import { getStoredExplorer,setAuth } from '../../shared/helpers/authentication_helpers';

// https://github.com/maxogden/browser-locale/blob/master/index.js
const getLocale = () => {
  try{
    let lang
    if (navigator.languages && navigator.languages.length) {
      // latest versions of Chrome and Firefox set this correctly
      lang = navigator.languages[0]
    } else if (navigator.userLanguage) {
      // IE only
      lang = navigator.userLanguage
    } else {
      // latest versions of Chrome, Firefox, and Safari set this correctly
      lang = navigator.language
    }
    return lang.match(/^\w{2}/)[0]
  } catch(e) {
    return 'en';
  }
};

const localeGuess = getLocale();

const initialParams = new URL(window.location.href).searchParams;

const defaultAttributes = Immutable.fromJS({
  email: initialParams.get('email'),
  token: initialParams.get('token'),
  locale: localeGuess,
});

const storedAttributes = defaultAttributes.merge(getStoredExplorer());

const calculatedAttributes = storedAttributes.merge({

});

const defaultValue = new Explorer(calculatedAttributes);

if(defaultValue.email && defaultValue.token){
  setAuth('explorer',defaultValue.toJSON());
}

export default (state=defaultValue,action) => {
  const actionData = Array.isArray(action.data) ? action.data[0] : action.data;
  switch (action.type) {
  case `explorer.load`:
    const loadedExplorer = Object.keys(actionData).reduce((state,key) => {
      return state.set(key,Immutable.fromJS(actionData[key]));
    },state).merge({
      locale: defaultTo(actionData.locale,localeGuess),
    });
    setAuth('explorer',loadedExplorer.toJSON());
    return loadedExplorer;
  case `explorer.merge`:
    const updateData = Array.isArray(action.data) ? action.data[0].attributes : action.data;
    const mergedExplorer = state.mergeDeep(updateData).merge({
      locale: defaultTo(actionData.locale,localeGuess),
    });
    setAuth('explorer',mergedExplorer.toJSON());
    return mergedExplorer; 
  case `explorer.delete_in`:
    const modifiedExplorer = state.withMutations(s => {
      action.data.forEach(p => {
        s.deleteIn(p);
      });
    });
    setAuth('explorer',modifiedExplorer.toJSON());
    return modifiedExplorer;
  case 'all.reset':
    return defaultValue;
  default:
    return state;
  }
};
