import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Immutable from 'immutable';
import defaultTo from 'lodash/defaultTo';

import JSONElementTree from './json_element_tree';
import JSONFormSubmitButton from './json_form_submit_button';

import { validateForm } from '../helpers/schema_helpers';
import { getFormErrorsFromState } from '../helpers/error_helpers';

class JSONForm extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      showValidation: null,
      submitting: false,
    };
  }

  render(){
    const {schema,form_schema,i18nKey,errori18nKey,formKey,sendRemoteValidation,onPhotoUploadSelect,disabled,showSubmitButton,mode,clearable} = this.props;
    const {submitting} = this.state;

    const showValidation = defaultTo(this.props.showValidation,this.state.showValidation);

    let button;
    if(showSubmitButton){
      button = (
        <div className="row">
          <div className="col mb-2">
            <JSONFormSubmitButton 
              onClick={this.onSubmit} 
              formKey={this.props.formKey} 
              submitting={submitting}
              disabled={disabled}
              mode={mode}
            />
          </div> 
        </div> 
      )
    }
    

    return (
      <div>
        <div className="row">
          <div className="col mb-2">
            <JSONElementTree {...{schema,form_schema,showValidation,i18nKey,errori18nKey,formKey,sendRemoteValidation,onPhotoUploadSelect,disabled,clearable}} />
          </div> 
        </div>
        {button}
      </div> 
    );
  }

  onSubmit = () => {
    const {form,schema,loadErrors,onSubmit,onComplete,prevalidations,i18nKey,errori18nKey} = this.props;
    if(!this.state.submitting){
      this.setState({submitting: true});

      const errors = validateForm(schema,form,(errori18nKey || i18nKey));
      const pvsAllValidated = (!prevalidations || prevalidations.every(pv => pv.validated === true));

      if(isEmpty(errors) && pvsAllValidated){
        onSubmit(form).then( () => {
          if(this){ this.setState({submitting: false, showValidation: true}); }
          return onComplete();
        }).catch(e => {
          console.log("error!",e)
          this.setState({submitting: false, showValidation: true});
        });
      } else {
        loadErrors(errors);
        this.setState({submitting: false, showValidation: true});
      }
    }
  }

}

JSONForm.defaultProps = {
  i18nKey: 'visits.form',
  errori18nKey: undefined,
  schema: Immutable.fromJS({}),
  form_schema: Immutable.fromJS({}),
  formKey: undefined,                                        // 'visits/new' | 'visits/edit'
  sendRemoteValidation: (attributes) => (Promise.resolve()), // expects function to prepare and send remote validations from attributes
  onPhotoUploadSelect: (params) => (Promise.resolve()),      // supplies params { ext } and expects an promise returning { key,upload_url } see UploadCredentials
  onSubmit: () => (Promise.resolve()),                       // supplies nothing, expects HOC to submit to retreive the form and submit it. Will check that there are no errors though. Resets form on success.
  onComplete: () => (Promise.resolve()),                     // Fired when done
  disabled: false,                                           // turns all fields disabled
  showSubmitButton: true,                                    // hides the submit button if false
  showValidation: null,                                      // show errors even if not touched. Null delegates the decision further down the chain
  mode: 'create',                                            // 'create' or 'update'
  clearable: false,                                          // 
}

const mapStateToProps = (initialState,initialProps) => {
  const { formKey } = initialProps;
  return (state) => ({
    form: state.getIn(['forms',formKey]),
    errors: getFormErrorsFromState(state,formKey),
    prevalidations: state.get('prevalidations')
  });
};


const mapDispatchToProps = (initialState,initialProps) => {
  const { formKey } = initialProps;
  return dispatch => ({
    loadErrors: (errors) => dispatch({type: 'form_errors.load', data: {[formKey]: errors}}),
    updateAsyncErrors: (errors) => dispatch({type: 'form_async_errors.merge', data: {[formKey]: errors}}),
    updateAsyncWarnings: (warnings) => dispatch({type: 'form_async_warnings.merge', data: {[formKey]: warnings}}),
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(JSONForm);
