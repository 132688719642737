import React from 'react';
import qs from 'qs';
import { withRouter } from 'react-router-dom';

import { generateCSV } from '../helpers/csv_helpers';

class CSVButton extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  render(){
    let progressBar;

    if(this.state.progress && this.state.progress > 0){
      progressBar = (
        <div className="progress" style={{height: '1px'}}>
          <div className="progress-bar" role="progressbar" style={{width: `${this.state.progress}%`}}></div>
        </div>
      )
    }

    return(
      <button className="btn btn-info btn-sm" onClick={this.download}>
        Download CSV 
        <span className="badge badge-secondary ml-1">{this.props.total}</span>
        {progressBar}
      </button>
    );
  }

  download = (e) => {
    this.setState({progress: 1});
    const progressRunner = window.setInterval(() => {
      this.setState((prevState) => {
        return {progress: Math.min(60,prevState.progress + 2)}
      })
    },100);
    
    return generateCSV(this.props.resource,qs.parse(this.props.location.search)).then(csv => {
      this.setState({progress: 80});
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const blob = new Blob([csv], {type: "text/csv"});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${this.props.resource}.csv`;
      this.setState({progress: 95});
      a.click(); 
      window.URL.revokeObjectURL(url);
      a.remove()
      window.clearInterval(progressRunner)
      this.setState({progress: null});
    });
  }
}

export default withRouter(CSVButton);