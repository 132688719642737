import React from 'react';
import cn from 'classnames';

class JSONFormFieldGroup extends React.PureComponent {
  render(){
    const {className,children} = this.props;

    return (
      <div className={cn('form-group mb-4',className)}>
        {children}
      </div>
    );
  }
}

export default JSONFormFieldGroup;