import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck,faTimes } from '@fortawesome/pro-light-svg-icons';

class TrackRecord extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = { open: false};
  }

  render(){

    const tds = [];

    for(let i=0;i<10;i++){
      let icon;
      if(this.props.last.get(i)){
        icon = <FontAwesomeIcon icon={faCheck} className="mx-auto d-block text-success" size="lg"/>
        tds.push(<td key={i} style={{width: '10%'}}>{icon}</td>);
      } else if(this.props.last.get(i) === false){
        icon = <FontAwesomeIcon icon={faTimes} className="mx-auto d-block text-danger" size="lg"/>
        tds.push(<td key={i} style={{width: '10%'}}>{icon}</td>);
      } else {
        tds.unshift(<td key={i} style={{width: '10%'}}>{icon}</td>);
      }
    }

    return (
      <div>
        <h5>Last submitted</h5>
        <table className="table table-condensed table-bordered">
          <tbody>
            <tr>
              {tds}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  onClick = (e) => {
    this.setState(prevState => this.setState({open: !prevState.open}))
  }

  pv = e => e.preventDefault();
}

const mapStateToProps = (state) => {
  return {
    last: state.getIn(['page','last']),
  };
};

export default connect(mapStateToProps)(TrackRecord);