import React from 'react';
import { connect } from 'react-redux';
import PlacesActions from '../actions/places_actions';
import { Link } from 'react-router-dom';
import PlacesChildsRecursivePanel from './places_childs_recursive_panel'


class PlacesChildsPanel extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {loaded:false};
  }

  componentDidMount()
  {
    if(this.props.place.root_id)
    {
      Promise.all([PlacesActions.show(this.props.place.root_id), this.fetchData({root_id: this.props.place.root_id})]).then(this.setState({loaded:true}))
    }
    else
    {
      this.fetchData({root_id: this.props.place.id})
      this.setState({loaded:true})
    }
  }

  render()
  {
    if(this.state.loaded === false)
      return 'loading...'
    const { place, childs, root, allChilds } = this.props;
    let rows;

    if(childs)
    {
      rows = childs.toIndexedSeq().map(place => {
        return <PlacesChildsRecursivePanel place = {place} allChilds = {allChilds} main = {this.props.place} root ={root}/>
      })
    }
    let rootPlace;

    let bgColor = ""
    if(root)
    {
      rootPlace = <Link to={`/places/${root.id}/edit`} className="badge badge-places">{root.id}</Link>
    }
    else
    {
      rootPlace = <Link to={`/places/${place.id}/edit`} className="badge badge-places">{place.id}</Link>
      bgColor = "bg-primary"
    }

    return (
      <div className="visits-data mx-2 my-4">
        <div className={bgColor}>
          {rootPlace}
        </div>
        <ul className="nested">
          {rows}
        </ul>
      </div>
    )
  }

  fetchData = (filter) =>
  {
    PlacesActions.index(
      Object.assign(
        {},
        {},
        {filter}
      ), "merge"
    )
  }

}

const mapStateToProps = (state,props) =>
{
  const placeId = props.match.params.id;
  const place = state.getIn(['places', placeId]);
  const root = state.getIn(['places', `${place.root_id}`]);
  const idRoot = root? root.id: placeId;

  return{
    place: place,
    childs: state.get('places').filter(i => idRoot === i.parent_id),
    allChilds: state.get('places').filter(i => i.root_id === idRoot),
    root: root
  }
}

export default connect(mapStateToProps)(PlacesChildsPanel);
