import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

import SamplesAnalysisActions from '../actions/samples_analysis_actions';
import SamplesActions from '../actions/samples_actions';
import AnalysisResults from './analysis_results';
import Loading from '../../shared/components/loading';


class SamplesAnalyzePanel extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loaded: false,
    }
  }

  componentDidMount(){
    SamplesAnalysisActions.show(this.props.sample.id).then(() => {
      this.setState({loaded: true})
    })
  }

  onExclude = (id) => {
    SamplesActions.destroy(this.props.sample.id, [id])
  }

  render(){
    const { sample } = this.props;
    var btn;
    var label;

    if(this.state.loaded){
      if(this.analyzeStatus() === 'analyzing'){
        label = <FontAwesomeIcon icon={faSpinner} spin className="mx-auto d-block" size="lg"/>;
      } else {
        label = (this.analyzeStatus() === 'completed' ? "Re-Analyze" : 'Analyze');
      }
      return (
        <div className="samples-data mb-2">
          <button className="btn btn-danger col-2 mb-4" onClick={this.analyze}>{label}</button>
          <AnalysisResults analysis={sample.analysis} onExclude ={this.onExclude}/>
        </div>

      )
    } else {
      return <Loading />
    }


  }

  analyzeStatus = () => {
    const { sample } = this.props;
    if(!sample.analyze_queued_at && !sample.analyzed_at){
      return 'unanalyzed';
    } else if(!sample.analyzed_at || (sample.analyze_queued_at && sample.analyze_queued_at > sample.analyzed_at) ){
      return 'analyzing';
    } else {
      return 'completed';
    }
  }

  analyze = () => {
    this.setState({loaded: false});
    SamplesAnalysisActions.create(this.props.sample.id).then(() => {
      this.setState({loaded: true});
    });
  }
}

const mapStateToProps = (state,props) =>
{
  return{
    sample: state.getIn(['samples',props.match.params.id])
  };
};

const mapDispatchToProps = {
  updateSample: (data) => ({ type: 'samples.merge', data })
}

export default connect(mapStateToProps,mapDispatchToProps)(SamplesAnalyzePanel);
