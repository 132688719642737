import React from 'react';
import cn from 'classnames';
import defaultTo from 'lodash/defaultTo';

import { I18nConsumer } from './i18n';
import T from './t'
import JSONFormFieldGroup from './json_form_field_group';

class JSONInputField extends React.PureComponent {

  constructor(props){
    super(props);
    this.state = {
      showValidation: false,
      validationRequestOutstanding: false,
      id: Math.random().toString().slice(2,12),
    };
  }

  render(){
    const {group_class_name} = this.props;

    return (
      <JSONFormFieldGroup className={cn(group_class_name)}>
        {this.label()}
        <div className="input-group">
          {this.prepend()}
          {this.input()}
          {this.append()}
        </div>
        {this.feedback()}
      </JSONFormFieldGroup>
    );
  }

  label = () => {
    const {element,i18nKey,property,disabled,clearable} = this.props;
    const {id} = this.state;
    const required = element.get('required');
    const clearElement = (!disabled && clearable) ? <button className="btn btn-sm btn-danger col p-0" onClick={this.onClear}>Clear</button> : null; 
    const reqSpan = required ? <span className="text-danger ml-1">*</span> : null;
    const i18n = element.get('i18n_key') || i18nKey;
    return (
      <div className="row justify-content-between">
        <div className="col-8 col-lg-9">
          <label htmlFor={id}><T k={[`${i18n}.${property}.label`,`${i18n}.${property}`]} />{reqSpan}</label>
        </div>
        <div className="col-4 col-lg-3 text-right">
          {clearElement}
        </div>
      </div>
    )
  }

  prepend = () => {
    return null;
  }


  input = () => {
    const {element,value,class_name,disabled,property} = this.props;
    const type = element.get('field_type');
    const ac = element.get('autocomplete') || 'new-password';
    return (
      <input 
        type={type}
        name={property} 
        className={cn('form-control',this.validation(),class_name)} 
        value={value} 
        onChange={this.onChange} 
        onBlur={this.onBlur}
        autoComplete={ac}
        disabled={disabled || element.get('disabled')}
      />
    );  
  }

  append = () => {
    return null;
  }

  feedback = () => {
    const {property,error,i18nKey,prevalidation,warning,element} = this.props
    const validationFailed = (prevalidation && prevalidation.validated === false);

    if( this.displayValidation() && (error || validationFailed) ){
      let errorKey;
      if(error){
        errorKey = error
      } else {
        errorKey = [
          (prevalidation && prevalidation.error_code),
          `visits.errors.${property}.async_invalid`,
          `visits.errors.${property}.invalid`,
          'forms.error_templates.invalid'
        ].filter(x => x);
      }
      

      return (
        <I18nConsumer>
          {
            ({t}) => {
              const propertyText = t(`${i18nKey}.${property}`);
              return (
                <div className="invalid-feedback d-block">
                  <T k={errorKey} property={propertyText} />
                </div>
              );
            }
          }
        </I18nConsumer>
        
      );
    } else if(element.get('help')){
      return (
        <small className="form-text text-muted"><T k={element.get('help')} /></small> 
      )
    } else if(warning) {
      return (
        <small className="text-warning form-text">
          <T k={warning} />
        </small>
      );
    }
  }

  displayValidation(){
    return defaultTo(this.props.showValidation,this.state.showValidation);
  }

  validation(){
    if(this.displayValidation()){
      const {error,value,prevalidation,warning} = this.props;
      const {validationRequestOutstanding} = this.state;

      const validationsProcessing = (validationRequestOutstanding || (prevalidation && prevalidation.validated === null));
      const validationFailed = (prevalidation && prevalidation.validated === false);

      return { 
        "is-valid": (!validationsProcessing && !error && !warning && (value || value === false)),
        "is-invalid": (!warning && (error || validationFailed)),
        "validations-processing": ((!error && validationsProcessing) || warning)
      };
    } else {
      return {};
    }
  }

  onChange = (e) => {
    const {property,updateForm} = this.props;
    const v = e.target.value;
    this.setState({ showValidation: false });
    const value = v === '' ? null : v;
    updateForm({[property]: value});
  }

  onBlur = () => {
    const {property,validateFormProperties} = this.props;
    this.setState({ showValidation: true, validationRequestOutstanding: true });
    validateFormProperties([property]).then(() => {
      this.setState({validationRequestOutstanding: false})
    });
  }

  onClear = () => {
    const { property,updateForm } = this.props;
    if(window.confirm(`Are you sure you want to clear "${property}"?`)){
      updateForm({[property]: null});
    }
  }
}

JSONInputField.defaultProps = {
  updateForm: () => {},
  validateFormProperties: () => {},
  validateRemote: () => {},
}

export default JSONInputField;