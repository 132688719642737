import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ExplorerProjectsActions from '../actions/explorer_projects_actions';
import ProjectsActions from '../actions/projects_actions';

class ExplorersProjectsCreateForm extends React.PureComponent {

  constructor(props){
    super(props);
    this.state = {
      project_id: "",
    };
  }

  componentDidMount(){
    ProjectsActions.index({disabled: false}).then(() => {
      this.setState({loaded: true});
    })
  }

  render(){
    const currentProjectIds = this.props.explorer_projects.map(xp => {
      if(!xp.disabled_at){ 
        return xp.project_id 
      }else{
        return null;
      }
    }).filter(x => x).toArray();

    const availableProjects = this.props.projects.filter(project => {
      return (
        !project.disabled && // not disabled
        project.region_ids.includes(this.props.explorer.region_id) && // this explorers region and
        !currentProjectIds.includes(project.id) // not already registered
      );
    }).toIndexedSeq();

    const options = availableProjects.map(project => {
      return <option key={project.id} value={project.id}>{project.id} - {project.name}</option>
    });

    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit}>
          <div className="form-row">
            <select className="custom-select form-control col mr-2" id="project_select" value={this.state.project_id} onChange={this.onChange}>
              <option value={""} disabled>Add to project...</option>
              {options}
            </select>

            <button type="submit" className="btn btn-primary mb-2">Submit</button>
          </div>
        </form>
      </React.Fragment>
    );
  }

  onChange = e => {
    this.setState({project_id: e.target.value});
  }

  onSubmit = e => {
    e.preventDefault();
    if(this.state.project_id){
      ExplorerProjectsActions.create({
        data: {
          attributes: {
            explorer_id: this.props.explorer.id, 
            project_id: this.state.project_id
          }
        }
      }).then(() => {
        this.setState({project_id: ""});
      });
    }
  }
}

const mapStateToProps = (initialState,initialProps) => {
  return state => {
    return ({
      projects: state.get('projects'),
    });
  }
};

export default withRouter(connect(mapStateToProps)(ExplorersProjectsCreateForm));

