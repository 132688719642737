import React from 'react';

import ImagesColumnItem from './images_column_item';

class PlaceValidationsEditImagesColumn extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {};
  }

  render(){
    return (
      <div className="h-100">
        {this.visitImagesComponents(this.visitImagesIndexedByRole(this.props.images))}
      </div>
    )
  }

  visitImagesComponents = (indexedImages) => {
    let components = [];
    for (let [role, images] of indexedImages) {
      images.forEach((image, idx) => {
        components.push( <ImagesColumnItem key={image.id} image_role={role} image_index={idx + 1} visit={this.props.visit} image={image}/> );
      });
    }

    return components;
  }

  visitImagesIndexedByRole = (images) => {
    return images.reduce((map, i) => {
      if (!map.get(i.role)) { map.set(i.role, []); }

      let arr = map.get(i.role);
      arr.push(i)
      map.set(i.role, arr);

      return map
    }, new Map());
  }
}

export default PlaceValidationsEditImagesColumn;
