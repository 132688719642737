import React from 'react'
import { isLoggedIn } from '../../shared/helpers/authentication_helpers';
import { Redirect } from 'react-router-dom'

class NoMatch extends React.PureComponent {
  render(){
    return (
      isLoggedIn() ? (
        <h1>404!</h1>
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />
      )
    )

  }
}

export default NoMatch;