import React from 'react';
import { connect } from 'react-redux';
import SuspensionButton from './suspension_button';

class ExplorersActionsPanel extends React.PureComponent {

  render(){

    return (
      <div>
        <SuspensionButton type='explorer' resource={this.props.explorer} />
      </div>
    )
  }
}

const mapStateToProps = (state,initialProps) => {
  return (state) => ({
    explorer: state.getIn(['explorers',initialProps.match.params.id])
  });
}

export default connect(mapStateToProps)(ExplorersActionsPanel);