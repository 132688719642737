import React,{ useCallback } from 'react';
import pick from 'lodash/pick';

import JFormRadioContentButton from './j_form_radio_content_button';
import JFormFeedback from './j_form_feedback';

import useFieldState from '../hooks/use_field_state';
import useForm from '../hooks/use_form';

const JFormRadioContentButtonSelectWidget = (props) => {
  const { element } = props;
  const property = element.get('property');
  const { show_validation,set_field_show_validation } = useFieldState();
  const { i18n_prefix,update_form,validate_form,value } = useForm(property,mapFormToProps)


  const on_click = useCallback(value => {
    const action = update_form({[property]: value});
    set_field_show_validation(true);
    validate_form([element],[action]);
  },[property,update_form,set_field_show_validation,validate_form,element]);

  const numButtons = element.get('elements').size;

  const buttons = element.get('elements').map((el,i) => {
    const last = (i+1) === numButtons;
    return <JFormRadioContentButton key={el.hashCode()} element={el} {...{i18n_prefix,value,on_click,last}}/>
  });

  return (
    <div className="form-group j-form-content-button-select-widget">
      {buttons}
      <JFormFeedback element={element} show_validation={show_validation} />
    </div>
  );
};

const mapFormToProps = (form => pick(form,
  'i18n_prefix',
  'value',
  'update_form',
  'validate_form',
));

export default React.memo(JFormRadioContentButtonSelectWidget);