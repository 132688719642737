import React from 'react';
import pick from 'lodash/pick';

import useForm from '../hooks/use_form';

import JFormRadioOption from './j_form_radio_option';

const JFormOptionsForRadio = (props) => {
  const { 
    id,
    element,
    show_validation,
    set_field_show_validation,
    should_check,
    transform_value_on_change,
  } = props;
  const property = element.get('property');
  const element_prefix = element.get('i18n_prefix');

  const { 
    schema,
    i18n_prefix,
  } = useForm(property,mapFormToProps);

  const inputProps = {
    element,
    id,
    property,
    show_validation,
    set_field_show_validation,
    should_check,
    transform_value_on_change,
  };

  let options;
  const enums = schema.get('enum');
  const prefix = element_prefix || i18n_prefix;

  if(enums){
    options = enums.reduce((obj,e) => { obj[`${prefix}.${property}.${e}`] = e; return obj },{}); 
  } else {
    options = {[`forms.general.yes`]: true, [`forms.general.no`]: false }
  }

  return Object.entries(options).map(([key,value]) => {
    return <JFormRadioOption key={key} option_key={key} option_value={value} {...inputProps}/>
  });
};

const mapFormToProps = (form) => pick(form,[
  'schema',
  'i18n_prefix',
]);

const no_transform = (v) => v;

JFormOptionsForRadio.defaultProps = {
  should_check: (value,option_value) => value === option_value,
  transform_value_on_change: no_transform,
}

export default React.memo(JFormOptionsForRadio);