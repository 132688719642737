import React from 'react';
import { Link } from 'react-router-dom';

class TasksPdrtCardBody extends React.PureComponent
{

  render()
  {
    const {task} = this.props;
    const originalPlace = task.get("association_ids").get("original_id");
    const duplicatePlace= task.get("association_ids").get("duplicate_id");

    const original = <div><Link to={`/places/${originalPlace}/edit`} className={"badge badge-places"}>{originalPlace}</Link></div>
    const duplicate = <div><Link to={`/places/${duplicatePlace}/edit`} className={"badge badge-places"}>{duplicatePlace}</Link></div>
    const compare  = <div><Link to={`/places/${originalPlace}/compare/${duplicatePlace}`} >Click here to compare them</Link></div>

   return (
      <div>
        <div className="row mb-2">
          <div className="col">
            <h6>Admin id:</h6>
            <code className="mb-2">{JSON.stringify(task.admin_id)}</code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Assigned at:</h6>
            <code className="mb-2">{JSON.stringify(task.assigned_at)}</code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Original place</h6>
            <code className="mb-2">{original}</code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Duplicated place</h6>
            <code className="mb-2">{duplicate}</code>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col">
            <h6>Compare them</h6>
            <code className="mb-2">{compare}</code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Data</h6>
            <code className="mb-2">{JSON.stringify(task.data)}</code>
          </div>
        </div>
      </div>
    );
  }
}

export default TasksPdrtCardBody;
