import React from 'react';
import { connect } from 'react-redux';
import PlacesPatchRow from './places_patch_row'
import PlacesOverviewPatchPanelRow from './places_overview_patch_panel_row'
import {raw} from '../../shared/schemas/data_schema';
import LiveDate from './live_date';
import { Link } from 'react-router-dom';
import {groupBy} from 'lodash';
import cn from 'classnames';
import Linkify from 'react-linkify';
import isNil from 'lodash/isNil';
import isBefore from 'date-fns/is_before';

import { Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

class PlacesOverviewPatchPanel extends React.Component
{

  render() {
    const patch = this.props.patch
    const attrOrder = Object.keys(raw.properties)
    const date = this.props.date

    let rows


    //const groupedAttrs = patch.reduce((obj, patch ) => {
    //  obj[patch.p] = obj[patch.p] || []
    //  obj[patch.p].push(patch)
    //  return obj
    //}, Object.create(null))

    const groupedByPath = groupBy(patch, (patch) => {
      return patch.p
    })

    const reducer = (acc, value) => acc.z > value.z ? acc: value

    const winners = Object.keys(groupedByPath).map((item) => {

      return groupedByPath[item].reduce(reducer)
    });

    if(patch)
    {
      rows = winners.map(p =>
      {
        if(isBefore(p.d, date))
        return <PlacesOverviewPatchPanelRow winner = {p} patch = {groupedByPath}/>
      });
    }



    return (
      <table className="col-6 table table-striped table-hover">
        <thead >
          <tr>
            <th scope='col-2'>Key</th>
            <th scope='col-6 align-left'>Attribute</th>
            <th scope='col-2'>Confidence</th>
            <th scope='col-2'>Date</th>
          </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </table>

    )
  }
}


export default connect()(PlacesOverviewPatchPanel);
