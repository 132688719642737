import React from 'react';
import GooglePlacesMatchCard from './google_places_match_card';
import { inlineAddress } from '../../shared/helpers/address_helpers';

class GooglePlacesMatchCards extends React.PureComponent {

  render(){
    const{ task,place,observation } = this.props;

    const candidates = observation.getIn(['response','matches']).sortBy(o => -1 * o.get('similarity') ).toIndexedSeq().map(candidate => {
      return <GooglePlacesMatchCard key={candidate.get('reference')} task_id={task.id} candidate={candidate}/>
    });

    const placeLink = (
      <a href={`/places/${place.id}/data`} target="_blank" rel="noopener noreferrer" onClick={this.sp}>
        <span className="badge badge-places">{place.id}</span>
      </a>
    );

    return (
      <div className="position-absolute my-auto col-3" style={{ left: 0, top: 75}}>
        <div className='card border-success' onClick={this.onClick}>
          <div className="card-body p-2">
            <h5 className="card-title">{place.name} {placeLink}</h5>
            <h6 className="card-subtitle mb-2 text-monospace" style={{fontSize: '0.5rem'}}>{inlineAddress(place)}</h6>
            <p className="mb-0"><span className="badge badge-secondary mr-1">{place.business_category_id}</span></p>
          </div>
        </div>
        <hr />
        <div className="pr-4 pb-5 pt-2" style={{height: '60vh', overflowY: 'scroll'}}>
          {candidates}
        </div>
      </div>
    );
  }
}

export default GooglePlacesMatchCards;
