import PlaceDuplicatesReviewTask from '../components/place_duplicates_review_task';
import GooglePlacesMatchTask from '../components/google_places_match_task';
import BotCallAuditTask from '../components/bot_call_audit_task';
import ManualReviewTask from '../components/manual_review_task';

export const determineTaskUIType = task =>
{
  switch(task.type)
  {
    case "PlaceDuplicatesReviewTask":
      return  PlaceDuplicatesReviewTask;
    case "GooglePlacesMatchTask":
      return GooglePlacesMatchTask;
    case "BotCallAuditTask":
      return BotCallAuditTask;
    case "ManualReviewTask":
      return ManualReviewTask;
    default:
      return PlaceDuplicatesReviewTask
  }
}
