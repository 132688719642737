import Immutable from 'immutable';

const defaults = {
  id: null,
  name: null,
  address: null,
  exterior_photo: null,
};

class MatchingPlace extends Immutable.Record(defaults){

}

export default MatchingPlace;