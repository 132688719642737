
export const triggerDownload = (type,data,filename) => {
  const a = document.createElement('a');
  const blob = new Blob([data], {type: type});
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}