
import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  index: function(params){
    const q = Object.assign({sort: 'id', sort_dir: 'desc'},params,{page_size: 25});
    return authorizedFetch('/v1/admin/projects',q).then(handleResponse("projects", "load"));
  },
  show: function(id){
    return authorizedFetch(`/v1/admin/projects/${id}`).then(handleResponse("projects", "merge"));
  },
  update: function(id,params){
    const opts = {
      method: 'PUT',
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v1/admin/projects/${id}`,null,opts).then(handleResponse("projects", "merge"));
  }
};
