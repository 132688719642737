import Store from '../reducers/store';
import { includedToActions } from '../../shared/helpers/action_helpers';
import { authorizedFetch,cleanBody } from '../../shared/helpers/request_helpers';

export default {
  index: () => {
    Store.dispatch({type: 'page.merge', data: {'projects_loading': true}})
    return authorizedFetch(`/v2/explorers/projects`).then(json => {
      if(json.included){
        includedToActions(json.included).forEach(action => {
          Store.dispatch(action);
        }); 
      }
      if(json && json.data){
        Store.dispatch({type: 'page.merge', data: {'projects_loaded': true}})
        return Store.dispatch({type: 'projects.load', ...json});  
      }
    });
  },
  update: (id,params) => {
    const opts = {
      method: 'PUT', 
      body: JSON.stringify({ data: { type: 'explorer_project', attributes: cleanBody(params)}})
    };
    return authorizedFetch(`/v2/explorers/projects/${id}`,null,opts).then(json => {
      if(json.included){
        includedToActions(json.included).forEach(action => {
          Store.dispatch(action);
        }); 
      }
      if(json && json.data){
        return Store.dispatch({type: 'projects.merge', ...json});  
      }
    });
  }
};