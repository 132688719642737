import React from 'react';
import cn from 'classnames';
import { Link,withRouter } from 'react-router-dom';
import { UncontrolledDropdown,DropdownToggle,DropdownItem,DropdownMenu } from 'reactstrap';
import { isLoggedIn,clearToken } from '../../shared/helpers/authentication_helpers';

class Navbar extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {show: false};
  }

  render(){
    let logout;
    if(isLoggedIn()){
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      logout = <a className="nav-link text-danger" href="#" onClick={this.signOut}>Sign Out</a>
    }

    let collapseClasses = cn("collapse navbar-collapse",{show: this.state.show})
    return (
      <nav className="navbar navbar-expand-lg">
        <Link className="navbar-brand" to="/">dataPlorAdmin</Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={this.toggle}>
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={collapseClasses} id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="text-explorers">Explorers</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/explorers">Table</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/explorers_quotas">Quotas</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="text-samples">Samples</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/samples">Table</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="text-deliveries">Deliveries</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/deliveries">Table</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="text-places">Places</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/places">Table</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="text-observations">Observations</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/observations">Table</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/observations_botcalls">Bot calls</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="text-leads">Leads</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/leads">Table</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="text-projects">Projects</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/projects">Table</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="text-tasks">Tasks</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/tasks">Table</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="text-admins">Admins</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/admins">Table</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

          </ul>

          <ul className="navbar-nav">
            <li className="nav-item active ml-auto">
              <Link className="nav-link text-primary" to="/validator">Validator</Link>
            </li>
          </ul>

          {logout}
        </div>
      </nav>
    )
  }

  toggle = (e) => {
    this.setState((prevState) => ({show: !prevState.show}) )
  }

  signOut = () => {
    clearToken();
    this.props.history.push('/signin')
  }
}

export default withRouter(Navbar);
