import React from 'react';

import JFormLabel from './j_form_label';
import JFormOptionsForRadio from './j_form_options_for_radio';
import JFormFeedback from './j_form_feedback';

import useFieldState from '../hooks/use_field_state';


const JFormRadioField = (props) => {
  const { element } = props;

  const { id,show_validation,set_field_show_validation } = useFieldState();

  return (
    <div className="form-group mb-4">
      <JFormLabel id={id} element={element} className="mb-3"/>
      <JFormOptionsForRadio  
        id={id}
        element={element}
        show_validation={show_validation}
        set_field_show_validation={set_field_show_validation}
      />
      <JFormFeedback element={element} show_validation={show_validation} />
    </div>
  );
};

export default React.memo(JFormRadioField);