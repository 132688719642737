import React from 'react';

import LiveDate from './live_date';



class PhoneObservationCardBody extends React.PureComponent {

  render(){
    const {observation} = this.props;

    let warning_not_verified;
    if(observation.input.get("number").includes("5555555555"))
    {
      warning_not_verified = (
        <div className="row mb-2">
          <div className="col">
            <h6>WARNING</h6>
            <code className="mb-2">{"This number might not have been verified by NumVerify"}</code>
          </div>
        </div>
      )
    }


    let type_phone_number;
    switch (observation.input.get("attribute"))
    {
      case "mobile":
        type_phone_number =
        (
          <div className="row mb-2">
            <div className="col">
              <h6>Type of phone</h6>
              <code className="mb-2">{"Mobile"}</code>
            </div>
          </div>
        )
        break;
      default:
    }

    return (

      <div>
        {warning_not_verified}
        <div className="row mb-2">
          <div className="col">
            <h6>Validator</h6>
            <code className="mb-2">{JSON.stringify(observation.observer_type)} - {JSON.stringify(observation.observer_id)}</code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Validated</h6>
            <code className="mb-2">{JSON.stringify(observation.validated)} - <LiveDate date={observation.validated_at} /></code>
          </div>
        </div>
        {type_phone_number}
        <div className="row mb-2">
          <div className="col">
            <h6>Input</h6>
            <code className="mb-2">{JSON.stringify(observation.input)}</code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Response</h6>
            <code className="mb-2">{JSON.stringify(observation.response)}</code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Data</h6>
            <code className="mb-2">{JSON.stringify(observation.data)}</code>
          </div>
        </div>
      </div>
    );
  }
}

export default PhoneObservationCardBody;
